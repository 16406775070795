import React, { useState, useEffect } from "react";
import DatePicker from "react-multi-date-picker";
import "./Physiotherapy.css";
import { addDays, format } from "date-fns";
import { toast } from "react-toastify";
import callApi from "../../../ApiCall";
import Constants from "../../../Constants";

export default function Physiotherapy({ GuestMasterId, PhysiotherapyId }) {
  const [sections, setSections] = useState([
    {
      id: Date.now(),
      startDate: null,
      endDate: null,
      diagnosis: "",
      description: "",
      notes: [],
    },
  ]);
  const [newNoteText, setNewNoteText] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedCheckIn, setSelectedCheckIn] = useState(null);
  const [selectedCheckOut, setSelectedCheckOut] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);

  const fetchAvailableDates = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.selectedGuestBookingHistory,
        requestBody: { guestMasterId: GuestMasterId },
      });

      if (response?.success) {
        const dates = response.data.selectedGuestBookingHistory.map(
          (booking) => ({
            checkIn: booking.CheckInDate,
            checkOut: booking.CheckOutDate,
          })
        );
        setAvailableDates(dates);
      } else {
        console.error("Error fetching available dates:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const fetchPhysiotherapyData = async () => {
    try {
      setDataLoading(true);
      const response = await callApi({
        apiURL: Constants.API.getPhysiotherapyDataList,
        requestBody: { GuestMasterId },
      });

      if (response?.success && response.data) {
        const formattedSections = response.data.getPhysiotherapyDataList.map(
          (item) => {
            const details = JSON.parse(item.Details);
            const notes = details.map((detail) => {
              const diagnosisDate = new Date(detail.DiagnosisDate);
              return {
                date: diagnosisDate,
                notes: detail.DiagnosisNote,
              };
            });

            return {
              PhysiotherapyId: item.PhysiotherapyId,
              id: item.GuestMasterId,
              diagnosis: item.Diagnosis,
              description: item.Description,
              notes,
              isUpdate: true,
            };
          }
        );

        setSections(formattedSections);
      }
    } catch (error) {
      console.error("Error fetching physiotherapy data:", error);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    if (GuestMasterId) {
      fetchAvailableDates();
      fetchPhysiotherapyData();
    }
  }, [GuestMasterId]);

  if (dataLoading) {
    return <div>Loading....</div>;
  }

  const handleAddNote = (index) => {
    const updatedSections = [...sections];
    if (!Array.isArray(updatedSections[index].notes)) {
      updatedSections[index].notes = [];
    }
    const newNote = {
      notes: newNoteText,
    };
    updatedSections[index].notes.push(newNote);
    setSections(updatedSections);
    setNewNoteText("");
  };

  const handleDateChange = (sectionIndex, noteIndex, date) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].notes[noteIndex].date = date;
    setSections(updatedSections);
  };

  const handleNoteChange = (sectionIndex, noteIndex, e) => {
    const { value } = e.target;
    const updatedSections = [...sections];
    updatedSections[sectionIndex].notes[noteIndex].notes = value;
    setSections(updatedSections);
  };

  const handleDeleteNote = (sectionIndex, noteIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].notes.splice(noteIndex, 1);
    setSections(updatedSections);
  };

  const handleDeleteNoteData = async (index, noteIndex) => {
    const noteToDelete = sections[index].notes[noteIndex];
    const diagnosisDate = format(new Date(noteToDelete.date), "yyyy-MM-dd");
    const updatedDate = sections[index].updatedDate;
    const physiotherapyId = sections[index].PhysiotherapyId;
    try {
      const response = await callApi({
        apiURL: Constants.API.deletePhysiotherapy,
        requestBody: {
          GuestMasterId,
          DiagnosisDate: diagnosisDate,
          PhysiotherapyId: physiotherapyId,
          updatedDate: updatedDate,
        },
      });

      if (response?.success) {
        const updatedSections = sections.map((section, sectionIndex) => {
          if (sectionIndex === index) {
            const updatedNotes = section.notes.filter(
              (_, idx) => idx !== noteIndex
            );
            return { ...section, notes: updatedNotes };
          }
          return section;
        });

        setSections(updatedSections);
        toast.success("Note Deleted Successfully!");
      } else {
        toast.error(response?.data?.message || "Error deleting note.");
      }
    } catch (error) {
      console.error("Error during deletion:", error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  const validateForm = (index) => {
    let formErrors = {};
    const section = sections[index];
    if (!section.diagnosis.trim()) {
      formErrors.diagnosis = "Diagnosis is required";
    }
    if (!section.description.trim()) {
      formErrors.description = "Description is required";
    }
    setErrors((prevErrors) => ({ ...prevErrors, [index]: formErrors }));
    return Object.keys(formErrors).length === 0;
  };

  const generateDateRange = (start, end) => {
    if (!start || !end) return [];
    const dates = [];
    let currentDate = new Date(start);
    const endDate = new Date(end);
    while (currentDate <= endDate) {
      dates.push(format(currentDate, "dd/MM/yyyy"));
      currentDate = addDays(currentDate, 1);
    }
    return dates;
  };

  const handleSubmitAll = async (e) => {
    e.preventDefault();

    let allValid = true;
    const payloads = [];

    // Validate all forms and collect payloads
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      if (validateForm(i)) {
        const sectionData = section.notes.map((note) => {
          const noteDate = new Date(note.date);
          const diagnosisNote = note.notes;
          const formattedDate = format(noteDate, "yyyy-MM-dd");

          return {
            GuestMasterId,
            Diagnosis: section.diagnosis,
            Description: section.description,
            DiagnosisDate: formattedDate,
            DiagnosisNote: diagnosisNote,
          };
        });
        payloads.push(...sectionData);
      } else {
        allValid = false;
        break;
      }
    }

    if (!allValid) return;

    try {
      setLoading(true);
      for (const payload of payloads) {
        const response = await callApi({
          apiURL: Constants.API.PhysiotherapyData,
          requestBody: payload,
        });

        if (!response?.success) {
          console.error("Error response from API:", response);
          break;
        }
      }

      toast.success("Physiotherapy Data Sent");

      await fetchPhysiotherapyData();
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e, index) => {
    e.preventDefault();

    if (validateForm(index)) {
      const sectionData = sections[index];

      const payloads = sectionData.notes.map((note) => {
        const noteDate = new Date(note.date);
        const diagnosisNote = note.notes;

        const formattedDate = format(noteDate, "yyyy-MM-dd");

        return {
          GuestMasterId,
          Diagnosis: sectionData.diagnosis,
          Description: sectionData.description,
          DiagnosisDate: formattedDate,
          DiagnosisNote: diagnosisNote,
        };
      });

      try {
        setLoading(true);

        for (const payload of payloads) {
          const response = await callApi({
            apiURL: Constants.API.PhysiotherapyData,
            requestBody: payload,
          });

          if (!response?.success) {
            console.error("Error response from API:", response);
            break;
          }
        }

        toast.success("Physiotherapy Data Sent");

        await fetchPhysiotherapyData();
      } catch (error) {
        console.error("Error during API call:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const handleDropdownChange = (e) => {
    const [checkIn, checkOut] = e.target.value.split(" to ");
    setSelectedCheckIn(checkIn);
    setSelectedCheckOut(checkOut);
  };

  const addSection = () => {
    setSections([
      ...sections,
      {
        id: Date.now(),
        startDate: null,
        endDate: null,
        diagnosis: "",
        description: "",
        notes: [],
      },
    ]);
  };

  // const deleteSection = (id) => {
  //   setSections((prevSections) =>
  //     prevSections.filter((section) => section.id !== id)
  //   );
  // };

  const deleteSection = (id) => {
    const updatedSections = sections.filter((section) => section.id !== id);
    setSections(updatedSections);
  };

  const handleFieldChange = (index, field, value) => {
    const updatedSections = [...sections];
    updatedSections[index][field] = value;
    setSections(updatedSections);
  };

  return (
    <div className="physiotherapy-main-container">
      <div className="physiotherapy-in-out">
        <select onChange={handleDropdownChange}>
          <option value="">Select Date Range</option>
          {availableDates.map((date, index) => (
            <option key={index} value={`${date.checkIn} to ${date.checkOut}`}>
              {`${format(new Date(date.checkIn), "dd-MM-yyyy")} to ${format(
                new Date(date.checkOut),
                "dd-MM-yyyy"
              )}`}
            </option>
          ))}
        </select>
      </div>

      <div className="physiotherapy-title">
        <h2>Physiotherapy Treatment</h2>
        <div className="physiotherapy-title-icon" onClick={addSection}>
          +
        </div>
      </div>

      {!selectedCheckIn || !selectedCheckOut ? (
        <p
          style={{
            color: "#989898",
            fontSize: "28px",
            fontWeight: "bold",
            alignItems: "center",
            textAlign: "center",
            margin: "0 auto",
            marginLeft: "5%",
            marginTop: "12%",
          }}
        >
          Please Select a Valid Date Range.
        </p>
      ) : sections.length === 0 ? (
        <p
          style={{
            color: "#989898",
            fontSize: "28px",
            fontWeight: "bold",
            alignItems: "center",
            textAlign: "center",
            margin: "0 auto",
            marginLeft: "5%",
            marginTop: "12%",
          }}
        >
          Data is not Available For the Selected Date Range.
        </p>
      ) : (
        <div style={{ position: "relative" }}>
          {sections.map((section, index) => {
            const dateRange = generateDateRange(
              section.startDate,
              section.endDate
            );
            return (
              <form
                key={section.id}
                className="physiotherapy-card-section"
                onSubmit={(e) => handleSubmit(e, index)}
              >
                {/* Left part (Diagnosis and Description) */}
                <div className="physiotherapy-card-left">
                  <div className="physiotherapy-card-left-title">
                    <label htmlFor={`diagnosis-${index}`}>Diagnosis :</label>
                    <input
                      type="text"
                      id={`diagnosis-${index}`}
                      value={section.diagnosis}
                      onChange={(e) =>
                        handleFieldChange(index, "diagnosis", e.target.value)
                      }
                      required
                      placeholder="Enter Diagnosis"
                    />
                  </div>
                  {errors[index]?.diagnosis && (
                    <span className="error">{errors[index].diagnosis}</span>
                  )}
                  <div className="physiotherapy-card-left-description">
                    <label htmlFor={`description-${index}`}>
                      Diagnosis Description :
                    </label>
                    <input
                      type="text"
                      id={`description-${index}`}
                      value={section.description}
                      onChange={(e) =>
                        handleFieldChange(index, "description", e.target.value)
                      }
                      placeholder="Enter Description"
                    />
                  </div>
                  {errors[index]?.description && (
                    <span className="error">{errors[index].description}</span>
                  )}
                </div>

                {/* Right part (Date and Notes) */}
                <div className="physiotherapy-card-right">
                  <div className="physiotherapy-card-right-date">
                    <div className="physiotherapy-card-right-start-date">
                      <label>
                        Start Date <span className="required">*</span>
                      </label>
                      <DatePicker
                        format="DD-MM-YYYY"
                        placeholder="Start Date"
                        value={
                          section.startDate
                            ? format(new Date(section.startDate), "dd-MM-yyyy")
                            : null
                        }
                        minDate={new Date(selectedCheckIn)}
                        maxDate={new Date(selectedCheckOut)}
                        onChange={(date) =>
                          handleFieldChange(index, "startDate", date)
                        }
                      />
                    </div>
                    <div className="physiotherapy-card-right-end-date">
                      <label>
                        End Date <span className="required">*</span>
                      </label>
                      <DatePicker
                        format="DD-MM-YYYY"
                        placeholder="End Date"
                        value={
                          section.endDate
                            ? format(new Date(section.endDate), "dd-MM-yyyy")
                            : null
                        }
                        minDate={new Date(selectedCheckIn)}
                        maxDate={new Date(selectedCheckOut)}
                        onChange={(date) =>
                          handleFieldChange(index, "endDate", date)
                        }
                      />
                    </div>
                  </div>

                  {/* Notes */}
                  <div className="physiotherapy-card-right-notes-section">
                    <div style={{ display: "flex" }}>
                      <h3>Date Notes :</h3>
                      <span
                        style={{
                          backgroundColor: "#6c7d02",
                          color: "white",
                          padding: "2px 10px",
                          borderRadius: "50%",
                          fontSize: "18px",
                          fontWeight: "bold",
                          cursor: "pointer",
                          marginLeft: "8px",
                          marginTop: "-5px",
                        }}
                        onClick={() => handleAddNote(index)}
                      >
                        +
                      </span>
                    </div>

                    {section.notes.map((note, noteIndex) => (
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                          width: "100%",
                        }}
                        key={noteIndex}
                      >
                        <DatePicker
                          value={
                            note.date
                              ? format(new Date(note.date), "dd-MM-yyyy")
                              : null
                          }
                          format="DD-MM-YYYY"
                          placeholder="Select Date"
                          onChange={(date) =>
                            handleDateChange(index, noteIndex, date)
                          }
                        />
                        <input
                          type="text"
                          placeholder="Enter Note"
                          className="physiotherapy-card-right-notes-input"
                          value={note.notes}
                          onChange={(e) =>
                            handleNoteChange(index, noteIndex, e)
                          }
                        />
                        <span
                          className="delete-section-btn"
                          onClick={() => handleDeleteNote(index, noteIndex)}
                        >
                          ×
                        </span>
                        <span
                          className="physiotherapy-title-icon1"
                          onClick={() => handleDeleteNoteData(index, noteIndex)}
                        >
                          <span
                            style={{ marginLeft: "8px", marginTop: "-5px" }}
                          >
                            x
                          </span>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>

                {sections.length > 0 && (
                  <button
                    className="delete-section-btn"
                    type="button"
                    onClick={() => deleteSection(section.id)}
                  >
                    XY
                  </button>
                )}
              </form>
            );
          })}
        </div>
      )}

      {sections.length > 0 && (
        <button
          className="physiotherapy-card-right-btn"
          onClick={handleSubmitAll}
        >
          {sections.length > 1
            ? sections.some((section) => section.isUpdate)
              ? "Update All"
              : "Submit All"
            : sections[0].isUpdate
            ? "Update"
            : "Submit"}
        </button>
      )}
    </div>
  );
}
