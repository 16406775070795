import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ItemRequestApproval from "../ItemRequest/ItemRequest"; // Import your components
import { Link } from "react-router-dom";
// import RequestApproval from '../RequestApproval/RequestApproval'; // Example for another component
// import ProjectApproval from '../ProjectApproval/ProjectApproval'; // Example for another component

const Approval = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // Track selected item

  // Sample data for categories and approval items
  const approvalData = [
    {
      category: "Item",
      items: [
        {
          name: "Item Request Approval",
          page: "Page 1",
          component: "ItemRequest",
        }, // Include component type
      ],
    },
    {
      category: "Requests",
      items: [
        { name: "Request 1", page: "Page 3" },
        { name: "Request 2", page: "Page 4" },
      ],
    },
    {
      category: "Projects",
      items: [
        { name: "Project 1", page: "Page 5" },
        { name: "Project 2", page: "Page 6" },
        { name: "Project 3", page: "Page 7" },
      ],
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleViewClick = (item) => {
    setSelectedItem(item); // Set the selected item
  };

  const renderComponent = (component) => {
    switch (component) {
      case "ItemRequest":
        return <ItemRequestApproval currentMenu={currentMenu} />;
      //   case 'RequestApproval':
      //     return <RequestApproval />;
      //   case 'ProjectApproval':
      //     return <ProjectApproval />;
      default:
        return null;
    }
  };
  const handleBackClick = () => {
    setSelectedItem(null);
  };

  const currentMenu = props.currentMenu || {};
  return (
    <Box sx={{ width: "100%", padding: "20px" }}>
      {selectedItem ? (
        <Box sx={{ width: "100%" }}>
          <button
            class="bg-green text-center w-48 rounded-2xl h-14 relative text-black text-xl font-semibold group"
            type="button"
          >
            {" "}
            <Link to="/approval" onClick={handleBackClick}>
              <div
                class="rounded-xl h-12 w-1/4 flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[184px] z-10 duration-500"
                style={{ background: "#6C7D02" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                  height="25px"
                  width="25px"
                >
                  <path
                    d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
                    fill="#000000"
                  ></path>
                  <path
                    d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
                    fill="#000000"
                  ></path>
                </svg>
              </div>
              <p class="translate-x-2"> Back</p>
            </Link>
          </button>

          <Typography variant="h4"> {selectedItem.name}</Typography>
          {renderComponent(selectedItem.component)}
        </Box>
      ) : (
        approvalData.map((category, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography variant="h6">{category.category}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                {category.items.map((item, i) => (
                  <Box
                    key={i}
                    sx={{
                      width: "calc(50% - 10px)",
                      marginBottom: "10px",
                      marginRight: "10px",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      backgroundColor: "#f9f9f9",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1" sx={{ flex: 1 }}>
                      {item.name}
                    </Typography>

                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleViewClick(item)}
                    >
                      View
                    </Button>
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </Box>
  );
};

export default Approval;
