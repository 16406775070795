import React, { useState, useEffect } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { toast, Zoom, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Multiselect from "multiselect-react-dropdown";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import callApi from "../../ApiCall";
import Constants from "../../Constants";
import CustomSelect from "../../common/CustomSelectEditable/CustomSelectEditable";
import dayjs from "dayjs";
import MySelect from "../../common/SingleSelect";

const GuestActivityPopup = ({
  onClose,
  isAddMode,
  selectedGuestActivity,
  propertyDetail,
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  });
  useEffect(() => {
    const timePickerContainer = document.createElement("div");
    timePickerContainer.id = "time-picker-container";
    document.body.appendChild(timePickerContainer);

    return () => {
      document.body.removeChild(timePickerContainer);
    };
  }, []);

  const [showPopup, setShowPopup] = useState(true);

  const [status, setStatus] = useState(
    selectedGuestActivity
      ? selectedGuestActivity.Active
        ? "Active"
        : "Inactive"
      : "Active"
  );

  const [showOverlay, setShowOverlay] = React.useState(true);

  const [property, setProperty] = useState(
    isAddMode ? "" : { value: selectedGuestActivity?.PropertyLocationId }
  );
  const [activityList, setActivityList] = useState([]);
  const [guestLocationList, setGuestLocationList] = useState([]);
  const [guestTypeList, setGuestTypeList] = useState([]);
  const [timeSlots, setTimeSlots] = useState(
    selectedGuestActivity?.TimeSlots
      ? JSON.parse(selectedGuestActivity.TimeSlots)
      : [{timeSlotId: null, start: "", end: "" }]  
  );

  const handleAddTimeSlot = () => {
    setTimeSlots([...timeSlots, { timeSlotId: null, start: "", end: "" }]);
  };

  const handleRemoveTimeSlot = (index) => {
    const newTimeSlots = timeSlots.filter((_, i) => i !== index);
    setTimeSlots(newTimeSlots);
  };

  //
  const selectedAssignedEmployees = selectedGuestActivity?.Employees
    ? JSON.parse(selectedGuestActivity?.Employees)
    : [];
  const [assignedEmployeesData, setAssignedEmployeesData] = useState(
    isAddMode ? selectedAssignedEmployees : []
  );

  const selectedRole = selectedGuestActivity?.AssignedDesignations
    ? JSON.parse(selectedGuestActivity?.AssignedDesignations)
    : [];

  const [roleData, setRoleData] = useState(isAddMode ? selectedRole : []);

  /* api for type  */
  const getGuestActivitesList = async () => {
    try {
      const response = await callApi({
        apiURL: "guestActivitymaster/getGuestActivitiesList",
      });
      if (response.data && response.data.guestActivitiesList) {
        /* typedata */
        const typeData = JSON.parse(
          response.data.guestActivitiesList[0].ActivityType
        )?.map((item) => ({
          label: item?.Name,
          value: item?.ID,
        }));

        setGuestTypeList(typeData);

        const guestLocation = JSON.parse(
          response.data.guestActivitiesList[0].Location
        )?.map((item) => ({
          id: item?.LocationId,
          name: item?.LocationName,
        }));
        setGuestLocationList(guestLocation);

        // for assigned employee
        const assignedEmployees = JSON.parse(
          response.data.guestActivitiesList[0].Employees
        )?.map((item) => ({
          label: item?.EmployeeName,
          value: item?.EmployeeId,
        }));
        setAssignedEmployeesData(assignedEmployees);
      } else {
        console.error("Response data or guestActivitiesList is missing.");
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getAssignedRole = async () => {
    try {
      const response = await callApi({
        apiURL: "employeemaster/getAddEmployee",
      });
      const dropdownData = response?.data?.response?.map((val) => ({
        Category: val?.Category,
        Data: JSON.parse(val?.Data),
      }));

      const roles = dropdownData.find(
        (item) => item.Category === "Designation"
      );
      const roleData =
        roles &&
        roles?.Data.map((item) => ({
          label: item?.Designation,
          value: item?.DesignationId,
        }));
      setRoleData(roleData);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  const handleCancel = () => {
    setShowPopup(false);
    onClose();
  };

  useEffect(() => {
    getGuestActivitesList();
    getAssignedRole();
  }, [selectedGuestActivity]);
  console.log(selectedGuestActivity)

  const handleOutsideClick = (event) => {
    if (event.target.classList.contains("PopMain")) {
      setShowPopup(false);
      onClose();
      setShowOverlay(false);
    }
  };

  const handleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setStatus(newStatus);
    }
  };
  const [selectedLocation, setSelectedLocation] = useState(
    isAddMode ? "" : selectedGuestActivity?.ActivityLocationMasterId
  );
  const guestSelectedLocation = (id, setFieldValue) => {
    setSelectedLocation(id);
    setFieldValue("ActivityLocationMasterId", id);
  };
  const addGuestLocation = async (params) => {
    try {
      const response = await callApi({
        apiURL: "guestActivitymaster/addGuestActivityLocation",
        requestBody: {
          activityLocationName: params,
        },
      });
      if (response?.success) {
        const actionMessage = "Added new Location";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            Activity location has been added successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            transition: Zoom,
            hideProgressBar: true,

            style: {
              width: "350px",
              backgroundColor: "#0ABE75",
              color: "#FFFFFF",
            },
            autoClose: 1500,
            closeButton: true,
            onClose: () => {},
          }
        );
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  const updateGuestLocation = async (id, name) => {
    try {
      const response = await callApi({
        apiURL: "guestActivitymaster/updateGuestActivityLocation",
        requestBody: {
          locationId: id,
          locationName: name,
        },
      });
      if (response?.success) {
        const actionMessage = "Updated new Location";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            Activity location has been updated successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            hideProgressBar: true,
            transition: Zoom,
            style: {
              width: "350px",
              backgroundColor: "#0ABE75",
              color: "#FFFFFF",
            },
            autoClose: 1500,
            closeButton: true,
            onClose: () => {},
          }
        );
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  const deleteGuestLocation = async (params) => {
    try {
      const response = await callApi({
        apiURL: "guestActivitymaster/deleteGuestActivityLocation",
        requestBody: {
          locationId: params,
        },
      });
      if (response?.success) {
        const actionMessage = "Deleted new Location";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            Activity location has been deleted successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            hideProgressBar: true,
            transition: Zoom,

            style: {
              width: "350px",
              backgroundColor: "#0ABE75",
              color: "#FFFFFF",
            },
            autoClose: 1500,
            closeButton: true,
            onClose: () => {},
          }
        );
        getGuestActivitesList();
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  /*   for Assigned employee and roles*/
  const remainingEmployee = assignedEmployeesData.filter((data) =>
    selectedAssignedEmployees.includes(data.value)
  );
  const [selectedData, setSelectedData] = useState(
    selectedAssignedEmployees ? selectedAssignedEmployees : []
  );

  const remainingRole = roleData.filter((data) =>
    selectedRole?.includes(data.value)
  );
  const [selectedRoleData, setSelectedRoleData] = useState(
    selectedRole ? selectedRole : []
  );

  const handleEmployeeChange = (selectedOptions, setFieldValue) => {
    const newData = selectedOptions?.map((option) => option?.value);
    setSelectedData(newData);
    setFieldValue("AssignedEmployees", newData);
  };
  const handleRoleDataChange = (selectedOptions, setFieldValue) => {
    const newData = selectedOptions?.map((option) => option?.value);

    setSelectedRoleData(newData);
    setFieldValue("AssignedDesignation", newData);
  };

  const initialValuesForFormiks = {
    GuestActivityType: selectedGuestActivity?.GuestActivityType
      ? selectedGuestActivity?.GuestActivityType
      : "",
    GuestActivityName: selectedGuestActivity?.GuestActivityName
      ? selectedGuestActivity?.GuestActivityName
      : "",
    GuestActivityDescription: selectedGuestActivity?.GuestActivityDescription
      ? selectedGuestActivity?.GuestActivityDescription
      : "",
    GuestActivityPrice: selectedGuestActivity?.GuestActivityPrice
      ? selectedGuestActivity?.GuestActivityPrice
      : "",
    ActivityLocationMasterId: selectedGuestActivity?.ActivityLocationMasterId
      ? selectedGuestActivity?.ActivityLocationMasterId
      : "",
    // ActivityEndTime: selectedGuestActivity?.ActivityEndTime
    //   ? selectedGuestActivity?.ActivityEndTime
    //   : "",
    // ActivityStartTime: selectedGuestActivity?.ActivityStartTime
    //   ? selectedGuestActivity?.ActivityStartTime
    //   : "",
    LocationName: isAddMode ? "" : selectedGuestActivity?.PropertyLocationId,

    status: selectedGuestActivity ? (selectedGuestActivity.Active ? 1 : 0) : 1,

    AssignedDesignation: selectedRoleData,
    AssignedEmployees: selectedData,
    timeSlots: timeSlots,
  };

  const handlelocationChange = (option) => {
    setProperty(option);
  };
  const handleChangeActivity = (option) => {
    setActivityList(option);
  };

  const validationSchema = Yup.object({
    GuestActivityName: Yup.string()
      .required("Activity Name is Required")
      .max(40, "Max 40 Characters"),
    GuestActivityDescription: Yup.string().required(
      "Activity Description Required"
    ),
    GuestActivityType: Yup.string().required("Activity Type is Required"),
    ActivityLocationMasterId: Yup.number().required(
      "Activity Location Name is Required"
    ),
    LocationName: Yup.string().required("Location Name is Required"),
    // ActivityEndTime: Yup.string().required("Activity End Time is Required"),
    // ActivityStartTime: Yup.string().required("Activity Start Time is Required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    values.status = status === "Active" ? 1 : 0;
    values.AssignedEmployees = selectedData;
    values.AssignedDesignation = selectedRoleData;
    values.LocationName = property?.value;
    values.ActivityLocationMasterId = selectedLocation;
     values.timeSlots = timeSlots

    // Add and update API
    let response;
    try {
      const params = { ...values };
      if (isAddMode) {
  
        response = await callApi({
          apiURL: "guestActivitymaster/addGuestActivity",
          requestBody: params,
        });
      } else {
        response = await callApi({
          apiURL: "guestActivitymaster/updateGuestActivity",
          requestBody: {
            ...values,
            activityMasterId: selectedGuestActivity.ActivityMasterId,
            updatedDate: selectedGuestActivity.UpdatedDate,
          },
        });
      }
      if (response?.success) {
        const actionMessage = isAddMode ? "Added Activity " : "Activity";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            Activity has been {isAddMode ? "added." : "updated."}
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            theme: "colored",
            transition: Zoom,
            className: "toast_pop",
            autoClose: 1500,
            closeButton: true,
            onClose: () => {
              setShowPopup(false);
              onClose();
              resetForm();
            },
          }
        );
      } else {
        console.error("Error fetching guest activity data:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }

    resetForm();
    onClose();
  };

  const propertyLocationNameOptions = propertyDetail[0]?.Data?.map(
    (val, i) => ({
      value: val?.LocationID,
      label: `${val?.LocationName} - ${val?.PropertyName}`,
    })
  );

  return (
    <>
      {showOverlay && <div className="Overlay" />}
      <div
        className={
          showPopup ? "guestActivity_PopMain" : "guestActivity_PopMain hidden"
        }
        onClicks={handleOutsideClick}
      >
        <div className="guestActivity_First">
          <p>{isAddMode ? "Add Guest Activity" : "Update Guest Activity"}</p>
          <h className="popup_Close_icon" onClick={handleCancel}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#424242"
              />
            </svg>
          </h>
        </div>
        <br />

        <Formik
          initialValues={initialValuesForFormiks}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, { resetForm });
          }}
        >
          {({ values, setFieldValue }) => (
            <Form className="guestActivity_form">
              <div className="guestActivity_ActivityDropdown">
                <label htmlFor="peropertyName">
                  Property Location Name <sup>*</sup>
                </label>
                <Field
                  component={MySelect}
                  options={propertyLocationNameOptions}
                  onChange={handlelocationChange}
                  name="LocationName"
                  className="designation-dropdown"
                  placeholder="Select"
                ></Field>
                <div className="error_GuestActivity">
                  <ErrorMessage name="LocationName" />
                </div>
              </div>
              <div className="guestActivity_ActivityDropdown">
                <label htmlFor="guestActivity_fname">
                  Activity type <sup>*</sup>
                </label>
                <Field
                  component={MySelect}
                  name="GuestActivityType"
                  options={guestTypeList}
                  placeholder="Select"
                  className="designation-dropdown"
                  onChange={handleChangeActivity}
                />
                <div className="error_GuestActivity">
                  <ErrorMessage name="GuestActivityType" />
                </div>
              </div>

              <div className="guestActivity_Name">
                <label htmlFor="guestActivity_fname">
                  Activity Name <sup>*</sup>
                </label>

                <Field
                  type="text"
                  id="activityname"
                  name="GuestActivityName"
                  placeholder="Enter Activity Name"
                ></Field>
                <div className="error_GuestActivity">
                  <ErrorMessage name="GuestActivityName" />
                </div>
              </div>

              <div className="guestActivity_Name">
                <label htmlFor="guestActivity_fname">
                  Activity Description <sup>*</sup>
                </label>
                <Field
                  type="text"
                  id="activitydescription"
                  name="GuestActivityDescription"
                  placeholder="Enter Description "
                ></Field>
                <div className="error_GuestActivity">
                  <ErrorMessage name="GuestActivityDescription" />
                </div>
              </div>

              <div className="guestActivity_Name">
                <label htmlFor="guestActivity_fname">Activity Price</label>
                <Field
                  type="number"
                  id="activityprice"
                  name="GuestActivityPrice"
                  placeholder="Enter price"
                ></Field>
                <div className="error_GuestActivity">
                  <ErrorMessage name="GuestActivityPrice" />
                </div>
              </div>

              <div className="guestActivity_ProductDropdown">
                <label htmlFor="">
                  Activity Location Name <sup>*</sup>
                </label>

                {guestLocationList && guestLocationList.length > 0 && (
                  <>
                    <div className="combo_Custom">
                      <Field
                        component={CustomSelect}
                        options={guestLocationList}
                        addData={addGuestLocation}
                        updateData={updateGuestLocation}
                        deleteCategory={deleteGuestLocation}
                        selectedCategory={
                          selectedGuestActivity?.ActivityLocationMasterId
                        }
                        newSelectedData={(id) =>
                          guestSelectedLocation(id, setFieldValue)
                        }
                      />
                    </div>
                    <div className="error_GuestActivity">
                      <ErrorMessage name="ActivityLocationMasterId" />
                    </div>
                  </>
                )}

                {(!guestLocationList || guestLocationList.length === 0) && (
                  <div className="combo_Custom">
                    <Field
                      component={CustomSelect}
                      options={[]}
                      addData={addGuestLocation}
                      updateData={updateGuestLocation}
                      deleteCategory={deleteGuestLocation}
                      selectedCategory={
                        selectedGuestActivity?.ActivityLocationMasterId
                      }
                      newSelectedData={(id) =>
                        guestSelectedLocation(id, setFieldValue)
                      }
                    />
                  </div>
                )}
              </div>

              {/* <div className="guestActivity_ProductDropdown">
                <label htmlFor="productTime">
                  Start Time <sup>*</sup>
                </label>
                <Field
                  type="time"
                  id="ActivityStartTime"
                  name="ActivityStartTime"
                  className="start-end-time"
                ></Field>
                <div className="error_GuestActivity">
                  <ErrorMessage name="ActivityStartTime" />
                </div>
              </div> */}

              {/* <div className="guestActivity_ProductDropdown">
                <label htmlFor="productTime">
                  End Time <sup>*</sup>
                </label>
                <Field
                  type="time"
                  id="ActivityEndTime"
                  name="ActivityEndTime"
                  className="start-end-time"
                ></Field>
                <div className="error_GuestActivity">
                  <ErrorMessage name="ActivityEndTime" />
                </div>
              </div> */}
              <div className="guestActivity_ProductDropdown">
                <label htmlFor="productTime">
                  Start and End Time <sup>*</sup>
                </label>

                {timeSlots.map((slot, index) => (
                  <div key={slot.timeSlotId || index} style={{ display: "flex" }}>
                    <div className="guestActivity_ProductDropdown">
                      <label htmlFor="startTime">
                        Start Time <sup>*</sup>
                      </label>
                      <input
                        type="time"
                        name={`timeSlots[${index}].start`}
                        value={slot.start}
                        onChange={(e) => {
                          const updatedSlots = [...timeSlots];
                          updatedSlots[index].start = e.target.value;
                          setTimeSlots(updatedSlots);
                        }}
                        className="start-end-time"
                      />
                    </div>

                    <div className="guestActivity_ProductDropdown">
                      <label htmlFor="endTime">
                        End Time <sup>*</sup>
                      </label>
                      <input
                        type="time"
                        name={`timeSlots[${index}].end`}
                        value={slot.end}
                        onChange={(e) => {
                          const updatedSlots = [...timeSlots];
                          updatedSlots[index].end = e.target.value;
                          setTimeSlots(updatedSlots);
                        }}
                        className="start-end-time"
                      />
                    </div>

                    {/* Remove time slot button */}
                    {timeSlots.length > 1 && (
                      <button
                        type="button"
                        className="remove-time-slot"
                        onClick={() => handleRemoveTimeSlot(index)}
                      >
                        X
                      </button>
                    )}
                  </div>
                ))}

                {/* Add more time slots button */}
                <button
                  type="button"
                  className="add-time-slot"
                  onClick={handleAddTimeSlot}
                >
                  Add Time Slot
                </button>
              </div>

              <div className="guestActivity_ProductDropdown">
                <label htmlFor="">
                  Assigned Employees <sup>*</sup>
                </label>
                <div className="assignedEmpl-multi">
                  <Field
                    as={Multiselect}
                    options={assignedEmployeesData}
                    selectedValues={remainingEmployee}
                    displayValue="label"
                    onSelect={(selectedList) =>
                      handleEmployeeChange(selectedList, setFieldValue)
                    }
                    onRemove={(selectedList) =>
                      handleEmployeeChange(selectedList, setFieldValue)
                    }
                    placeholder="Select "
                    name="AssignedEmployees"
                  />
                  <div className="error_GuestActivity">
                    <ErrorMessage name="AssignedEmployees" />
                  </div>
                </div>
              </div>

              <div className="guestActivity_ActivityDropdown">
                <label htmlFor="peropertyName">
                  Assigned Role <sup>*</sup>
                </label>

                <Field
                  as={Multiselect}
                  options={roleData}
                  selectedValues={remainingRole}
                  displayValue="label"
                  onSelect={(selectedList) =>
                    handleRoleDataChange(selectedList, setFieldValue)
                  }
                  onRemove={(selectedList) =>
                    handleRoleDataChange(selectedList, setFieldValue)
                  }
                  placeholder="Select "
                  name="AssignedDesignation"
                />
                <div className="error_GuestActivity">
                  <ErrorMessage name="AssignedDesignation" />
                </div>
              </div>

              <div className="guestActivity_Togglebutton-1">
                <ToggleButtonGroup
                  className="guestActivity_ToggleButtonGroups-1"
                  sx={{ width: "30px", height: "32px" }}
                  value={status}
                  id="status"
                  name="status"
                  exclusive
                  onChange={handleStatusChange}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    sx={{ borderRadius: "8px 0px 0px 8px" }}
                    value="Active"
                    aria-label="Active"
                  >
                    Active
                  </ToggleButton>
                  <ToggleButton
                    sx={{ borderRadius: "0px 8px 8px 0px" }}
                    value="Inactive"
                    aria-label="Inactive"
                  >
                    Inactive
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className="guestActivity_End">
                <button type="submit" className="guestActivity_buttonb">
                  {isAddMode ? "Add New" : "Update GuestActivity"}
                </button>
                &nbsp;&nbsp;&nbsp;
                <button
                  className="guestActivity_buttona"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <ToastContainer />
    </>
  );
};

export default GuestActivityPopup;
