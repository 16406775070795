import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ItemPopup from "./ItemPopup.js";
import "./Item.css";
import AddButton from "../../common/Button/Button.js";
import ExportFile from "../../common/ExportFile/ExportFile.jsx";
import SearchBar from "../../common/SearchBar/SearchBar.js";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DeleteConfirmationDialog from "../../common/DeleteConfirmation/confirmdelete.js";
import Menu from "@mui/material/Menu";
import { toast, Zoom } from "react-toastify";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import trash from "../../assets/icons/trash.svg";
import edit from "../../assets/icons/edit.svg";
import Constants from "../../Constants.js";
import callApi from "../../ApiCall.js";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Grid } from "@material-ui/core";

const MenuItemWrapper = styled("li")(() => ({
  padding: "8px 8px",
  boxShadow: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F75555",
    color: "#FFFFFF",
    img: {
      filter:
        "invert(92%) sepia(4%) saturate(8%) hue-rotate(126deg) brightness(138%) contrast(200%)",
    },
  },
  transition: "all 0.3s ease",
}));

const IconWrapper = styled("span")(() => ({
  fontSize: "16px",
  marginRight: "8px",
  "& .MuiSvgIcon-root": {
    fontSize: "inherit",
  },
}));

const Item = (props) => {
  // table columns
  const columns = [
    { field: "id", headerName: "Sr No", flex: 1 },
    { field: "ItemName", headerName: "Item Name", flex: 1 },
    { field: "Description", headerName: "Description", flex: 1 },
    { field: "CategoryName", headerName: "Category Name", flex: 1 },
    { field: "Pack", headerName: "Pack", flex: 1 },
    { field: "UOM", headerName: "UOM", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            color: params.row.status === "Active" ? "#0ABE75" : "#F75555",
          }}
        >
          {params.row.status}
        </div>
      ),
    },

    props.currentMenu.Edit || props.currentMenu.Delete
      ? {
          field: "actions",
          headerName: "Actions",
          flex: 1,
          renderCell: (params) => (
            <div>
              <IconButton
                color="primary"
                aria-label="actions"
                onClick={(event) => handleMenuOpen(event, params.row)}
              >
                <MoreVertIcon style={{ color: "gray" }} />
              </IconButton>
              <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                sx={{
                  "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                    {
                      boxShadow: "none",
                      backgroundColor: "#EEE",
                      borderRadius: "8px",
                    },
                  "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                    paddingTop: "0",
                    paddingBottom: "0",
                  },
                }}
              >
                {props.currentMenu.Edit ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("edit")}>
                    <IconWrapper>
                      <img src={edit} />
                    </IconWrapper>
                    <span style={{ fontWeight: "400" }}>Edit</span>
                  </MenuItemWrapper>
                ) : null}

                {props.currentMenu.Delete ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("delete")}>
                    <IconWrapper>
                      <img src={trash} />
                    </IconWrapper>
                    <span style={{ fontWeight: "400" }}>Delete</span>
                  </MenuItemWrapper>
                ) : null}
              </Menu>
            </div>
          ),
        }
      : {},
  ];

  const [showItemPopup, setshowItemPopup] = useState(false);
  const [item, setitem] = useState([]);
  const [isAddMode, setisAddMode] = useState(true);
  const [selectedRowForActions, setselectedRowForActions] = useState(null);
  const [selectedItemToUpdate, setselectedItemToUpdate] = useState(null);
  const [filtering, setFiltering] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedReturn, setSelectedReturn] = useState("Return");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [selectedItemToDelete, setSelectedItemToDelete] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const initialSortedRows = item;
  const [sortedRows, setSortedRows] = useState(initialSortedRows);
  const pageSize = 10;


  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  // handleFunctions
  const handleitemActivityPopupClose = () => {
    setshowItemPopup(false);
    setisAddMode(true);
    setselectedItemToUpdate(null);
    getItems();
  };

  // GETAPI
  const getItems = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getItemMaster,
      });
      if (response?.success) {
        const { item = [] } = response?.data;
        const mappedData = item.map((item, index) => ({
          id: index + 1,
          ...item,
          status: item.IsActive === 1 ? "Active" : "Inactive",
          UOM: item.UOMName,
          returnStatus: item.IsReturn === 1 ? "Return" : "NoReturn",
        }));
        setitem(mappedData);
      } else {
        console.error("Error fetching item data:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  // Delete API

  const handleConfirmDelete = async (selectedRow) => {
    try {
      const response = await callApi({
        apiURL: Constants.API.deleteItemMaster,
        requestBody: {
          itemMasterId: selectedRow.ItemMasterId,
          updateDate: selectedRow.UpdatedDate,
        },
      });
      if (response?.success) {
        const actionMessage = "Deleted Successfully";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            Item has been deleted successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            theme: "colored",
            className: "toast_pop",
            autoClose: 1500,
            transition: Zoom,
            closeButton: true,
            onClose: () => {
              getItems();
            },
          }
        );
        getItems();
      } else {
        console.error("Error deleting item:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setShowDeleteConfirmation(false);
      setPage(1);
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  const handleAddProductClick = () => {
    setisAddMode(true);
    setshowItemPopup(true);
  };

  // for edit and delete menu
  const handleMenuOpen = (e, row) => {
    setAnchorEl(e.currentTarget);
    setselectedItemToUpdate(row);
    setSelectedItemToDelete(row);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  // select edit and delete option
  const handleMenuSelect = (option) => {
    if (option === "edit") {
      handleUpdateClick(selectedItemToUpdate);
      handleMenuClose();
    } else if (option === "delete") {
      handleDeleteClick(selectedItemToDelete);
    }
  };

  // updateItemclick
  const handleUpdateClick = (selectedRow) => {
    setSelectedItemToDelete(selectedRow);
    setisAddMode(false);
    setshowItemPopup(true);
    getItems();
  };

  const handleDeleteClick = (selectedRow) => {
    setSelectedItemToDelete(selectedRow);
    setShowDeleteConfirmation(true);
  };

  // filtering based on status

  const handleSortClick = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };

  const selectedStatusData =
    selectedStatus !== "all"
      ? sortedRows.filter((val) => val.status === selectedStatus)
      : sortedRows;
  const totalPages = Math.ceil(selectedStatusData.length / pageSize);

  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };

  const renderArrowIcon = (column) => {
    if (sortedColumn === column) {
      return (
        <span className="arrow-icon">
          {sortOrder === "asc" ? (
            <i className="fa-solid fa-arrow-up" />
          ) : (
            <i className="fa-solid fa-arrow-down" />
          )}
        </span>
      );
    }
    return null;
  };

  useEffect(() => {
    if (item.length > 0) {
      setSortedRows(item);
    }
  }, [item.length > 0]);

  // Sort columns
  useEffect(() => {
    if (sortedColumn) {
      const sorted = item.slice().sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];
        if (aValue < bValue) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedRows(sorted);
    }
  }, [sortedColumn, sortOrder, item]);

  const handleSearchfilter = (event) => {
    setFiltering(event.target.value);
    setPage(1);
  };

  // search items
  useEffect(() => {
    const searched = item.filter((i) => {
      if (!filtering) {
        return true;
      } else {
        return (
          (i.ItemName !== null &&
            i.ItemName.toLowerCase().includes(filtering.toLowerCase())) ||
          (i.Description !== null &&
            i.Description.toString()
              .toLowerCase()
              .includes(filtering.toLowerCase())) ||
          (i.CategoryName !== null &&
            i.CategoryName.toLowerCase().includes(filtering.toLowerCase()))
        );
      }
    });
    setSortedRows(searched);
  }, [filtering, item]);

  const paginatedRows = selectedStatusData.slice(startIndex, endIndex);

  //export PDF and Excel
  const handleExportClick = () => {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      const fileName = `Item_${formattedDate.replace(/ /g, "")}.xlsx`;
      const worksheetName = `Item`;

      if (paginatedRows.length > 0) {
        const exportData = paginatedRows.map((row, index) => ({
          "Sr No.": index + 1,
          "Item Name": row.ItemName,
          Description: row.Description,
          "Category Name": row.CategoryName,
          Pack: row.Pack,
          Uom: row.UOM,
          Status: row.status,
        }));

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
        XLSX.writeFile(workbook, fileName);
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF("p");
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    const fileName = `Service_${formattedDate.replace(/ /g, "")}.pdf`;

    if (paginatedRows.length > 0) {
      let tableData = paginatedRows.map((row, index) => {
        return [
          index + 1,
          row.ItemName,
          row.Description,
          row.CategoryName,
          row.Pack,
          row.UOM,
          row.status,
        ];
      });

      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("Services", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });

      autoTable(doc, {
        head: [
          [
            "Sr No",
            "Service Name",
            "Service Rate",
            "Category Name",
            "Pack",
            "UOM",
            "Status",
          ],
        ],
        body: tableData,
        theme: "grid",
      });

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        formattedDate,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );

      doc.save(fileName);
    } else {
      console.error("No data to export");
    }
  };

  const handleStatusChange = (event, newStatus) => {
    setPage(1);
    setSelectedStatus(newStatus);
  };

  return (
    <>
      {showItemPopup && (
        <ItemPopup
          onClose={handleitemActivityPopupClose}
          isAddMode={isAddMode}
          selectedItemToUpdate={selectedItemToUpdate}
        />
      )}
      <div className="itemmaster_MainPage">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <SearchBar value={filtering} onChange={handleSearchfilter} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <ToggleButtonGroup
              className="ToggleButtonGroups"
              value={selectedStatus}
              exclusive
              onChange={handleStatusChange}
              aria-label="text alignment"
            >
              <ToggleButton
                value="all"
                aria-label="all"
                disabled={selectedStatus === "all"}
                sx={{
                  textTransform: "none",
                  padding: "8px 16px",
                  borderRadius: "8px 0px 0px 8px",
                }}
              >
                {" "}
                All
              </ToggleButton>
              <ToggleButton
                value="Active"
                aria-label="Active"
                disabled={selectedStatus === "Active"}
                sx={{ textTransform: "none", padding: "8px 16px" }}
              >
                Active
              </ToggleButton>
              <ToggleButton
                value="Inactive"
                aria-label="Inactive"
                disabled={selectedStatus === "Inactive"}
                sx={{
                  textTransform: "none",
                  padding: "8px 16px",
                  borderRadius: "0px 8px 8px 0px",
                }}
              >
                Inactive
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>{" "}
          <Grid item xs={12} sm={6} md={6} lg={2}>
            {" "}
            <ExportFile
              handleExportClick={handleExportClick}
              filteredRows={item}
              handleExportPDF={handleExportPDF}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2}>
            {props?.currentMenu?.Add ? (
              <>
                <AddButton
                  buttonText={"Add Item"}
                  onClick={handleAddProductClick}
                />
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>

        <div className="itemmasterTable">
          <div className="Table">
            <DeleteConfirmationDialog
              open={showDeleteConfirmation}
              handleClose={() => {
                setShowDeleteConfirmation(false);
                handleMenuClose();
              }}
              handleConfirm={() => {
                handleConfirmDelete(selectedItemToDelete);
                handleMenuClose();
              }}
              product={"Item"}
            />
            <div className="itemdatgrid">
              <div className="itemmaster-table">
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell
                            key={i}
                            className="tableHeaderCell"
                            onClick={() => handleSortClick(column.field)}
                          >
                            {column.headerName}
                            {renderArrowIcon(column.field)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedRows.map((row, i) => (
                        <TableRow key={i}>
                          {columns.map((column) => (
                            <TableCell key={i} className="tableBodyCell">
                              {column.renderCell
                                ? column.renderCell({ row })
                                : row[column.field]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={totalPages}
                  page={page}
                  hideNextButton
                  hidePrevButton
                  onChange={(event, value) => setPage(value)}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      page={formattedPageNumber(item.page)}
                      className={item.selected ? "pagination-selected" : ""}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
