import React from "react";
import * as XLSX from "xlsx";
import GuestActivityPopup from "./GuestActivityPopUp";
import "./GuestActivity.css";
import ExportFile from "../../common/ExportFile/ExportFile.jsx";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { toast, Zoom } from "react-toastify";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import callApi from "../../ApiCall";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteConfirmationDialog from "../../common/DeleteConfirmation/confirmdelete";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip from "@mui/material/Tooltip";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

import trash from "../../assets/icons/trash.svg";
import edit from "../../assets/icons/edit.svg";
import SearchBar from "../../common/SearchBar/SearchBar.js";
import AddButton from "../../common/Button/Button.js";
import { Grid } from "@material-ui/core";

const MenuItemWrapper = styled("li")(() => ({
  padding: "8px 8px",
  boxShadow: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F75555",
    color: "#FFFFFF",
    img: {
      filter:
        "invert(92%) sepia(4%) saturate(8%) hue-rotate(126deg) brightness(138%) contrast(200%)",
    },
  },
  transition: "all 0.3s ease",
}));

const IconWrapper = styled("span")(() => ({
  fontSize: "16px",
  marginRight: "8px",
  "& .MuiSvgIcon-root": {
    fontSize: "inherit",
  },
}));

export default function GuestActivityMaster(props) {
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [guestActivity, setGuestActivity] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedProductForDelete, setSelectedProductForDelete] =
    useState(null);
  const [selectedProductForUpdate, setSelectedProductForUpdate] =
    useState(null);
  const [showGuestActivityPopup, setShowGuestActivityPopup] = useState(false);
  const [isAddMode, setIsAddMode] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRowForActions, setSelectedRowForActions] =
    React.useState(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [page, setPage] = useState(1);
  const [propertyDetail, setPropertyDetail] = useState([]);

  const columns = [
    { field: "id", headerName: "Sr No", flex: 1 },
    { field: "GuestActivityName", headerName: "Activity Name", flex: 1 },
    {
      field: "GuestActivityDescription",
      headerName: "Activity Description",
      flex: 1,
    },
    { field: "GuestActivityPrice", headerName: "Activity Price", flex: 1 },
    {
      field: "ActivityTypeName",
      headerName: "Activity Type",
      flex: 1,
    },
    {
      field: "GuestActivityAssignedEmployees",
      headerName: "Assigned Employees",
      flex: 1,
      renderCell: (params) => {
        const assignedEmployees = JSON.parse(
          params.row.GuestActivityAssignedEmployees
        );

        if (!Array.isArray(assignedEmployees)) return null;

        const MAX_DISPLAY_EMPLOYEES = 1;

        const filteredEmployees = assignedEmployees.filter(
          (employee) => employee.FullName !== null
        );

        const slicedEmployees = filteredEmployees.slice(
          0,
          MAX_DISPLAY_EMPLOYEES
        );

        const remainingCount = filteredEmployees.length - MAX_DISPLAY_EMPLOYEES;

        const displayedEmployees = slicedEmployees.map((employee, index) => (
          <span
            style={{ cursor: "pointer" }}
            key={index}
          >{`${employee.FullName} `}</span>
        ));

        const fullNames = filteredEmployees
          .map((employee) => employee.FullName)
          .join("<br/>");

        return (
          <Tooltip title={<div>{parse(fullNames)}</div>}>
            <div style={{ cursor: "pointer" }}>
              {displayedEmployees}
              {remainingCount > 0 && `,+ ${remainingCount} ...`}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            color: params.row.status === "Active" ? "#0ABE75" : "#F75555",
          }}
        >
          {params.row.status}
        </div>
      ),
    },
    props.currentMenu.Edit || props.currentMenu.Delete
      ? {
          field: "actions",
          headerName: "Actions",
          flex: 1,
          renderCell: (params) => (
            <div>
              <IconButton
                color="primary"
                aria-label="actions"
                onClick={(event) => handleMenuOpen(event, params.row)}
              >
                <MoreVertIcon style={{ color: "gray" }} />
              </IconButton>
              <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                sx={{
                  "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                    {
                      boxShadow: "none",
                      backgroundColor: "#EEE",
                      borderRadius: "8px",
                    },
                  "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                    paddingTop: "0",
                    paddingBottom: "0",
                  },
                }}
              >
                {props.currentMenu.Edit ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("edit")}>
                    <IconWrapper>
                      <img src={edit} />
                    </IconWrapper>
                    <span style={{ fontWeight: "400" }}>Edit</span>
                  </MenuItemWrapper>
                ) : null}

                {props.currentMenu.Delete ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("delete")}>
                    <IconWrapper>
                      <img src={trash} />
                    </IconWrapper>
                    <span style={{ fontWeight: "400" }}>Delete</span>
                  </MenuItemWrapper>
                ) : null}
              </Menu>
            </div>
          ),
        }
      : {},
  ];

  const handleDeleteClick = (selectedRow) => {
    setSelectedProductForDelete(selectedRow);
    setShowDeleteConfirmation(true);
  };

  // Delete Api
  const handleConfirmDelete = async (selectedRow) => {
    try {
      const response = await callApi({
        apiURL: "guestActivitymaster/deleteGuestActivity",
        requestBody: {
          activityMasterId: selectedRow?.ActivityMasterId,
          updatedDate: selectedRow?.UpdatedDate,
        },
      });
      if (response?.success) {
        const actionMessage = "Deleted Successfully";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            Activity has been deleted successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,

            theme: "colored",
            className: "toast_pop",
            autoClose: 1500,
            closeButton: true,
            onClose: () => {
              getUserDetails();
            },
            transition: Zoom,
          }
        );
        getUserDetails();
      } else {
        console.error("Error deleting plan:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setShowDeleteConfirmation(false);
      setPage(1);
    }
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowForActions(row);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleMenuSelect = (option) => {
    if (option === "edit") {
      handleUpdateClick(selectedRowForActions);
    } else if (option === "delete") {
      handleDeleteClick(selectedRowForActions);
    }

    handleMenuClose();
  };

  const handleUpdateClick = (selectedRow) => {
    setSelectedProductForUpdate(selectedRow);
    setIsAddMode(false);
    setShowGuestActivityPopup(true);
  };

  const handleAddProductClick = () => {
    setIsAddMode(true);
    setShowGuestActivityPopup(true);
  };

  const handleGuestActivityPopupClose = () => {
    setShowGuestActivityPopup(false);
    setIsAddMode(true);
    setSelectedProductForUpdate(null);
    getUserDetails();
  };

  const getUserDetails = async () => {
    try {
      const response = await callApi({
        apiURL: "guestActivitymaster/getGuestActivity",
      });

      if (response?.success) {
        const { guestActivityList = [] } = response.data;

        const mappedData = guestActivityList.map((item, index) => ({
          id: index + 1,
          ...item,
          PropertyLocationId: item.PropertyLocationId,
          GuestActivityName: item.ActivityName,
          GuestActivityDescription: item.ActivityDescription,
          GuestActivityPrice: item.ActivityPrice,
          GuestActivityType: item.ActivityType,
          GuestActivityAssignedEmployees: item.AssignedEmployees,
          ActivityLocationMasterId: item.ActivityLocationMasterId,
          TimeSlots: item.TimeSlots,
          // ActivityEndTime: item.ActivityEndTime,
          // ActivityStartTime: item.ActivityStartTime,
          status: item.Active === 1 ? "Active" : "Inactive",
        }));
        setGuestActivity(mappedData);
      } else {
        console.error("Error fetching guest activity data:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };


  const getPropertyDetail = async () => {
    try {
      const response = await callApi({
        apiURL: "employeemaster/getPropertyDetails",
      });
      const dropdownData = response.data.response.map((val) => ({
        Category: val?.Category,
        Data: JSON.parse(val?.Data),
      }));
      setPropertyDetail(dropdownData);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  useEffect(() => {
    getUserDetails();
    getPropertyDetail();
  }, []);

  //export to xl

  const handleExportClick = (filteredRows) => {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      const fileName = `Guest Activity ${formattedDate.replace(/ /g, "")}.xlsx`;
      const worksheetName = `Guest Activity`;

      if (paginatedRows?.length > 0) {
        const exportData = paginatedRows.map((row, index) => ({
          "Sr No.": index + 1,
          "Activity Name": row.GuestActivityName,
          Description: row.GuestActivityDescription,
          Price: row.GuestActivityPrice,
          "Activity Type": row.ActivityTypeName,
          "Assigned Employees":
            JSON.parse(row.GuestActivityAssignedEmployees)
              ?.map((employee) => employee.FullName)
              .join(", ") || "",
          Status: row.status,
        }));

        const workSheet = XLSX.utils.json_to_sheet(exportData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, worksheetName);
        XLSX.writeFile(workBook, fileName);
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF("p");
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    const fileName = `Guest_${formattedDate.replace(/ /g, "")}.pdf`;

    const tableHeaders = [
      "Sr No",
      "Activity Name",
      "Activity Description",
      "Activity Price",
      "Activity Type",
      "Assigned Employees",
      "Status",
    ];

    if (paginatedRows.length > 0) {
      let tableData = paginatedRows.map((row, index) => {
        return [
          index + 1,
          row.GuestActivityName,
          row.GuestActivityDescription,
          row.GuestActivityPrice,
          row.ActivityTypeName,
          JSON.parse(row.GuestActivityAssignedEmployees)
            ?.map((employee) => employee.FullName)
            .join(", ") || "",
          row.status,
        ];
      });

      // Add title
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text(
        "Guest Activity Location",
        doc.internal.pageSize.getWidth() / 2,
        10,
        { align: "center" }
      );

      // Add table to PDF
      doc.autoTable({
        head: [tableHeaders],
        body: tableData,
        startY: 20,
        theme: "grid",
      });

      // Add date and time at bottom right
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        formattedDate,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );

      // Save PDF
      doc.save(fileName);
    } else {
      console.error("No data to export to PDF.");
    }
  };

  const handleStatusChange = (newStatus) => {
    setPage(1);
    setSelectedStatus(newStatus.target.value);
  };

  const initialSortedRows = guestActivity;
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortedRows, setSortedRows] = useState(initialSortedRows);
  const pageSize = 10;
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };

  const selectedStatusData =
    selectedStatus !== "all"
      ? sortedRows.filter((val) => val.status === selectedStatus)
      : sortedRows;

  const totalPages = Math.ceil(selectedStatusData.length / pageSize);

  const handleSortClick = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };

  const renderArrowIcon = (column) => {
    if (sortedColumn === column) {
      return (
        <span className="arrow-icon">
          {sortOrder === "asc" ? (
            <i className="fa-solid fa-arrow-up" />
          ) : (
            <i className="fa-solid fa-arrow-down" />
          )}
        </span>
      );
    }
    return null;
  };

  useEffect(() => {
    if (guestActivity.length > 0) {
      setSortedRows(guestActivity);
    }
  }, [guestActivity.length > 0]);

  useEffect(() => {
    if (sortedColumn) {
      const sorted = guestActivity.slice().sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];
        if (aValue < bValue) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedRows(sorted);
    }
  }, [sortedColumn, sortOrder, guestActivity]);

  const [filtering, setFiltering] = useState("");
  const handleSearchfilter = (event) => {
    setFiltering(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    const searched = guestActivity?.filter((item) => {
      if (!filtering) {
        return true;
      } else {
        return (
          (item.ActivityName !== null &&
            item.ActivityName.toLowerCase().includes(
              filtering.toLowerCase()
            )) ||
          (item.ActivityTypeName !== null &&
            item.ActivityTypeName.toLowerCase().includes(
              filtering.toLowerCase()
            )) ||
          (item.ActivityDescription !== null &&
            item.ActivityDescription.toLowerCase().includes(
              filtering.toLowerCase()
            )) ||
          (item.ActivityPrice !== null &&
            item.ActivityPrice.toString()
              .toLowerCase()
              .includes(filtering.toLowerCase())) ||
          (item.AssignedEmployees !== null &&
            item.AssignedEmployees.toLowerCase().includes(
              filtering.toLowerCase()
            )) ||
          (item.status !== null &&
            item.status.toLowerCase().includes(filtering.toLowerCase()))
        );
      }
    });
    setSortedRows(searched);
  }, [filtering, guestActivity]);

  const paginatedRows = selectedStatusData.slice(startIndex, endIndex);

  return (
    <>
      {showGuestActivityPopup && (
        <GuestActivityPopup
          onClose={handleGuestActivityPopupClose}
          isAddMode={isAddMode}
          selectedGuestActivity={selectedProductForUpdate}
          guestActivity={guestActivity}
          propertyDetail={propertyDetail}
        />
      )}
      <div className="guestActivity_MainPae">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <SearchBar value={filtering} onChange={handleSearchfilter} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <ToggleButtonGroup
              className="ToggleButtonGroups"
              value={selectedStatus}
              exclusive
              onChange={handleStatusChange}
              aria-label="text alignment"
            >
              <ToggleButton
                value="all"
                aria-label="all"
                disabled={selectedStatus === "all"}
                sx={{
                  textTransform: "none",
                  padding: "8px 16px",
                  borderRadius: "8px 0px 0px 8px",
                }}
              >
                All
              </ToggleButton>
              <ToggleButton
                value="Active"
                aria-label="Active"
                disabled={selectedStatus === "Active"}
                sx={{ textTransform: "none", padding: "8px 16px" }}
              >
                Active
              </ToggleButton>
              <ToggleButton
                value="Inactive"
                aria-label="Inactive"
                disabled={selectedStatus === "Inactive"}
                sx={{
                  textTransform: "none",
                  padding: "8px 16px",
                  borderRadius: "0px 8px 8px 0px",
                }}
              >
                Inactive
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <ExportFile
              handleExportClick={handleExportClick}
              filteredRows={guestActivity}
              handleExportPDF={handleExportPDF}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2}>
            {props?.currentMenu?.Add ? (
              <>
                <AddButton
                  buttonText={"Add GuestActivity"}
                  onClick={handleAddProductClick}
                />
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>

        <div className="GuestActivityTable">
          <div className="Table">
            <div className="guestActivity_sect-2"></div>
            <DeleteConfirmationDialog
              open={showDeleteConfirmation}
              handleClose={() => setShowDeleteConfirmation(false)}
              handleConfirm={() =>
                handleConfirmDelete(selectedProductForDelete)
              }
              product={"Guest"}
            />
            <div className="Guest_Activity_DataGrid">
              <div className="guestActivity-table">
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell
                            key={i}
                            className="tableHeaderCell"
                            onClick={() => handleSortClick(column.field)}
                          >
                            {column.headerName} {renderArrowIcon(column.field)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedRows.map((row, i) => (
                        <TableRow key={i}>
                          {columns.map((column) => (
                            <TableCell key={i} className="tableBodyCell">
                              {column.renderCell
                                ? column.renderCell({ row })
                                : row[column.field]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={totalPages}
                  page={page}
                  hideNextButton
                  hidePrevButton
                  onChange={(event, value) => setPage(value)}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      page={formattedPageNumber(item.page)}
                      className={item.selected ? "pagination-selected" : ""}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
