import React, { useEffect, useState } from "react";
import callApi from "../../ApiCall";
import Constants from "../../Constants";
import "./dashboardScreen.css";

const RoomCounts = () => {
  const [roomCount, setRoomCount] = useState([]);

  useEffect(() => {
    const fetchRoomData = async () => {
      try {
        const response = await callApi({
          apiURL: Constants.API.getRoomCount,
        });
        if (response?.success) {
          const roomCountData = response.data.roomCount || [];
          setRoomCount(roomCountData);
        } else {
          console.error("Failed to fetch room count:", response?.error);
        }
      } catch (error) {
        console.error("API call error:", error);
      }
    };

    fetchRoomData();
  }, []);

  return (
    <div className="dashboard-card-container1">
      <h2 className="dashboard-title1">
        Total Rooms: {roomCount.length > 0 ? roomCount[0]["Total Rooms"] : 0}
      </h2>

      <div className="dashboard-card1">
        <div className="dashboard-card-item1">
          <h3 className="dashboard-item-label1">Room Category</h3>
          {roomCount.map((room, index) => (
            <p key={index} className="dashboard-item-count1">
              {room["Room Category"]}
            </p>
          ))}
        </div>
        {/* <div className='vl'></div> */}
        <div className="dashboard-card-item2">
          <h3 className="dashboard-item-label2">Available Rooms</h3>
          {roomCount.map((room, index) => (
            <p key={index} className="dashboard-item-count2">
              {room["Available Rooms"]}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RoomCounts;
