import React, { useEffect, useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./HealthHistory.css";

import CustomizedHook from "./CustomizedHook";
import DatePicker from "react-multi-date-picker";
import { Formik, Field, Form, ErrorMessage } from "formik";
import MySelect from "../../../common/SingleSelect";
import * as Yup from "yup";
import callApi from "../../../ApiCall";
import { Category, Height } from "@material-ui/icons";
import moment from "moment";
// import { toast } from "react-toastify";
import { toast, Zoom } from "react-toastify";
import Constants from "../../../Constants";
import Select from "react-select";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import { PaginationItem } from "semantic-ui-react";

export default function HealthHistory({ GuestMasterId }) {
  // const [height, setHeight] = useState("");
  // const [heightUnit, setHeightUnit] = useState("cm"); // Default unit

  // const [feet, setFeet] = useState("");
  // const [inches, setInches] = useState("");

  const [weight, setWeight] = useState("");
  const [unit, setUnit] = useState("cm"); // 'ft' or 'cm'
  const [weightUnit, setWeightUnit] = useState("kg"); // 'kg' or 'lbs'
  const [error, setError] = useState("");

  // all usestate in dropdown
  // const [heightIdList, setHeightIdList] = useState([]);
  const [bloodGroupList, setBloodGroupList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [appetiteList, setAppetiteList] = useState([]);
  const [bowelList, setBowelList] = useState([]);
  const [urinationList, setUrinationList] = useState([]);
  const [menstruationList, setMenstruationList] = useState([]);
  const [sleepList, setSleepList] = useState([]);
  const [walkList, setWalkList] = useState([]);
  const [mentalStatusList, setMentalStatusList] = useState([]);
  const [weightIdList, setWeightIdList] = useState([]);
  const [gotData, setGotData] = useState(false);
  // setAppetiteList(prevList => [...prevList, healthData.Appetite || ""]);

  // LIST
  // const [selectedGuestBookingHistory, setSelectedGuestBookingHistory] =
  //   useState([]);

  const [getDailyHealthHistoryDataList, setGetDailyHealthHistoryDataList] =
    useState([]);

  // Date Start
  const [checkInDate, setCheckInDate] = useState(new Date());
  // const [checkInDate, setCheckInDate] = useState(null);

  const [checkInDate2, setCheckInDate2] = useState(null);
  const [checkInDateError, setCheckInDateError] = useState("");

  const [activeSection, setActiveSection] = useState("HealthHistory");

  const [dataValue, setDataValue] = useState({
    // HeightId: "",
    // Height: "",
    BloodGroup: "",
    Gender: "",
    Appetite: "",
    Bowel: "",
    Urination: "",
    Menstruation: "",
    Sleep: "",
    Walk: "",
    MentalStatus: "",
    PresentClinicalComplaint: "",
    PastClinicalillness: "",
    Medications: "",
    Height: "",
    FamilyHistory: "",
    Investigations: "",
    ProvisionalDiagnosis: "",
    FoodAllergy: "",
    WeightId: "",
    PulseRate: "",
    BloodPressure: "",
    RespiratoryRate: "",
    FBS: "",
    PPBS: "",
    RBS: "",
  });
  // const fetchHealthHistoryData = async (GuestMasterId) => {
  //   try {
  //     const response = await callApi({
  //       apiURL: Constants.API.getHealthHistoryDataList,
  //       requestBody: { guestMasterId: GuestMasterId },
  //     });

  //     if (response?.success) {
  //       const healthData = response.data.getHealthHistoryDataList[0];
  //       console.log("Health", healthData);

  //       setDataValue((prevState) => ({
  //         ...prevState,
  //         // Appetite: { value: 101, label: "Bad" } || "",
  //         Appetite: setAppetiteList((prevList) => [
  //           ...prevList,
  //           healthData.Appetite || "",
  //         ]),
  //         Bowel: healthData.Bowel || "",
  //         Urination: healthData.Urination || "",
  //         Menstruation: healthData.Menstruation || "",
  //         Sleep: healthData.Sleep || "",
  //         Walk: healthData.Walk || "",
  //         MentalStatus: healthData.MentalStatus || "",
  //         PresentClinicalComplaint: healthData.PresentClinicalComplaint || "",
  //         PastClinicalillness: healthData.PastClinicalillness || "",
  //         Medications: healthData.Medications || "",
  //         FamilyHistory: healthData.FamilyHistory || "",
  //         Investigations: healthData.Investigations || "",
  //         ProvisionalDiagnosis: healthData.ProvisionalDiagnosis || "",
  //         FoodAllergy: healthData.FoodAllergy || "",
  //       }));
  //     } else {
  //       console.error("Error fetching data:", response.error);
  //     }
  //   } catch (error) {
  //     console.error("Error during API Call:", error);
  //   }
  // };

  // DONE :

  // const fetchHealthHistoryData = async (GuestMasterId) => {
  //   try {
  //     const response = await callApi({
  //       apiURL: Constants.API.getHealthHistoryDataList,
  //       requestBody: { guestMasterId: GuestMasterId },
  //     });

  //     if (response?.success) {
  //       const healthData = response.data.getHealthHistoryDataList[0];
  //       console.log("Health", healthData);

  //       // Map options from state data
  //       const appetiteOption = appetiteList.find(
  //         (option) => option.value === parseInt(healthData.Appetite)
  //       );
  //       const bowelOption = bowelList.find(
  //         (option) => option.value === parseInt(healthData.Bowel)
  //       );
  //       const urinationOption = urinationList.find(
  //         (option) => option.value === parseInt(healthData.Urination)
  //       );
  //       const menstruationOption = menstruationList.find(
  //         (option) => option.value === parseInt(healthData.Menstruation)
  //       );
  //       const sleepOption = sleepList.find(
  //         (option) => option.value === parseInt(healthData.Sleep)
  //       );
  //       const walkOption = walkList.find(
  //         (option) => option.value === parseInt(healthData.Walk)
  //       );
  //       const mentalStatusOption = mentalStatusList.find(
  //         (option) => option.value === parseInt(healthData.MentalStatus)
  //       );

  //       // Set data value with options
  //       setDataValue((prevState) => ({
  //         ...prevState,
  //         Appetite: appetiteOption || "",
  //         Bowel: bowelOption || "",
  //         Urination: urinationOption || "",
  //         Menstruation: menstruationOption || "",
  //         Sleep: sleepOption || "",
  //         Walk: walkOption || "",
  //         MentalStatus: mentalStatusOption || "",
  //         PresentClinicalComplaint: healthData.PresentClinicalComplaint || "",
  //         PastClinicalillness: healthData.PastClinicalillness || "",
  //         Medications: healthData.Medications || "",
  //         FamilyHistory: healthData.FamilyHistory || "",
  //         Investigations: healthData.Investigations || "",
  //         ProvisionalDiagnosis: healthData.ProvisionalDiagnosis || "",
  //         FoodAllergy: healthData.FoodAllergy || "",
  //       }));
  //     } else {
  //       console.error("Error fetching data:", response.error);
  //     }
  //   } catch (error) {
  //     console.error("Error during API Call:", error);
  //   }
  // };

  // TRY :
  const fetchHealthHistoryData = async (GuestMasterId) => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getHealthHistoryDataList,
        requestBody: { guestMasterId: GuestMasterId },
      });

      if (response?.success) {
        const healthData = response.data.getHealthHistoryDataList[0];
        // console.log("Health", healthData);

        // // Height
        // if (healthData.RecordDate) {
        //   const recordDate = new Date(healthData.RecordDate);
        //   console.log("Record Date:", recordDate);
        //   setCheckInDate(recordDate);
        // }
        // RecordDate :
        // if (healthData.RecordDate) {
        //   const recordDate = new Date(healthData.RecordDate);
        //   console.log("Record Date:", recordDate);
        //   setCheckInDate(recordDate);
        // }

        if (healthData.RecordDate) {
          const recordDate = new Date(healthData.RecordDate);
          console.log("Original Record Date:", recordDate);

          const day = String(recordDate.getDate()).padStart(2, "0");
          const month = String(recordDate.getMonth() + 1).padStart(2, "0");
          const year = recordDate.getFullYear();

          const formattedDate = `${day}-${month}-${year}`;
          console.log("Formatted Record Date:", formattedDate);

          setCheckInDate(formattedDate);
        }

        // console.log("Fetched Health Data:", healthData);
        // Only options
        const appetiteOption =
          appetiteList.length > 0
            ? appetiteList.find(
                (option) => option.value === parseInt(healthData.Appetite)
              )
            : null;

        const bowelOption =
          bowelList.length > 0
            ? bowelList.find(
                (option) => option.value === parseInt(healthData.Bowel)
              )
            : null;
        const urinationOption =
          urinationList.length > 0
            ? urinationList.find(
                (option) => option.value === parseInt(healthData.Urination)
              )
            : null;
        const menstruationOption =
          menstruationList.length > 0
            ? menstruationList.find(
                (option) => option.value === parseInt(healthData.Menstruation)
              )
            : null;
        const sleepOption =
          sleepList.length > 0
            ? sleepList.find(
                (option) => option.value === parseInt(healthData.Sleep)
              )
            : null;
        const walkOption =
          walkList.length > 0
            ? walkList.find(
                (option) => option.value === parseInt(healthData.Walk)
              )
            : null;
        const mentalStatusOption =
          mentalStatusList.length > 0
            ? mentalStatusList.find(
                (option) => option.value === parseInt(healthData.MentalStatus)
              )
            : null;

        const genderOption =
          genderList.length > 0
            ? genderList.find(
                (option) => option.value === parseInt(healthData.Gender)
              )
            : null;
        const bloodGroupOption =
          bloodGroupList.length > 0
            ? bloodGroupList.find(
                (option) => option.value === parseInt(healthData.BloodGroup)
              )
            : null;

        // const heightIdOption =
        //   heightIdList.length > 0
        //     ? heightIdList.find(
        //         (option) => option.value === parseInt(healthData.HeightId)
        //       )
        //     : null;

        // Set data value
        setDataValue((prevState) => ({
          ...prevState,
          // HeightId: heightIdOption || "",
          Gender: genderOption || "",
          BloodGroup: bloodGroupOption || "",
          Appetite: appetiteOption || "",
          Bowel: bowelOption || "",
          Urination: urinationOption || "",
          Menstruation: menstruationOption || "",
          Sleep: sleepOption || "",
          Walk: walkOption || "",
          MentalStatus: mentalStatusOption || "",
          PresentClinicalComplaint: healthData?.PresentClinicalComplaint || "",
          PastClinicalillness: healthData?.PastClinicalillness || "",
          Medications: healthData?.Medications || "",
          Height: healthData?.Height || "",
          FamilyHistory: healthData?.FamilyHistory || "",
          Investigations: healthData?.Investigations || "",
          ProvisionalDiagnosis: healthData?.ProvisionalDiagnosis || "",
          FoodAllergy: healthData?.FoodAllergy || "",
        }));
      } else {
        console.error("Error fetching data:", response.error);
      }
    } catch (error) {
      console.error("Error during API Call:", error);
    }
  };

  useEffect(() => {
    // if (GuestMasterId) {
    //   fetchHealthHistoryData(GuestMasterId);
    // }
    if (activeSection === "HealthHistory" && GuestMasterId) {
      fetchHealthHistoryData(GuestMasterId);
    }
    setDataValue({
      // HeightId: "",
      BloodGroup: "",
      Gender: "",
      Appetite: "",
      Bowel: "",
      Urination: "",
      Menstruation: "",
      Sleep: "",
      Walk: "",
      MentalStatus: "",
      PresentClinicalComplaint: "",
      PastClinicalillness: "",
      Medications: "",
      Height: "",
      FamilyHistory: "",
      Investigations: "",
      ProvisionalDiagnosis: "",
      FoodAllergy: "",
      WeightId: "",
      PulseRate: "",
      BloodPressure: "",
      RespiratoryRate: "",
      FBS: "",
      PPBS: "",
      RBS: "",
    });
  }, [GuestMasterId, gotData, activeSection]);

  const [mealInputs, setMealInputs] = useState({
    PresentClinicalComplaint: "",
    PastClinicalillness: "",
    Medications: "",
    Height: "",
    FamilyHistory: "",
    Investigations: "",
    ProvisionalDiagnosis: "",
    FoodAllergy: "",

    PulseRate: "",
    BloodPressure: "",
    RespiratoryRate: "",
    FBS: "",
    PPBS: "",
    RBS: "",
  });

  const [mealSelector, setMealSelector] = useState({
    // HeightId: "",
    BloodGroup: "",
    Gender: "",
    Appetite: "",
    Bowel: "",
    Urination: "",
    Menstruation: "",
    Sleep: "",
    Walk: "",
    MentalStatus: "",

    WeightId: "",
  });

  const handleSectionChange = (section) => {
    setActiveSection(section);

    if (section === "HealthHistory") {
      if (GuestMasterId) {
        fetchHealthHistoryData(GuestMasterId);
      }
    }
  };
  const handleDateChange = (date) => {
    setCheckInDate(date);
    setCheckInDateError("");
  };

  const handleButtonClick = () => {
    setCheckInDate(new Date());
  };

  // const convertHeight = (value, fromUnit) => {
  //   if (fromUnit === "ft") {
  //     const [feet, inches] = value
  //       .split("'")
  //       .map((part) => part.trim().replace('"', "").trim());
  //     const totalCm = parseFloat(feet) * 30.48 + parseFloat(inches) * 2.54;
  //     return totalCm.toFixed(2);
  //   } else {
  //     const totalFeet = Math.floor(value / 30.48);
  //     const totalInches = Math.round((value % 30.48) / 2.54);
  //     return `${totalFeet}' ${totalInches}"`;
  //   }
  // };

  // const convertHeight = (value, fromUnit) => {
  //   if (fromUnit === "ft") {
  //     const [feet, inches] = value.split(" ").map((part) => part.trim());
  //     const totalCm = parseFloat(feet) * 30.48 + parseFloat(inches) * 2.54;
  //     return totalCm.toFixed(2);
  //   } else {
  //     const totalFeet = Math.floor(value / 30.48);
  //     const totalInches = Math.round((value % 30.48) / 2.54);
  //     return `${totalFeet} ${totalInches}`;
  //   }
  // };

  // const toggleHeightUnit = (e) => {
  //   const newUnit = e.target.value;
  //   setUnit(newUnit);
  //   setError("");

  //   if (newUnit === "cm" && height) {
  //     const cmValue = convertHeight(height, "ft");
  //     setHeight(cmValue);
  //   } else if (newUnit === "ft" && height) {
  //     const ftValue = convertHeight(height, "cm");
  //     setHeight(ftValue);
  //   }
  // };

  // const convertWeight = (value, fromUnit) => {
  //   return fromUnit === "kg"
  //     ? (value * 2.20462).toFixed(2)
  //     : (value / 2.20462).toFixed(2);
  // };

  // const toggleInput = (e) => {
  //   setUnit(e.target.value);
  //   setError("");

  //   if (e.target.value === "cm" && height) {
  //     const cmValue = convertHeight(height, "ft");
  //     setHeight(cmValue);
  //   } else if (e.target.value === "ft" && height) {
  //     const ftInchesValue = convertHeight(height, "cm");
  //     setHeight(ftInchesValue);
  //   }
  // };

  // const toggleWeightInput = (e) => {
  //   setWeightUnit(e.target.value);
  //   setError("");

  //   if (e.target.value === "lbs" && weight) {
  //     const lbsValue = convertWeight(weight, "kg");
  //     setWeight(lbsValue);
  //   } else if (e.target.value === "kg" && weight) {
  //     const kgValue = convertWeight(weight, "lbs");
  //     setWeight(kgValue);
  //   }
  // };

  // const validateInput = (e) => {
  //   const value = e.target.value;
  //   if (e.target.name === "Height") {
  //     setHeight(value);
  //     setError("");

  //     const regexFt = /^\d{1,2}'\s\d{1,2}"$/;
  //     const regexCm = /^\d+(\.\d+)?$/;

  //     if (unit === "ft" && !regexFt.test(value)) {
  //       setError("Enter Height in the format X' Y\" (e.g., 5' 7\").");
  //     } else if (unit === "cm" && !regexCm.test(value)) {
  //       setError("Enter a valid centimeters value.");
  //     }
  //   } else if (e.target.name === "weight") {
  //     setWeight(value);
  //     setError("");

  //     if (!/^\d*\.?\d*$/.test(value)) {
  //       setError(
  //         weightUnit === "kg"
  //           ? "Enter a valid kilograms value."
  //           : "Enter a valid pounds value."
  //       );
  //     }
  //   }
  // };

  // const validateInput = (e) => {
  //   const value = e.target.value;
  //   if (e.target.name === "Height") {
  //     setHeight(value);
  //     setError("");

  //     const regexFt = /^\d{1,2}\s+\d{1,2}$/;
  //     const regexCm = /^\d+(\.\d+)?$/;

  //     if (unit === "cm" && !regexFt.test(value)) {
  //       setError("Enter Height in the format X Y (e.g., 5 7).");
  //     } else if (unit === "ft" && !regexCm.test(value)) {
  //       setError("Enter a valid cm(e.g. 111)& ft(e.g. 1  11) value.");
  //     }
  //   } else if (e.target.name === "Weight") {
  //     setWeight(value);
  //     setError("");

  //     if (!/^\d*\.?\d*$/.test(value)) {
  //       setError(
  //         weightUnit === "kg"
  //           ? "Enter a valid kilograms value."
  //           : "Enter a valid pounds value."
  //       );
  //     }
  //   }
  // };
  const validateInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    // if (name === "Height") {
    //   setHeight(value);
    //   setError("");

    //   if (unit === "cm" && !/^\d+(\.\d+)?$/.test(value)) {
    //     setError("Enter a valid cm value (e.g., 170).");
    //   }
    // } else if (name === "Feet") {
    //   setFeet(value);
    // } else if (name === "Inches") {
    //   setInches(value);
    // } else
    if (e.target.name === "Weight") {
      setWeight(value);

      setError("");

      if (!/^\d*\.?\d*$/.test(value)) {
        setError(
          weightUnit === "kg"
            ? "Enter a valid kilograms value."
            : "Enter a valid pounds value."
        );
      }
    }
  };

  const imageContainers = document.querySelectorAll(".image-container");
  imageContainers.forEach((container) => {
    container.addEventListener("click", () => {
      imageContainers.forEach((container) => {
        container.classList.remove("selecteda");
      });
      container.classList.add("selecteda");
    });
  });

  const buttons = document.querySelectorAll(".selectable");
  buttons.forEach((button) => {
    button.addEventListener("click", () => {
      buttons.forEach((otherButton) =>
        otherButton.classList.remove("selectede")
      );
      button.classList.toggle("selectede");
    });
  });

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const handleSelectionChange = (field, value) => {
    console.log("a", field, value);
    setDataValue((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    // if (field === "HeightId") {
    //   if (value?.value === 153) {
    //     setUnit("cm");
    //   } else if (value?.value === 154) {
    //     setUnit("ft");
    //   }
    // }
  };

  const handleMealInputChange = (e) => {
    const { id, value } = e.target;
    setDataValue((prevInputs) => ({
      ...prevInputs,
      [id]: value,
    }));
  };

  const initialValuesForFormiks = {
    // HeightId: dataValue.HeightId,
    // Height: height,
    Recorddate: checkInDate,
    BloodGroup: dataValue.BloodGroup,
    Gender: dataValue.Gender,
    Appetite: dataValue.Appetite,
    Bowel: dataValue.Bowel,
    Urination: dataValue.Urination,
    Menstruation: dataValue.Menstruation,
    Sleep: dataValue.Sleep,
    Walk: dataValue.Walk,
    MentalStatus: dataValue.MentalStatus,
    PresentClinicalComplaint: dataValue.PresentClinicalComplaint,
    PastClinicalillness: dataValue.PastClinicalillness,
    Medications: dataValue.Medications,
    Height: dataValue.Height,
    FamilyHistory: dataValue.FamilyHistory,
    Investigations: dataValue.Investigations,
    ProvisionalDiagnosis: dataValue.ProvisionalDiagnosis,
    FoodAllergy: dataValue.FoodAllergy,
  };
  console.log("initialValuesForFormiks", initialValuesForFormiks);

  const initialValuesForFormikfollowup = {
    WeightId: "",
    Weight: "",
    PulseRate: "",
    BloodPressure: "",
    RespiratoryRate: "",
    FBS: "",
    PPBS: "",
    RBS: "",
  };
  // validation
  const validateSchema = Yup.object({
    // HeightId: Yup.string().required("HeightId is required"),
    RecordDate: Yup.date()
      .required("Record Date is required")
      .typeError("Invalid date"),
    BloodGroup: Yup.string().required("BloodGroup is required"),
    Gender: Yup.string().required("Gender is required"),
    Appetite: Yup.string().required("Appetite is required"),
    Bowel: Yup.string().required("Bowel is required"),
    Urination: Yup.string().required("Urination is required"),
    Menstruation: Yup.string().required("Menstruation is required"),
    Sleep: Yup.string().required("Sleep is required"),
    Walk: Yup.string().required("Walk is required"),
    MentalStatus: Yup.string().required("MentalStatus is required"),

    PresentClinicalComplaint: Yup.string()
      .matches(
        /^[A-Za-z0-9 ]*$/,
        "It must contain only letters, numbers, and spaces"
      )
      .max(100, "most be 100 characters")
      .required("PresentClinicalComplaint is required"),

    PastClinicalillness: Yup.string()
      .matches(
        /^[A-Za-z0-9 ]*$/,
        "It must contain only letters, numbers, and spaces"
      )
      .max(100, "most be 100 characters")
      .required("PastClinicalillness is required"),

    Medications: Yup.string()
      .matches(
        /^[A-Za-z0-9 ]*$/,
        "It must contain only letters, numbers, and spaces"
      )
      .max(100, "most be 100 characters")
      .required("Medications is required"),

    Height: Yup.string()
      .matches(/^[0-9 ]*$/, "It must contain only numbers")
      .max(10, "most be 10 characters")
      .required("Height is required"),

    FamilyHistory: Yup.string()
      .matches(
        /^[A-Za-z0-9 ]*$/,
        "It must contain only letters, numbers, and spaces"
      )
      .max(100, "most be 100 characters")
      .required("FamilyHistory is required"),

    Investigations: Yup.string()
      .matches(
        /^[A-Za-z0-9 ]*$/,
        "It must contain only letters, numbers, and spaces"
      )
      .max(100, "most be 100 characters")
      .required("Investigations is required"),

    ProvisionalDiagnosis: Yup.string()
      .matches(
        /^[A-Za-z0-9 ]*$/,
        "It must contain only letters, numbers, and spaces"
      )
      .max(100, "most be 100 characters")
      .required("ProvisionalDiagnosis is required"),

    FoodAllergy: Yup.string()
      .matches(
        /^[A-Za-z0-9 ]*$/,
        "It must contain only letters, numbers, and spaces"
      )
      .max(100, "most be 100 characters")
      .required("FoodAllergy is required"),
  });
  const validateSchemafollowup = Yup.object({
    WeightId: Yup.string().required("WeightId is required"),

    RecordDate: Yup.date()
      .required("Record Date is required")
      .typeError("Invalid date"),

    PulseRate: Yup.string()
      .matches(/^[0-9]*$/, "It must contain only numbers")
      .required("BloodPressure is required"),

    BloodPressure: Yup.string()
      .matches(/^[0-9]*$/, "It must contain only numbers")
      .required("BloodPressure is required"),

    RespiratoryRate: Yup.string()
      .matches(/^[0-9]*$/, "It must contain only numbers")
      .required("RespiratoryRate is required"),

    FBS: Yup.string()
      .matches(/^[0-9]*$/, "It must contain only numbers")
      .required("FBS is required"),

    PPBS: Yup.string()
      .matches(/^[0-9]*$/, "It must contain only numbers")
      .required("PPBS is required"),

    RBS: Yup.string()
      .matches(/^[0-9]*$/, "It must contain only numbers")
      .required("RBS is required"),
  });

  // get API For DropDown :
  const getaddHealthHistoryData = async (guestMasterId) => {
    try {
      const response = await callApi({
        apiURL: "guesthealthhistory/getaddHealthHistoryData",
        requestBody: { guestMasterId: guestMasterId },
      });
      const dropdownData = response?.data?.response?.map((val) => ({
        Category: val?.Category,
        Data: JSON.parse(val?.Data),
      }));

      // HeightId :

      // const heightId = dropdownData.find(
      //   (item) => item.Category === "HeightId"
      // );
      // const heightIdData =
      //   heightId &&
      //   heightId?.Data?.map((item) => ({
      //     value: item?.ID,
      //     label: item?.Name,
      //   }));
      // setHeightIdList(heightIdData);

      // BLOODGROUP :
      const bloodGroup = dropdownData.find(
        (item) => item.Category === "BloodGroup"
      );
      const bloodGroupData =
        bloodGroup &&
        bloodGroup?.Data?.map((item) => ({
          value: item?.ID,
          label: item?.Name,
        }));
      setBloodGroupList(bloodGroupData);

      // GENDER :
      const gender = dropdownData.find((item) => item.Category === "Gender");
      const genderData =
        gender &&
        gender?.Data?.map((item) => ({
          value: item?.ID,
          label: item.Name,
        }));
      setGenderList(genderData);

      // Appetite :
      const appetite = dropdownData.find(
        (item) => item.Category === "Appetite"
      );
      const appetiteData =
        appetite &&
        appetite?.Data?.map((item) => ({
          value: item?.ID,
          label: item?.Name,
        }));
      setAppetiteList(appetiteData);

      // Bowel :
      const bowel = dropdownData.find((item) => item.Category === "Bowel");
      const bowelData =
        bowel &&
        bowel?.Data?.map((item) => ({
          value: item?.ID,
          label: item?.Name,
        }));
      setBowelList(bowelData);

      // Urination :
      const urination = dropdownData.find(
        (item) => item.Category === "Urination"
      );
      const urinationData =
        urination &&
        urination?.Data?.map((item) => ({
          value: item?.ID,
          label: item?.Name,
        }));
      setUrinationList(urinationData);

      // Menstruation :
      const menstruation = dropdownData.find(
        (item) => item.Category === "Menstruation"
      );
      const menstruationData =
        menstruation &&
        menstruation?.Data?.map((item) => ({
          value: item?.ID,
          label: item?.Name,
        }));
      setMenstruationList(menstruationData);

      // Sleep :
      const sleep = dropdownData.find((item) => item.Category === "Sleep");
      const sleepData =
        sleep &&
        sleep?.Data?.map((item) => ({
          value: item?.ID,
          label: item?.Name,
        }));
      setSleepList(sleepData);

      // Walk :
      const walk = dropdownData.find((item) => item.Category === "Walk");
      const wlakData =
        walk &&
        walk?.Data?.map((item) => ({
          value: item?.ID,
          label: item?.Name,
        }));
      setWalkList(wlakData);

      // MentalStatus :
      const mentalstatus = dropdownData.find(
        (item) => item.Category === "MentalStatus"
      );
      const mentalstatusData =
        mentalstatus &&
        mentalstatus?.Data?.map((item) => ({
          value: item?.ID,
          label: item?.Name,
        }));
      setMentalStatusList(mentalstatusData);
      // start catch
      setGotData(true);
      // const selectedHeightId = heightIdData?.find(
      //   (item) => item.value === dataValue.HeightId
      // )?.value;
      // if (selectedHeightId === 153) {
      //   setUnit("cm");
      // } else if (selectedHeightId === 154) {
      //   setUnit("ft");
      // }
    } catch (error) {
      console.log("Error During API Call: ", error);
    }
  };

  const getaddDailyHealthHistoryData = async (guestMasterId) => {
    try {
      const response = await callApi({
        apiURL: "guestdailyhealth/getaddDailyHealthHistoryData",
        requestBody: { guestMasterId: guestMasterId },
      });
      const dropdownDatafollowup = response?.data?.response?.map((val) => ({
        Category: val?.Category,
        Data: JSON.parse(val?.Data),
      }));

      const weightId = dropdownDatafollowup.find(
        (item) => item.Category === "WeightId"
      );

      const weightIdData =
        weightId &&
        weightId?.Data?.map((item) => ({
          value: item?.ID,
          label: item?.Name,
        }));
      setWeightIdList(weightIdData);
    } catch (error) {
      console.log("Error During API Call", error);
    }
  };

  // // Show the LIST start

  // // Show the LIST END

  const DailyHealthHistoryDataList = async (GuestMasterId) => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getDailyHealthHistoryDataList,
        requestBody: GuestMasterId,
      });
      if (response?.success) {
        setGetDailyHealthHistoryDataList(
          response.data.getDailyHealthHistoryDataList
        );
      } else {
        console.log("Error", response.error);
      }
    } catch (error) {
      console.error("Error during API Call :", error);
    }
  };

  useEffect(() => {
    getaddHealthHistoryData(GuestMasterId);
    getaddDailyHealthHistoryData(GuestMasterId);

    const params = {
      guestMasterId: GuestMasterId,
    };

    DailyHealthHistoryDataList(params)
      .then((data) => {
        if (
          data &&
          data.guestDailyHelathDataList[0].GuestMasterId === GuestMasterId
        ) {
          const guestDailyDataDetails = data.guestDailyHelathDataList[0];
          setDataValue({
            ...setDataValue,
            PulseRate: guestDailyDataDetails.PulseRate || "",
            BloodPressure: guestDailyDataDetails.BloodPressure || "",
            RespiratoryRate: guestDailyDataDetails.RespiratoryRate || "",
            FBS: guestDailyDataDetails.FBS || "",
            PPBS: guestDailyDataDetails.PPBS || "",
            RBS: guestDailyDataDetails.RBS || "",
            Weight: guestDailyDataDetails.Weight || "",
            PulseRate: "",
            BloodPressure: "",
            RespiratoryRate: "",
            FBS: "",
            PPBS: "",
            RBS: "",
            Weight: "",
          });
        }
      })
      .catch((error) => {
        console.error("Error Fetching Daily Health", error);
      });
  }, [GuestMasterId]);

  const getFormattedDate = (date) => {
    if (date) {
      return moment(date).format("DD-MM-YYYY");
    }
    return null;
  };

  const handleSubmit = async () => {
    const formattedDate = getFormattedDate(checkInDate);
    // let heightValue = "";

    // if (unit === "cm") {
    //   heightValue = height;
    // } else if (unit === "ft") {
    //   heightValue = `${feet} ${inches}`;
    // }

    const payload = {
      GuestMasterId: GuestMasterId,
      // HeightId: dataValue.HeightId?.value,
      // Height: heightValue,
      RecordDate: formattedDate,
      BloodGroup: dataValue.BloodGroup?.value,
      Gender: dataValue.Gender?.value,
      Appetite: dataValue.Appetite?.value,
      Bowel: dataValue.Bowel?.value,
      Urination: dataValue.Urination?.value,
      Menstruation: dataValue.Menstruation?.value,
      Sleep: dataValue.Sleep?.value,
      Walk: dataValue.Walk?.value,
      MentalStatus: dataValue.MentalStatus?.value,
      // BOX
      PresentClinicalComplaint: dataValue.PresentClinicalComplaint,
      PastClinicalillness: dataValue.PastClinicalillness,
      Medications: dataValue.Medications,
      Height: dataValue.Height,
      FamilyHistory: dataValue.FamilyHistory,
      Investigations: dataValue.Investigations,
      ProvisionalDiagnosis: dataValue.ProvisionalDiagnosis,
      FoodAllergy: dataValue.FoodAllergy,
    };
    // const payload_2 = {
    //   HeightId: 153,
    //   Height: "22",
    //   RecordDate: "2024-10-19",
    //   BloodGroup: 25,
    //   Gender: 12,
    //   Appetite: 102,
    //   Bowel: 107,
    //   Urination: 113,
    //   Menstruation: 118,
    //   Sleep: 126,
    //   Walk: 130,
    //   MentalStatus: 140,
    //   PresentClinicalComplaint: "cecc",
    //   PastClinicalillness: "ece",
    //   Medications: "cewc",
    //   FamilyHistory: "wvwv",
    //   Investigations: "vv",
    //   ProvisionalDiagnosis: "vwvwv",
    //   FoodAllergy: "wv",
    //   loggedInUserId: 1,
    //   mobileNumber: "1111111111",
    //   propertyMasterId: 4,
    //   propertyLocationId: 13,
    //   roleMasterId: 3,
    // };

    try {
      const response = await callApi({
        // apiURL: Constants.API.addHealthHistoryData,
        apiURL: Constants.API.addHealthHistoryInputData,
        requestBody: payload,
      });
      if (response?.success) {
        console.log("Pass", response?.success);
      } else {
        console.error("Error response from API:", response);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }

    setDataValue({
      ...setDataValue,
      PresentClinicalComplaint: "",
      PastClinicalillness: "",
      Medications: "",
      Height: "",
      FamilyHistory: "",
      Investigations: "",
      ProvisionalDiagnosis: "",
      FoodAllergy: "",
      // HeightId: "",
      BloodGroup: "",
      Gender: "",
      Appetite: "",
      Bowel: "",
      Urination: "",
      Menstruation: "",
      Sleep: "",
      Walk: "",
      MentalStatus: "",
    });

    setCheckInDate(null);
    // setHeight("");
    // setFeet("");
    // setInches("");
  };

  const handleFollowUpSubmit = async () => {
    const formattedDate = getFormattedDate(checkInDate2);

    const payload_2 = {
      GuestMasterId: GuestMasterId,
      WeightId: dataValue.WeightId?.value,
      Weight: weight,
      RecordDate: formattedDate,

      PulseRate: dataValue.PulseRate,
      BloodPressure: dataValue.BloodPressure,
      RespiratoryRate: dataValue.RespiratoryRate,
      FBS: dataValue.FBS,
      PPBS: dataValue.PPBS,
      RBS: dataValue.RBS,
    };
    try {
      const response = await callApi({
        apiURL: Constants.API.addDailyHealthHistoryData,
        requestBody: payload_2,
      });
      if (response?.success) {
        const actionMessage = "Data SuccessFully Send In Database";
        toast.success(
          <div>
            <h5>{actionMessage}</h5>
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            hideProgressBar: true,
            className: "toast_pop",
            autoClose: 1500,
            transition: Zoom,
            closeButton: true,
          }
        );
      } else {
        console.error("Error response from API:", response);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }

    setDataValue({
      ...setDataValue,
      PulseRate: "",
      BloodPressure: "",
      RespiratoryRate: "",
      FBS: "",
      PPBS: "",
      RBS: "",
      WeightId: "",
    });

    setCheckInDate2(null);
    setWeight("");
  };

  return (
    <div className="HealthMain">
      <div className="button-group">
        <button
          className={`toggle-button ${
            activeSection === "HealthHistory" ? "active" : ""
          }`}
          onClick={() => handleSectionChange("HealthHistory")}
        >
          Health History
        </button>
        <button
          className={`toggle-button ${
            activeSection === "DailyHealthHistory" ? "active" : ""
          }`}
          onClick={() => handleSectionChange("DailyHealthHistory")}
        >
          Follow up History
        </button>
      </div>

      <div>
        {activeSection === "HealthHistory" && (
          <div className="Health1">
            <h2>Health History</h2>

            <b className="Heading">General</b>
            <p className="Description">Enter basic medical information.</p>
            <br />

            <Formik
              initialValues={initialValuesForFormiks}
              validationSchema={validateSchema}
            >
              <>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "space-between",
                    height: "5px",
                    alignItems: "center",
                  }}
                  className="health1_flex"
                >
                  {/* WORK : */}
                  {/* <div className="Height">
                    <label className="Designation-label" htmlFor="HeightId">
                      Height: <sup>*</sup>
                    </label>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Field
                        component={MySelect}
                        options={heightIdList}
                        id="HeightId"
                        name="HeightId"
                        placeholder="Select"
                        className="designation-dropdown"
                        onChange={(value) =>
                          handleSelectionChange("HeightId", value)
                        }
                        value={dataValue.HeightId}
                      />

                      {unit === "cm" ? (
                        <input
                          name="Height"
                          value={height}
                          placeholder="Enter Height in cm"
                          onChange={validateInput}
                          type="number"
                          min="0"
                          step="0.1"
                        />
                      ) : (
                        <>
                          <input
                            name="Feet"
                            value={feet}
                            placeholder="ft"
                            onChange={validateInput}
                            type="number"
                            min="0"
                            max="9"
                            step="1"
                          />
                          <input
                            name="Inches"
                            value={inches}
                            placeholder="in"
                            onChange={validateInput}
                            type="number"
                            min="0"
                            max="11"
                            step="1"
                          />
                        </>
                      )}
                    </div>
                  </div> */}

                  <div className="data_row1-1" style={{ marginLeft: "38%" }}>
                    <DatePicker
                      selected={checkInDate}
                      onChange={handleDateChange}
                      format="DD-MM-YYYY"
                      id="RecordDate"
                      name="RecordDate"
                      placeholder="Today's Date"
                      minDate={new Date()}
                      showPopperArrow={false}
                      className="custom-datepicker"
                      style={{
                        width: "27%",
                        borderRadius: "8px",
                        borderColor: "#ccc",
                        height: "46px",
                        cursor: "pointer",
                        marginLeft: "85%",
                        marginTop: "-50%",
                      }}
                      onClick={handleButtonClick}
                    />
                    {checkInDateError && (
                      <p className="booking_error">{checkInDateError}</p>
                    )}
                  </div>
                </div>

                <div className="healthhistory_select_main">
                  {/* Height : */}
                  <div className="Height">
                    <label
                      className="designation-dropdown"
                      title="Permanent Height"
                    >
                      Height<sup>*</sup>
                      <span>(Cm)</span>
                    </label>

                    <input
                      id="Height"
                      name="Height"
                      placeholder="Enter Height"
                      className="employee-detail-textarea"
                      value={dataValue.Height}
                      onChange={handleMealInputChange}
                      required
                    />

                    <ErrorMessage
                      name="Height"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* BloodGroup */}
                  <div className="blood-drop">
                    <label htmlFor="BloodGroup" className="Designation-label">
                      Blood Group <sup>*</sup>
                    </label>
                    <Field
                      component={MySelect}
                      options={bloodGroupList}
                      id="BloodGroup"
                      name="BloodGroup"
                      placeholder="Select"
                      className="designation-dropdown"
                      required
                      onChange={(value) =>
                        handleSelectionChange("BloodGroup", value)
                      }
                      value={dataValue.BloodGroup}
                    ></Field>
                    <ErrorMessage
                      name="BloodGroup"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* Gender */}
                  <div className="MaleFemale">
                    <label htmlFor="Gender" className="Designation-label">
                      Gender <sup>*</sup>
                    </label>
                    <Field
                      component={MySelect}
                      options={genderList}
                      id="Gender"
                      name="Gender"
                      placeholder="Select Gender"
                      className="designation-dropdown"
                      onChange={(value) =>
                        handleSelectionChange("Gender", value)
                      }
                      value={dataValue.Gender}
                      required
                    ></Field>
                    <ErrorMessage
                      name="Gender"
                      component="div"
                      className="error-message"
                    />
                  </div>{" "}
                  {/* Appetite */}
                  <div className="Appetite">
                    <label htmlFor="Appetite">
                      Appetite <sup>*</sup>
                    </label>
                    <Field
                      component={MySelect}
                      name="Appetite"
                      id="Appetite"
                      options={appetiteList}
                      placeholder="Select"
                      className="designation-dropdown"
                      onChange={(value) => {
                        console.log("cc", dataValue.Appetite);
                        handleSelectionChange("Appetite", value);
                      }}
                      value={dataValue.Appetite}
                      required
                    ></Field>
                    <ErrorMessage
                      name="Appetite"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* Bowel */}
                  <div className="Bowel">
                    <label htmlFor="guestActivity_fname">
                      Bowel <sup>*</sup>
                    </label>
                    <Field
                      component={MySelect}
                      name="Bowel"
                      id="Bowel"
                      options={bowelList}
                      placeholder="Select"
                      className="designation-dropdown"
                      onChange={(value) =>
                        handleSelectionChange("Bowel", value)
                      }
                      value={dataValue.Bowel}
                    ></Field>
                    <ErrorMessage
                      name="Bowel"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* Urination */}
                  <div className="Urination">
                    <label htmlFor="Urination">
                      Urination
                      <sup>*</sup>
                    </label>
                    <Field
                      component={MySelect}
                      name="Urination"
                      id="Urination"
                      options={urinationList}
                      placeholder="Select"
                      className="designation-dropdown"
                      onChange={(value) =>
                        handleSelectionChange("Urination", value)
                      }
                      value={dataValue.Urination}
                    ></Field>
                    <ErrorMessage
                      name="Urination"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* Menstruation */}
                  <div className="Menstruation">
                    <label htmlFor="Menstruation">
                      Menstruation
                      <sup>*</sup>
                    </label>
                    <Field
                      component={MySelect}
                      name="Menstruation"
                      id="Menstruation"
                      options={menstruationList}
                      placeholder="Select"
                      className="designation-dropdown"
                      onChange={(value) =>
                        handleSelectionChange("Menstruation", value)
                      }
                      value={dataValue.Menstruation}
                    ></Field>
                    <ErrorMessage
                      name="Menstruation"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* Sleep */}
                  <div className="Sleep">
                    <label htmlFor="Sleep">
                      Sleep
                      <sup>*</sup>
                    </label>
                    <Field
                      component={MySelect}
                      name="Sleep"
                      id="Sleep"
                      options={sleepList}
                      placeholder="Select"
                      className="designation-dropdown"
                      onChange={(value) =>
                        handleSelectionChange("Sleep", value)
                      }
                      value={dataValue.Sleep}
                    ></Field>
                    <ErrorMessage
                      name="Sleep"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* Walk */}
                  <div className="Walk">
                    <label htmlFor="Walk">
                      Walk
                      <sup>*</sup>
                    </label>
                    <Field
                      component={MySelect}
                      name="Walk"
                      id="Walk"
                      options={walkList}
                      placeholder="Select"
                      className="designation-dropdown"
                      onChange={(value) => handleSelectionChange("Walk", value)}
                      value={dataValue.Walk}
                    ></Field>
                    <ErrorMessage
                      name="Walk"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* MentalStatus */}
                  <div className="MentalStatus">
                    <label htmlFor="MentalStatus">
                      MentalStatus
                      <sup>*</sup>
                    </label>
                    <Field
                      component={MySelect}
                      name="MentalStatus"
                      id="MentalStatus"
                      options={mentalStatusList}
                      placeholder="Select"
                      className="designation-dropdown"
                      onChange={(value) =>
                        handleSelectionChange("MentalStatus", value)
                      }
                      value={dataValue.MentalStatus}
                      disabled
                    ></Field>
                    <ErrorMessage
                      name="MentalStatus"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                <div className="healthhistory_input_box">
                  {/*PresentClinicalComplaint  */}
                  <div className="PresentClinicalComplaint">
                    <label
                      className="employee-detail-label"
                      title="Permanent PresentClinicalComplaint"
                    >
                      PresentClinicalComplaint<sup>*</sup>
                    </label>

                    <input
                      id="PresentClinicalComplaint"
                      name="PresentClinicalComplaint"
                      placeholder="Enter PresentClinicalComplaint"
                      className="employee-detail-textarea"
                      value={dataValue.PresentClinicalComplaint}
                      onChange={handleMealInputChange}
                      required
                    />

                    <ErrorMessage
                      name="PresentClinicalComplaint"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* PastClinicalillness */}
                  <div className="PastClinicalillness">
                    <label
                      className="employee-detail-label"
                      title="Permanent PastClinicalillness"
                    >
                      PastClinicalillness<sup>*</sup>
                    </label>

                    <input
                      id="PastClinicalillness"
                      name="PastClinicalillness"
                      placeholder="Enter PastClinicalillness"
                      className="employee-detail-textarea"
                      value={dataValue.PastClinicalillness}
                      onChange={handleMealInputChange}
                      required
                    />
                    <ErrorMessage
                      name="PastClinicalillness"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* Medications */}
                  <div className="Medications">
                    <label
                      className="employee-detail-label"
                      title="Permanent Medications"
                    >
                      Medications<sup>*</sup>
                    </label>

                    <input
                      id="Medications"
                      name="Medications"
                      placeholder="Enter Medications"
                      className="employee-detail-textarea"
                      value={dataValue.Medications}
                      onChange={handleMealInputChange}
                      required
                    />
                    <ErrorMessage
                      name="Medications"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* FamilyHistory */}
                  <div className="FamilyHistory">
                    <label
                      className="employee-detail-label"
                      title="Permanent FamilyHistory"
                    >
                      FamilyHistory<sup>*</sup>
                    </label>

                    <input
                      id="FamilyHistory"
                      name="FamilyHistory"
                      placeholder="Enter FamilyHistory"
                      className="employee-detail-textarea"
                      value={dataValue.FamilyHistory}
                      onChange={handleMealInputChange}
                      required
                    />
                    <ErrorMessage
                      name="FamilyHistory"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* Investigations */}
                  <div className="Investigations">
                    <label
                      className="employee-detail-label"
                      title="Permanent Investigations"
                    >
                      Investigations<sup>*</sup>
                    </label>

                    <input
                      id="Investigations"
                      name="Investigations"
                      placeholder="Enter Investigations"
                      className="employee-detail-textarea"
                      value={dataValue.Investigations}
                      onChange={handleMealInputChange}
                      required
                    />
                    <ErrorMessage
                      name="Investigations"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* ProvisionalDiagnosis */}
                  <div className="ProvisionalDiagnosis">
                    <label
                      className="employee-detail-label"
                      title="Permanent ProvisionalDiagnosis"
                    >
                      ProvisionalDiagnosis<sup>*</sup>
                    </label>

                    <input
                      id="ProvisionalDiagnosis"
                      name="ProvisionalDiagnosis"
                      placeholder="Enter ProvisionalDiagnosis"
                      className="employee-detail-textarea"
                      value={dataValue.ProvisionalDiagnosis}
                      onChange={handleMealInputChange}
                      required
                    />
                    <ErrorMessage
                      name="ProvisionalDiagnosis"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* FoodAllergy */}
                  <div className="FoodAllergy">
                    <label
                      className="employee-detail-label"
                      title="Permanent FoodAllergy"
                    >
                      FoodAllergy<sup>*</sup>
                    </label>

                    <input
                      id="FoodAllergy"
                      name="FoodAllergy"
                      placeholder="Enter FoodAllergy"
                      className="employee-detail-textarea"
                      value={dataValue.FoodAllergy}
                      onChange={handleMealInputChange}
                      required
                    />
                    <ErrorMessage
                      name="FoodAllergy"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                <button
                  onClick={() => {
                    handleSubmit();
                    handleSectionChange("DailyHealthHistory");
                  }}
                  className="helath_history_master_button"
                >
                  NEXT
                </button>
              </>
            </Formik>
          </div>
        )}

        {/* <div className="Health2">
        <b>Medical History</b>
        <p>
          All medical conditions that have been reported to or diagnosed by a
          doctor
        </p>
        
        <CustomizedHook />
        <br />
      </div> */}

        {/* <div className="Health3">
        <div className="AllergyHistory">
          <b>Allergy History</b>
          <br />
          <div class="button">
            <button type="button" class="selectable">
              Food
            </button>
            <button type="button" class="selectable">
              Environment
            </button>
            <button type="button" class="selectable">
              Medicine
            </button>
          </div>
          <br />
          <div className="togglebutton">
            <p>
              <input type="checkbox" />
              &emsp;Dust
            </p>
            <p>
              <input type="checkbox" />
              &emsp;Dust mites
            </p>
            <p>
              <input type="checkbox" />
              &emsp;Dust mites
            </p>
            <p>
              <input type="checkbox" />
              &emsp;Pollen
            </p>
            <p>
              <input type="checkbox" />
              &emsp;Cockroaches
            </p>
            <p>
              <input type="checkbox" />
              &emsp;Cockroaches
            </p>
            <p>
              <input type="checkbox" />
              &emsp;Mold
            </p>
            <p>
              <input type="checkbox" />
              &emsp;Dust mites
            </p>
            <p>
              <input type="checkbox" />
              &emsp;Dust mites
            </p>
          </div>
          <br />
        </div>
        <br />
        <div className="HabitHistory">
          <b>Habit History</b>
          <br />
          <p>
            <input type="checkbox" />
            &emsp;&nbsp;Smoke&emsp;&emsp;&emsp;&nbsp;
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  placeholder="1994"
                  sx={{
                    width: "30% !important",
                    height: "50%  !important",
                  }}
                  onChange={onChange}
                  views={["year"]}
                />
              </DemoContainer>
            </LocalizationProvider>
          </p>
          <br />
          <p>
            <input type="checkbox" />
            &nbsp;Drink&emsp;&emsp;&emsp;&emsp;
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  placeholder="1994"
                  sx={{
                    width: "30% !important",
                    height: "50%  !important",
                  }}
                  onChange={onChange}
                  views={["year"]}
                />
              </DemoContainer>
            </LocalizationProvider>
          </p>
          <br />
          <p>
            <input type="checkbox" />
            &emsp;&nbsp;Drunk&emsp;&emsp;&emsp;&ensp;&nbsp;
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  placeholder="1994"
                  sx={{
                    width: "30% !important",
                    height: "50%  !important",
                  }}
                  onChange={onChange}
                  views={["year"]}
                />
              </DemoContainer>
            </LocalizationProvider>
          </p>
          <br />
        </div>
        <div className="health_history_upadated_btn">ADD</div>
      </div> */}
        {activeSection === "DailyHealthHistory" && (
          <div className="Health3">
            <h2>Follow up History</h2>
            <Formik
              initialValues={initialValuesForFormikfollowup}
              validationSchema={validateSchemafollowup}
            >
              <>
                <div className="daily_normal_details">
                  {/* Weight  */}

                  <div className="Weight">
                    <label className="Designation-label" htmlFor="WeightId">
                      Weight: <sup>*</sup>
                    </label>

                    <div style={{ display: "flex", gap: "10px" }}>
                      <Field
                        component={MySelect}
                        options={weightIdList}
                        id="WeightId"
                        name="WeightId"
                        placeholder="Select"
                        className="designation-dropdown"
                        onChange={(value) =>
                          handleSelectionChange("WeightId", value)
                        }
                        value={dataValue.WeightId}
                      ></Field>

                      <input
                        id="Weight"
                        name="Weight"
                        type="number"
                        placeholder="Enter Weight"
                        className="employee-detail-textarea"
                        // value={weight}
                        onChange={validateInput}
                        style={{ display: "flex" }}
                        required
                      />
                    </div>
                    {error && <p className="error">{error}</p>}
                  </div>

                  {/* date  */}
                  <div className="data_row1-1">
                    <p className="data_label">
                      Record Date
                      <span className="custom-text-color">*</span>
                    </p>
                    <DatePicker
                      value={checkInDate2}
                      onChange={(date) => {
                        setCheckInDate2(date);
                      }}
                      format="DD-MM-YYYY"
                      placeholder="Start Date"
                      minDate={new Date()}
                      style={{
                        width: "40%",
                        borderRadius: "8px",
                        borderColor: "#ccc",
                        height: "46px",
                      }}
                      required
                    />
                    {checkInDateError && (
                      <p className="booking_error">{checkInDateError}</p>
                    )}
                  </div>
                </div>

                <div className="daily_body_chechup">
                  {/* PulseRate */}
                  <div className="pulse_rate">
                    <label
                      className="employee-detail-label"
                      placeholder="Enter Pulse Rate"
                    >
                      Pulse Rate (bpm):
                    </label>
                    <input
                      id="PulseRate"
                      type="number"
                      name="PulseRate"
                      placeholder="Enter PulseRate"
                      className="employee-detail-textarea"
                      value={dataValue.PulseRate}
                      onChange={handleMealInputChange}
                      required
                    />
                    <ErrorMessage
                      name="PulseRate"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* bloodPressure */}
                  <div className="bloodPressure">
                    <label
                      className="employee-detail-label"
                      placeholder="Enter Blood Pressure"
                    >
                      Blood Pressure (mmHg):
                    </label>
                    <input
                      id="BloodPressure"
                      type="number"
                      name="BloodPressure"
                      placeholder="Enter BloodPressure"
                      className="employee-detail-textarea"
                      value={dataValue.BloodPressure}
                      onChange={handleMealInputChange}
                      required
                    />
                    <ErrorMessage
                      name="BloodPressure"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* Respiratory_Rate */}
                  <div className="Respiratory_Rate">
                    <label
                      className="employee-detail-label"
                      placeholder="Enter Blood Pressure"
                    >
                      Respiratory Rate (breaths/min):
                    </label>
                    <input
                      id="RespiratoryRate"
                      type="number"
                      name="RespiratoryRate"
                      placeholder="Enter RespiratoryRate"
                      className="employee-detail-textarea"
                      value={dataValue.RespiratoryRate}
                      onChange={handleMealInputChange}
                      required
                    />
                    <ErrorMessage
                      name="RespiratoryRate"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* FBS */}
                  <div className="FBS">
                    <label
                      className="employee-detail-label"
                      placeholder="Enter Blood Pressure"
                    >
                      FBS (mg/dL):
                    </label>
                    <input
                      id="FBS"
                      type="number"
                      name="FBS"
                      placeholder="Enter FBS"
                      className="employee-detail-textarea"
                      value={dataValue.FBS}
                      onChange={handleMealInputChange}
                      required
                    />
                    <ErrorMessage
                      name="FBS"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* PPBS */}
                  <div className="ppbs">
                    <label
                      className="employee-detail-label"
                      placeholder="Enter Blood Pressure"
                    >
                      PPBS (mg/dL):
                    </label>
                    <input
                      id="PPBS"
                      type="number"
                      name="PPBS"
                      placeholder="Enter PPBS"
                      className="employee-detail-textarea"
                      value={dataValue.PPBS}
                      onChange={handleMealInputChange}
                      required
                    />
                    <ErrorMessage
                      name="PPBS"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* RBS */}
                  <div className="RBS">
                    <label
                      className="employee-detail-label"
                      placeholder="Enter Blood Pressure"
                    >
                      RBS (mg/dL):
                    </label>
                    <input
                      id="RBS"
                      type="number"
                      name="RBS"
                      placeholder="Enter RBS"
                      className="employee-detail-textarea"
                      value={dataValue.RBS}
                      onChange={handleMealInputChange}
                      required
                    />
                    <ErrorMessage
                      name="RBS"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>
                <button
                  onClick={handleFollowUpSubmit}
                  className="daily_normal_details_update"
                >
                  SUBMIT
                </button>
                <div>
                  <TableContainer style={{ maxHeight: 400 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow className="table-row-heading">
                          <TableCell style={{ width: "1px" }}>Date</TableCell>
                          {/* <TableCell style={{ width: "120px" }}>Weight</TableCell> */}
                          <TableCell
                            style={{ width: "1px", textAlign: "center" }}
                          >
                            Pulse Rate
                          </TableCell>
                          <TableCell
                            style={{ width: "1px", textAlign: "center" }}
                          >
                            Blood Pressure
                          </TableCell>
                          <TableCell
                            style={{ width: "1px", textAlign: "center" }}
                          >
                            Respiratory Rate
                          </TableCell>
                          <TableCell
                            style={{ width: "1px", textAlign: "center" }}
                          >
                            FBS
                          </TableCell>
                          <TableCell
                            style={{ width: "1px", textAlign: "center" }}
                          >
                            PPBS
                          </TableCell>
                          <TableCell
                            style={{ width: "1px", textAlign: "center" }}
                          >
                            RBS
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getDailyHealthHistoryDataList.length === 0 ? (
                          <TableRow>
                            <TableCell
                              colSpan={8}
                              style={{ textAlign: "center" }}
                            >
                              No Previous Data Entry.
                            </TableCell>
                          </TableRow>
                        ) : (
                          getDailyHealthHistoryDataList.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell style={{ width: "1px" }}>
                                {moment(row.RecordDate).format("DD/MM/YYYY")}
                              </TableCell>
                              {/* <TableCell
                style={{
                  width: "120px",
                  color: row.Weight < 0 ? "red" : "black",
                }}
              >
                {row.Weight}
              </TableCell> */}
                              <TableCell
                                style={{
                                  width: "1px",
                                  color: row.PulseRate < 80 ? "black" : "red",
                                  fontWeight: row.PulseRate > 80 ? "bold" : "",
                                  textAlign: "center",
                                }}
                              >
                                {row.PulseRate}
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "1px",
                                  color:
                                    row.BloodPressure < 90 ? "black" : "red",
                                  fontWeight:
                                    row.BloodPressure > 80 ? "bold" : "",
                                  textAlign: "center",
                                }}
                              >
                                {row.BloodPressure}
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "1px",
                                  color:
                                    row.RespiratoryRate < 100 ? "black" : "red",
                                  fontWeight:
                                    row.RespiratoryRate > 80 ? "bold" : "",
                                  textAlign: "center",
                                }}
                              >
                                {row.RespiratoryRate}
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "1px",
                                  color: row.FBS < 120 ? "black" : "red",
                                  fontWeight: row.FBS > 80 ? "bold" : "",
                                  textAlign: "center",
                                }}
                              >
                                {row.FBS}
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "1px",
                                  color: row.PPBS < 130 ? "black" : "red",
                                  fontWeight: row.PPBS > 80 ? "bold" : "",
                                  textAlign: "center",
                                }}
                              >
                                {row.PPBS}
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "1px",
                                  color: row.RBS < 150 ? "black" : "red",
                                  fontWeight: row.RBS > 80 ? "bold" : "",
                                  textAlign: "center",
                                }}
                              >
                                {row.RBS}
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </>
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
}
