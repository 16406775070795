import React, { useEffect, useState } from "react";
import "./RoomMaster.css";
import * as XLSX from "xlsx"; //to export to XL
import callApi from "../../ApiCall.js";
import trash from "../../assets/icons/trash.svg";
import edit from "../../assets/icons/edit.svg";
//mui imports
import { styled, alpha } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import ExportFile from "../../common/ExportFile/ExportFile.jsx";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
//Icons
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";

//mui pagination
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

//delete confirmation
import DeleteConfirmationDialog from "../../common/DeleteConfirmation/confirmdelete";
import RoomMasterPopup from "./RoomMasterPopup.js";
import AssignRoomPopup from "./AssignRoomPopup.js";
import { toast, Zoom } from "react-toastify";
import Constants from "../../Constants.js";
import SearchBar from "../../common/SearchBar/SearchBar.js";
import AddButton from "../../common/Button/Button.js";
import { Grid } from "@material-ui/core";
// import RoomMasterPopup from "./RoomMasterPopup";

const MenuItemWrapper = styled("li")(() => ({
  padding: "8px 8px",
  boxShadow: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F75555",
    color: "#FFFFFF",
    img: {
      filter:
        "invert(92%) sepia(4%) saturate(8%) hue-rotate(126deg) brightness(138%) contrast(200%)",
    },
  },
  transition: "all 0.3s ease",
}));

const IconWrapper = styled("span")(({ theme }) => ({
  fontSize: "16px",
  marginRight: "8px",
  "& .MuiSvgIcon-root": {
    fontSize: "inherit",
  },
}));

const RoomMaster = (props) => {
  const columns = [
    { field: "RoomId", HeaderName: "Sr No", flex: 1 },
    { field: "RoomTypeName", HeaderName: "Room Type", flex: 1 },
    { field: "NoOfSingleBed", HeaderName: "No Of Single Bed", flex: 1 },
    { field: "NoOfDoubleBed", HeaderName: "No Of Double Bed", flex: 1 },
    props.currentMenu.Edit || props.currentMenu.Delete
      ? {
          field: "actions",
          HeaderName: "Actions",
          flex: 1,
          renderCell: (params) => (
            <div>
              <IconButton
                color="primary"
                aria-label="actions"
                onClick={(event) => handleMenuOpen(event, params.row)}
              >
                <MoreVertIcon style={{ color: "gray" }} />
              </IconButton>
              <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                sx={{
                  "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                    {
                      boxShadow: "none",
                      backgroundColor: "#EEE",
                      borderRadius: "8px",
                    },
                  "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                    paddingTop: "0",
                    paddingBottom: "0",
                  },
                }}
              >
                {props.currentMenu.Edit ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("edit")}>
                    <IconWrapper>
                      <img src={edit} />
                    </IconWrapper>{" "}
                    <span style={{ fontWeight: "400" }}>Edit</span>
                  </MenuItemWrapper>
                ) : null}

                <MenuItemWrapper
                  onClick={() => handleMenuSelect("assign")}
                  sx={{
                    background: "#EEE",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconWrapper>
                    <BedOutlinedIcon />
                  </IconWrapper>
                  <span style={{ fontWeight: "400" }}>Assign</span>
                </MenuItemWrapper>

                {props.currentMenu.Delete ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("delete")}>
                    <IconWrapper>
                      <img src={trash} />
                    </IconWrapper>{" "}
                    <span style={{ fontWeight: "400" }}>Delete</span>
                  </MenuItemWrapper>
                ) : null}
              </Menu>
            </div>
          ),
        }
      : {},
  ];
  const [tempData, setTempData] = useState([]);

  // const paginatedRows = tempData.slice(startIndex, endIndex);

  //sorting
  const initialSortedRows = tempData;
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortedRows, setSortedRows] = useState(initialSortedRows);

  const handleSortClick = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };

  useEffect(() => {
    if (tempData.length > 0) {
      setSortedRows(tempData);
    }
  }, [tempData.length > 0]);
  useEffect(() => {
    if (sortedColumn) {
      const sorted = tempData.slice().sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];
        if (aValue < bValue) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedRows(sorted);
    }
  }, [sortedColumn, sortOrder, tempData]);

  //pagination
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const totalPages = Math.ceil(sortedRows.length / pageSize);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedRows = sortedRows.slice(startIndex, endIndex);
  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };

  //search input field
  const [filtering, setFiltering] = useState("");
  const handleSearchfilter = (event) => {
    setFiltering(event.target.value);
    setPage(1);
  };
  useEffect(() => {
    const searched = tempData.filter((item) => {
      if (!filtering) {
        return true;
      } else {
        return item.RoomTypeName.toLowerCase().includes(
          filtering.toLowerCase()
        );
      }
    });
    setSortedRows(searched);
  }, [filtering, tempData]);

  // get query
  const getRoomType = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getRoomType,
      });
      if (response?.success) {
        const { roomType = [] } = response.data;
        const mappedData = roomType.map((item, index) => ({
          RoomId: index + 1,
          ...item,
        }));
        setTempData(mappedData);
      } else {
        console.error("Error fetching guest activity data:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  useEffect(() => {
    getRoomType();
  }, []);

  //Delete confirmation Dialogue
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedProductDelete, setSelectedProductDelete] = useState(null);

  const handleDeleteRow = (selectedRow) => {
    setSelectedProductDelete(selectedRow);
    setConfirmDelete(true);
  };
  const handleConfirmDelete = async (selectedRow) => {
    const response = await callApi({
      apiURL: Constants.API.deleteRoomType,
      requestBody: {
        roomTypeId: selectedRow?.RoomTypeId,
        updatedDate: selectedRow?.UpdatedDate,
      },
    });
    if (response?.success) {
      const actionMessage = "Deleted Room";
      toast.success(
        <div className="custom_Toast">
          <h5>{actionMessage}</h5>
          Room has been Deleted successfully
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          transition: Zoom,
          hideProgressBar: true,
          style: {
            width: "350px",
            backgroundColor: "#0ABE75",
            color: "#FFFFFF",
          },
          autoClose: 1500,
          closeButton: true,
          onClose: () => {
            getRoomType();
            setConfirmDelete(false);
          },
        }
      );
    }
    getRoomType();
    setConfirmDelete(false);
  };

  //manage action menu
  const [selectedProductForDelete, setSelectedProductForDelete] =
    useState(null);
  const [selectedProductForUpdate, setSelectedProductForUpdate] =
    useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedproperty, setSelectedproperty] = useState(null);

  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedProductForUpdate(row);
    setSelectedProductForDelete(row);
    setIsMenuOpen(true);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };
  const handleRowClick = (data) => {
    setSelectedproperty(data);
  };
  // };
  const handleMenuSelect = (option) => {
    if (option === "edit") {
      handleRoomUpdate(selectedProductForUpdate);
      handleMenuClose();
    } else if (option === "delete") {
      handleDeleteRow(selectedProductForDelete);
    } else if (option === "assign") {
      setShowAssignPopup(true);
      setSelectedRoom(selectedProductForUpdate);
      handleMenuClose();
    }
  };

  //export to excel
  const handleExportClick = (tempData) => {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      const fileName = `Rooms_${formattedDate.replace(/ /g, " ")}.xlsx`;
      const worksheetName = `Rooms`;

      if (tempData?.length > 0) {
        const exportData = tempData.map((row) => ({
          "Sr No.": row.RoomId,
          "Room Type": row.RoomTypeName,
          "No Of Single Bed": row.NoOfSingleBed,
          "No Of Double Bed": row.NoOfDoubleBed,
        }));
        const workSheet = XLSX.utils.json_to_sheet(exportData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, worksheetName);
        XLSX.writeFile(workBook, fileName);
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  //export to PDF
  const handleExportPDF = () => {
    const doc = new jsPDF("p");
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    const fileName = `Rooms${formattedDate.replace(/ /g, "")}.pdf`;
    const tableHeaders = [
      "Sr No",
      "Room Type Name",
      "No Of Single Bed",
      "No Of Double Bed",
    ];

    if (tempData.length > 0) {
      let tableData = tempData.map((row, index) => {
        return [
          index + 1,
          row.RoomTypeName,
          row.NoOfSingleBed,
          row.NoOfDoubleBed,
        ];
      });

      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("Rooms Details", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });

      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
        theme: "grid",
      });

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        formattedDate,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );
      doc.save(fileName);
    } else {
      // showToast("ERROR", "No Data To Export");
    }
  };

  //Open RoomMasterPopup
  const [showRoomPopup, setShowRoomPopup] = useState(false);
  const [isAddMode, setIsAddMode] = useState(true);

  const handleRoomAdd = () => {
    setIsAddMode(true);
    setShowRoomPopup(true);
  };
  const handleRoomUpdate = (selectedRow) => {
    setSelectedProductForUpdate(selectedRow);
    setIsAddMode(false);
    setShowRoomPopup(true);
  };
  const handleRoomPopupClose = () => {
    getRoomType();
    setShowRoomPopup(false);
    setIsAddMode(true);
    setSelectedProductForUpdate(null);
  };

  const handleAssignPopupClose = () => {
    getRoomType();
    setShowAssignPopup(false);
    setSelectedRoom(null);
    setSelectedProductForUpdate(null);
  };

  return (
    <>
      {showRoomPopup && (
        <RoomMasterPopup
          isAddMode={isAddMode}
          onClose={handleRoomPopupClose}
          selectedRoomActivity={selectedProductForUpdate}
        />
      )}
      {showAssignPopup && (
        <AssignRoomPopup
          onClose={handleAssignPopupClose}
          selectedRoom={selectedRoom}
        />
      )}
      <div className="room-master-container">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <SearchBar value={filtering} onChange={handleSearchfilter} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ExportFile
              handleExportClick={handleExportClick}
              filteredRows={tempData}
              handleExportPDF={handleExportPDF}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            {props?.currentMenu?.Add ? (
              <>
                <AddButton buttonText={"Add Room"} onClick={handleRoomAdd} />
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>

        <div className="roomMaster-table-container">
          <div className="roomMaster-table">
            <DeleteConfirmationDialog
              open={confirmDelete}
              handleClose={() => {
                setConfirmDelete(false);
                handleMenuClose();
              }}
              handleConfirm={() => {
                handleMenuClose();
                handleConfirmDelete(selectedProductDelete);
              }}
              product={"Room"}
            />
            <div className="room-table-container">
              <div className="room-table">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell
                            key={i}
                            className="tableHeaderCell"
                            onClick={() => handleSortClick(column.field)}
                          >
                            {column.HeaderName}
                            {/* Show arrows only if it's not the actions column */}
                            {column.HeaderName !== "actions" && (
                              <span>
                                {sortedColumn === column.field &&
                                  sortOrder === "asc" && (
                                    <i className={`fa-solid fa-arrow-up `} />
                                  )}
                                {sortedColumn === column.field &&
                                  sortOrder === "desc" && (
                                    <i className={`fa-solid fa-arrow-down `} />
                                  )}
                              </span>
                            )}

                            {/* {column.HeaderName!='Actions'?renderArrowIcon(column.field):''} */}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedRows.map((row, i) => (
                        <TableRow key={i}>
                          {columns.map((column, i) => (
                            <TableCell
                              className="tableBodyCell"
                              key={i}
                              onClick={() => {
                                if (column?.field !== "actions") {
                                  handleRowClick(row);
                                } else {
                                  column.renderCell({ row });
                                }
                              }}
                            >
                              {column.renderCell
                                ? column.renderCell({ row })
                                : row[column.field]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={totalPages}
                  page={page}
                  hideNextButton
                  hidePrevButton
                  onChange={(event, value) => setPage(value)}
                  siblingCount={0}
                  boundaryCount={2}
                  //   onChange={(event, value) => handlePageChange(value)}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      page={formattedPageNumber(item.page)}
                      className={item.selected ? "pagination-selected" : ""}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RoomMaster;
