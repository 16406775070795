import React from "react";
import "../../assets/colors/colors.css";
import "react-datepicker/dist/react-datepicker.css";
import "./CSS/reservation.css";
import { useEffect, useState } from "react";

import callApi from "../../ApiCall";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteConfirmationDialog from "../../common/DeleteConfirmation/confirmdelete";

import Constants from "../../Constants";
import Booking from "./Booking";
import { getDisplayDate } from "../../components/DateTimeUtils";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Pagination, PaginationItem } from "@mui/lab";
import trash from "../../assets/icons/trash.svg";
import edit from "../../assets/icons/edit.svg";
import CheckIn from "./CheckIn";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import SearchBar from "../../common/SearchBar/SearchBar";
import AddButton from "../../common/Button/Button";
import CheckOut from "./CheckOut";

const MenuItemWrapper = styled("li")(() => ({
  padding: "8px 8px",
  boxShadow: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F75555",
    color: "#FFFFFF",
    img: {
      filter:
        "invert(92%) sepia(4%) saturate(8%) hue-rotate(126deg) brightness(138%) contrast(200%)",
    },
  },
  transition: "all 0.3s ease",
}));

const IconWrapper = styled("span")(() => ({
  fontSize: "16px",
  marginRight: "8px",
  "& .MuiSvgIcon-root": {
    fontSize: "inherit",
  },
}));

export default function Reservation(props) {
  const [filter, setFilter] = useState("");
  const [reservation, setReservation] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedProductForDelete, setSelectedProductForDelete] =
    useState(null);
  const [selectedProductForUpdate, setSelectedProductForUpdate] =
    useState(null);
  const [showReservationPopup, setShowReservationPopup] = useState(false);
  const [isAddMode, setIsAddMode] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRowForActions, setSelectedRowForActions] =
    React.useState(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isSearchFocused, setIsSearchFocused] = React.useState(false);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const initialSortedRows = reservation;
  const [sortedRows, setSortedRows] = useState(initialSortedRows);
  const [page, setPage] = useState(1);
  const [isCheckOutPopUp, setIsCheckOutPopUp] = useState(false);
  const pageSize = 10;
  const totalPages = Math.ceil(sortedRows.length / pageSize);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };

  const columns = [
    { field: "id", headerName: "Rooms", flex: 1 },
    {
      field: "FullName",
      headerName: <span>Guest</span>,
      flex: 1,
    },
    {
      field: "CheckInDateValue",
      headerName: <span>Check in</span>,
      flex: 1,
    },
    {
      field: "CheckOutDateValue",
      headerName: <span>Check out</span>,
      flex: 1,
    },
    {
      field: "TotalMember",
      headerName: <span>Total Member </span>,
      flex: 1,
    },
    {
      field: "BookingStatus",
      headerName: <span>Status</span>,
      flex: 1,
      renderCell: (params) => {
        const bookingStatus = params.row.BookingStatus;
        let statusText = "";
        let statusStyle = {}; 

       
        if (bookingStatus == 64) {
          statusText = "Checked-in"; 
          statusStyle = { color: "var(--alert-status-success)" }; 
        } else if (bookingStatus == 65) {
          
          statusText = "Checked-out"; 
          statusStyle = { color: "var(--alert-status-error)" }; 
        } else {
          statusText = "Other Status"; 
          statusStyle = { color: "gray" }; 
        }

      
        return <span style={statusStyle}>{statusText}</span>;
      },
    },

    props.currentMenu.Edit || props.currentMenu.Delete
      ? {
          field: "actions",
          headerName: "Actions",
          flex: 1,
          renderCell: (params, index) => {
            return (
              <div>
                <IconButton
                  color="primary"
                  aria-label="actions"
                  onClick={(event) => handleMenuOpen(event, params.row)}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <MoreVertIcon style={{ color: "gray" }} />
                </IconButton>
                <Menu
                  id="actions-menu"
                  anchorEl={anchorEl}
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                  sx={{
                    "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                      {
                        boxShadow: "none",
                        backgroundColor: "#EEE",
                        borderRadius: "8px",
                      },
                    "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                      paddingTop: "0",
                      paddingBottom: "0",
                    },
                  }}
                >
                  {props.currentMenu.Edit ? (
                    <MenuItemWrapper
                      onClick={() => {
                        handleMenuSelect("edit");
                      }}
                    >
                      <IconWrapper>
                        {/* <EditIcon /> */}
                        <img src={edit} />
                      </IconWrapper>
                      <span style={{ fontWeight: "400" }}>Edit</span>
                    </MenuItemWrapper>
                  ) : null}

                  {props.currentMenu.Delete ? (
                    <MenuItemWrapper onClick={() => handleMenuSelect("delete")}>
                      <IconWrapper>
                        {/* <IoMdTrash /> */}
                        <img src={trash} />
                      </IconWrapper>{" "}
                      <span style={{ fontWeight: "400" }}>Delete</span>
                    </MenuItemWrapper>
                  ) : null}

                  {props.currentMenu.Edit ? (
                    <MenuItemWrapper
                      onClick={() => {
                        handleMenuSelect("checkIn");
                      }}
                    >
                      <IconWrapper>
                        <BedOutlinedIcon />
                      </IconWrapper>
                      <span style={{ fontWeight: "400" }}>Check In</span>
                    </MenuItemWrapper>
                  ) : null}
                </Menu>
              </div>
            );
          },
        }
      : {},
  ];

  const handleDeleteClick = (selectedRow) => {
    setSelectedProductForDelete(selectedRow);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async (selectedRow) => {
    try {
      const params = {
        bookingMasterId: selectedRow.BookingMasterId,
        updatedDate: selectedRow.UpdatedDate,
      };

      const response = await callApi({
        apiURL: Constants.API.deleteBookingData,
        requestBody: params,
      });

      if (response?.success) {
        getBookingMaster();
      } else {
        console.error("Error deleting reservation:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setShowDeleteConfirmation(false);
    }
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowForActions(row);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRowForActions(null);
    setIsMenuOpen(false);
  };
  const handleMenuSelect = (option) => {
    if (option === "edit") {
      handleUpdateClick(selectedRowForActions);
    } else if (option === "delete") {
      handleDeleteClick(selectedRowForActions);
    } else if (option === "checkIn") {
      handleCheckInClick(selectedRowForActions);
    }

    handleMenuClose();
  };

  const handleUpdateClick = (selectedRow) => {
    setSelectedProductForUpdate(selectedRow);
    setIsAddMode(false);
    setShowReservationPopup(true);
  };

  const [checkInPopUp, setCheckInPopUp] = useState(false);
  const handleCheckInClick = (selectedRow) => {
    setSelectedProductForUpdate(selectedRow);
    setCheckInPopUp(true);
  };

  const handleAddProductClick = () => {
    setIsAddMode(true);
    setShowReservationPopup(true);
  };

  const handleCheckoutClick = () => {
    setIsCheckOutPopUp(true)
  }

  const handleReservationPopupClose = () => {
    setShowReservationPopup(false);
    setCheckInPopUp(false);
    setIsCheckOutPopUp(false);
    setIsAddMode(true);
    setSelectedProductForUpdate(null);
    getBookingMaster();
  };

  const getBookingMaster = async () => {
    const response = await callApi({
      apiURL: Constants.API.getBookingData,
    });
    if (response?.success) {
      const { BookingMasterData = [] } = response.data;

      const mappedData = BookingMasterData?.map((item, index) => {
        const guest = JSON.parse(item.GuestList).filter((x) => {
          return item.PrimaryGuestId == x.GuestMasterId;
        })[0];
        const fullName = guest.FirstName + " " + guest.LastName;
        return {
          ...item,
          id: index + 1,
          actions: null,
          delete: null,
          FullName: fullName,
          CheckInDateValue: getDisplayDate(item.CheckInDate),
          CheckOutDateValue: getDisplayDate(item.CheckOutDate),
        };
      });

      setReservation(mappedData);
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    getBookingMaster();
  }, []);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(1);
  };

  const handleSortClick = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };
  const renderArrowIcon = (column) => {
    if (sortedColumn === column) {
      return (
        <span className="arrow-icon">
          {sortOrder === "asc" ? (
            <i className="fa-solid fa-arrow-up" />
          ) : (
            <i className="fa-solid fa-arrow-down" />
          )}
        </span>
      );
    }
    return null;
  };

  useEffect(() => {
    if (reservation?.length > 0) {
      setSortedRows(reservation);
    }
  }, [reservation.length > 0]);

  useEffect(() => {
    if (sortedColumn) {
      const sorted = reservation?.slice().sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];
        if (aValue < bValue) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedRows(sorted);
    }
  }, [sortedColumn, sortOrder, reservation]);

  const paginatedRows = sortedRows.slice(startIndex, endIndex);

  useEffect(() => {
    const searched = reservation.filter((item) => {
      if (!filter) {
        return true;
      } else {
        return (
          item.FullName !== null &&
          item.FullName.toLowerCase().includes(filter.toLowerCase())
        );
      }
    });
    setSortedRows(searched);
  }, [filter, reservation]);

  return (
    <>
      {showReservationPopup && (
        <Booking
          onClose={handleReservationPopupClose}
          isAddMode={isAddMode}
          selectedReservation={selectedProductForUpdate}
        />
      )}

      {checkInPopUp && (
        <CheckIn
          onClose={handleReservationPopupClose}
          selectedBooking={selectedProductForUpdate}
        />
      )}
      {
isCheckOutPopUp && (
  <CheckOut  onClose={handleReservationPopupClose}/>

)
        
      }
      <div className="reservation_MainPae">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <SearchBar value={filter} onChange={handleFilterChange} />
          </Grid>
       
          <Grid item xs={12} sm={3} md={3}>
            {" "}
            {props?.currentMenu?.Add ? (
              <>
                <AddButton
                  buttonText={"Check Out"}
                  onClick={handleCheckoutClick}
                />
              </>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            {" "}
            {props?.currentMenu?.Add ? (
              <>
                <AddButton
                  buttonText={"Add Booking"}
                  onClick={handleAddProductClick}
                />
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>

        <div className="ReservationTable">
          <div className="Table">
            <DeleteConfirmationDialog
              open={showDeleteConfirmation}
              handleClose={() => setShowDeleteConfirmation(false)}
              handleConfirm={() =>
                handleConfirmDelete(selectedProductForDelete)
              }
              product={selectedProductForDelete?.FullName + " booking details"}
            />
            <div className="Property_Location_DataGrid">
              <div className="propActivity-table">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns?.map((column, i) => (
                          <TableCell
                            key={i}
                            className="tableHeaderCell"
                            onClick={() => handleSortClick(column.field)}
                          >
                            {column.headerName}
                            {renderArrowIcon(column.field)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedRows?.map((row, i) => (
                        <TableRow key={i}>
                          {columns.map((column, ind) => (
                            <TableCell key={ind} className="tableBodyCell">
                              {column.renderCell
                                ? column.renderCell({ row })
                                : row[column.field]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={totalPages}
                  page={page}
                  hideNextButton
                  hidePrevButton
                  onChange={(event, value) => setPage(value)}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      page={formattedPageNumber(item.page)}
                      className={item.selected ? "pagination-selected" : ""}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
