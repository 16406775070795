import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AddButton from "../../common/Button/Button";
import { useState } from "react";
import Payment from "./Payment";

export default function Billing(props) {
  const [paymentPopUp, setPaymentPopUp] = useState(false);

  const handlePaymentClick = () => {
    setPaymentPopUp(true);
  };

  const handlePaymentPopupClose = () =>{
    setPaymentPopUp(false)
  }
  return (
    <div>
      <Grid item xs={12} sm={3} md={3}>
        {" "}
        {props?.currentMenu?.Add ? (
          <>
            <AddButton buttonText={"Payment"} onClick={handlePaymentClick} />
          </>
        ) : (
          <></>
        )}
      </Grid>
      {paymentPopUp && (
        <Payment onClose={handlePaymentPopupClose}/>
      )}
    </div>
  );
}
