import React, { useEffect, useState } from "react";
import "./DailyScheduleSummary.css";
import * as XLSX from "xlsx";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ExportFile from "../../common/ExportFile/ExportFile";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import callApi from "../../ApiCall";
import Constants from "../../Constants";
import SearchBar from "../../common/SearchBar/SearchBar";
import DateSelect from "../../common/DatePicker/Datepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid } from "@material-ui/core";

const GuestShedule = () => {
  //for tooltip
  const renderTimeCell = (slotField) => (params) => {
    const activityName = params.row[slotField];
    const activityData = params.row[`${slotField}_data`];

    if (!activityData) return null;

    const { ActivityStartTime, AssignedEmployees } = activityData;

    const formatTime = (timeString) => {
      const [hours, minutes] = timeString.split(":").map(Number);
      const period = hours >= 12 ? "PM" : "AM";
      const hourIn12 = hours % 12 || 12;
      return `${hourIn12}:${minutes.toString().padStart(2, "0")} ${period}`;
    };

    const employeeNames = AssignedEmployees
      ? AssignedEmployees.map((emp) => emp.AssignedEmployeeName).join(", ")
      : "unassigned";
    const formattedStartTime = formatTime(ActivityStartTime);
    const formattedEndTime = formatTime(activityData.ActivityEndTime);

    const tooltipText = `Activity Time: ${formattedStartTime}-${formattedEndTime}
    Assigned Employees: ${employeeNames}`;

    return (
      <Tooltip
        title={<span style={{ whiteSpace: "pre-line" }}>{tooltipText}</span>}
        arrow
      >
        <div style={{ cursor: "pointer" }}>{activityName}</div>
      </Tooltip>
    );
  };

  const timeSlots = [
    "12:00 AM",
    "1:00 AM",
    "2:00 AM",
    "3:00 AM",
    "4:00 AM",
    "5:00 AM",
    "6:00 AM",
    "7:00 AM",
    "8:00 AM",
    "9:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
    "5:00 PM",
    "6:00 PM",
    "7:00 PM",
    "8:00 PM",
    "9:00 PM",
    "10:00 PM ",
    "11:00 PM ",
  ];

  const [visibleStart, setVisibleStart] = useState(0);
  const [visibleEnd, setVisibleEnd] = useState(5);

  const handlePrevious = () => {
    if (visibleStart > 0) {
      setVisibleStart(visibleStart - 1);
      setVisibleEnd(visibleEnd - 1);
    }
  };

  const handleNext = () => {
    if (visibleEnd < timeSlots.length) {
      setVisibleStart(visibleStart + 1);
      setVisibleEnd(visibleEnd + 1);
    }
  };

  const visibleColumns = timeSlots
    .slice(visibleStart, visibleEnd)
    .map((slot, index) => ({
      field: `timeSlot_${visibleStart + index}`,
      headerName: slot,
      flex: 1,
      renderCell: renderTimeCell(`timeSlot_${visibleStart + index}`),
    }));

  const columns = [
    { field: "id", headerName: "Sr No", flex: 1 },
    { field: "Guest", headerName: "Guest", flex: 1 },
    ...visibleColumns,
  ];

  const getTimeSlotIndex = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    let isPM = hours >= 12;
    let hourIn12Format = hours % 12 === 0 ? 12 : hours % 12;
    let period = isPM ? "PM" : "AM";

    if (minutes >= 30) {
      hourIn12Format += 1;
      if (hourIn12Format === 12) isPM = !isPM;
    }

    const timeLabel = `${hourIn12Format}:00 ${isPM ? "PM" : "AM"}`;
    return timeSlots.findIndex((slot) => slot.startsWith(timeLabel));
  };

  const [page, setPage] = useState(1);
  const [guestScheduleData, setGuestScheduleData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  // get api
  const getDatewiseGuestSchedule = async (date) => {
    if (!date) {
      console.error("No date selected for fetching data");
      return;
    }
    try {
      const formattedDate = new Date(date).toISOString().split("T")[0];
      const response = await callApi({
        apiURL: Constants.API.getDatewiseGuestSchedule,
        requestBody: { selectedDate: formattedDate },
      });

      if (response?.success) {
        const { dateWiseGuestScheduleList = [] } = response.data;
        const mappedData = dateWiseGuestScheduleList.map((item, index) => {
          const activityData = JSON.parse(item.Activities);
          const timeSlotActivities = {};

          activityData.forEach((activity) => {
            const startSlotIndex = getTimeSlotIndex(activity.ActivityStartTime);
            const endSlotIndex = getTimeSlotIndex(activity.ActivityEndTime);

            if (startSlotIndex >= 0) {
              timeSlotActivities[`timeSlot_${startSlotIndex}`] =
                activity.ActivityName;
              timeSlotActivities[`timeSlot_${startSlotIndex}_data`] = activity;
            }
          });

          return {
            id: index + 1,
            Guest: item.Guest,
            ...timeSlotActivities,
          };
        });

        console.log(mappedData);
        setGuestScheduleData(mappedData);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  useEffect(() => {
    getDatewiseGuestSchedule(selectedDate);
  }, [selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  // Export PDF and Excel
  const handleExportClick = (guestScheduleData) => {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      const fileName = `DailySchedules_${formattedDate.replace(
        / /g,
        " "
      )}.xlsx`;
      const worksheetName = `DailySchedules`;

      if (guestScheduleData?.length > 0) {
        const exportData = guestScheduleData.map((row) => {
          const timeSlotData = {};
          timeSlots.forEach((range, index) => {
            timeSlotData[range] = row[`timeSlot_${index}`] || "";
          });

          return {
            "Sr No": row.id,
            Guest: row.Guest,
            ...timeSlotData,
          };
        });

        // Create the worksheet
        const workSheet = XLSX.utils.json_to_sheet(exportData);

        const colWidth = [];
        exportData.forEach((row) => {
          Object.keys(row).forEach((key, index) => {
            const cellValue = row[key] ? row[key].toString() : "";
            const length = cellValue.length;

            if (!colWidth[index]) {
              colWidth[index] = { wch: Math.max(10, length) };
            } else if (length > colWidth[index].wch) {
              colWidth[index] = { wch: length };
            }
          });
        });

        workSheet["!cols"] = colWidth;

        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, worksheetName);
        XLSX.writeFile(workBook, fileName);
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF("p");
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    const fileName = `DailySchedule_${formattedDate.replace(/ /g, "")}.pdf`;

    const tableHeaders = ["Sr No", "Guest", ...timeSlots];

    if (guestScheduleData.length > 0) {
      let tableData = guestScheduleData.map((row) => {
        const rowData = [row.id, row.Guest];
        timeSlots.forEach((_, index) => {
          rowData.push(row[`timeSlot_${index}`] || "");
        });
        return rowData;
      });

      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("Daily Schedule", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });

      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
        theme: "grid",
      });

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        formattedDate,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );

      doc.save(fileName);
    } else {
      alert("No data to export");
    }
  };

  // pagination sorting and searching
  const initialSortedRows = guestScheduleData;
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortedRows, setSortedRows] = useState(initialSortedRows);
  const pageSize = 10;
  const totalPages = Math.ceil(sortedRows.length / pageSize);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };

  const handleSortClick = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };

  const renderArrowIcon = (column) => {
    if (sortedColumn === column) {
      return (
        <span className="arrow-icon">
          {sortOrder === "asc" ? (
            <i className="fa-solid fa-arrow-up" />
          ) : (
            <i className="fa-solid fa-arrow-down" />
          )}
        </span>
      );
    }
  };

  useEffect(() => {
    if (guestScheduleData.length > 0) {
      setSortedRows(guestScheduleData);
    }
  }, [guestScheduleData.length > 0]);

  useEffect(() => {
    if (sortedColumn) {
      const sorted = guestScheduleData.slice().sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];
        if (aValue < bValue) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedRows(sorted);
    }
  }, [sortedColumn, sortOrder, guestScheduleData]);

  // SEARCH
  const [filtering, setFiltering] = useState("");
  const handleSearchfilter = (event) => {
    setFiltering(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    const searched = guestScheduleData.filter((item) => {
      if (!filtering) {
        return true;
      } else {
        return (
          (item.Guest !== null &&
            item.Guest.toLowerCase().includes(filtering.toLowerCase())) ||
          (item.ActivityName !== null &&
            item.Guest.toLowerCase().includes(filtering.toLowerCase()))
        );
      }
    });
    setSortedRows(searched);
  }, [filtering, guestScheduleData]);

  const paginatedRows = sortedRows.slice(startIndex, endIndex);

  return (
    <>
      <div className="propActivity_MainPae">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <SearchBar value={filtering} onChange={handleSearchfilter} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="export_dailyschedule">
              <div className="date-picker-container_dailyschedule">
                <div className="date-input-wrapper">
                  <CalendarMonthIcon className="calendar-icon" />
                  <DateSelect
                    startDate={selectedDate}
                    onChange={handleDateChange}
                    format={"DD-MM-YYYY"}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <ExportFile
              handleExportClick={handleExportClick}
              filteredRows={guestScheduleData}
              handleExportPDF={handleExportPDF}
            />
          </Grid>
        </Grid>

        <div className="propActivityTable">
          <div className="Table">
            <div className="dailyschedule_data_grid">
              <div className="propActivity-table">
                <TableContainer style={{ overflowX: "auto", width: "100%" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="fixed-column">Sr No</TableCell>
                        <TableCell className="fixed-second-column">
                          Guest
                        </TableCell>

                        <TableCell>
                          <ArrowBackIosIcon
                            className="navigation-arrow previous"
                            onClick={handlePrevious}
                            style={{
                              cursor:
                                visibleStart === 0 ? "not-allowed" : "pointer",
                            }}
                            disabled={visibleStart === 0}
                          />
                        </TableCell>

                        {visibleColumns.map((column, i) => (
                          <TableCell key={i}>{column.headerName}</TableCell>
                        ))}

                        <TableCell>
                          <ArrowForwardIosIcon
                            className="navigation-arrow next"
                            onClick={handleNext}
                            style={{
                              cursor:
                                visibleEnd >= timeSlots.length
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                            disabled={visibleEnd >= timeSlots.length}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {paginatedRows.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          <TableCell className={`tableBodyCell fixed-column`}>
                            {row.id}
                          </TableCell>
                          <TableCell
                            className={`tableBodyCell fixed-second-column`}
                          >
                            {row.Guest}
                          </TableCell>

                          <TableCell />

                          {visibleColumns.map((column, colIndex) => (
                            <TableCell
                              key={colIndex}
                              className={`tableBodyCell`}
                              style={{
                                backgroundColor:
                                  colIndex < 2
                                    ? rowIndex % 2 === 0
                                      ? "white"
                                      : "var(--Greyscale-50)"
                                    : "inherit",
                              }}
                            >
                              {column.renderCell
                                ? column.renderCell({ row })
                                : row[column.field]}
                            </TableCell>
                          ))}

                          <TableCell />
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Pagination
                  count={totalPages}
                  page={page}
                  hideNextButton
                  hidePrevButton
                  onChange={(event, value) => setPage(value)}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      page={formattedPageNumber(item.page)}
                      className={item.selected ? "pagination-selected" : ""}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestShedule;
