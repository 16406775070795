import React, { useEffect, useState } from "react";
import callApi from "../../ApiCall";
import Constants from "../../Constants";
import "./dashboardScreen.css";

const GenderChart = () => {
  const [genderCount, setGenderCount] = useState({ male: 0, female: 0 });

  useEffect(() => {
    const fetchGenderCount = async () => {
      try {
        const response = await callApi({
          apiURL: Constants.API.getGenderCount,
        });
        if (response?.success) {
          const genderData = response.data.genderCount;
          const maleCount =
            genderData.find((item) => item.GenderName === "Male")?.Count || 0;
          const femaleCount =
            genderData.find((item) => item.GenderName === "Female")?.Count || 0;
          setGenderCount({ male: maleCount, female: femaleCount });
        } else {
          console.error("Failed to fetch gender count:", response?.error);
        }
      } catch (error) {
        console.error("API call error:", error);
      }
    };

    fetchGenderCount();
  }, []);

  const totalCount = genderCount.male + genderCount.female;
  const malePercentage = ((genderCount.male / totalCount) * 100).toFixed(2);
  const femalePercentage = ((genderCount.female / totalCount) * 100).toFixed(2);

  return (
    <div className="dashboard-card-container0">
      <h2 className="dashboard-title0">Visitors</h2>
      <div className="progress-container">
        <svg viewBox="0 0 36 18" className="semi-circle-chart">
          {/* Male Segment */}
          <path
            d="M2 18 A16 16 0 0 1 34 18"
            fill="none"
            stroke="#b4ba3d"
            strokeWidth="2"
            strokeDasharray={`${malePercentage}, 100`}
          />
          {/* Female Segment */}
          <path
            d="M2 18 A16 16 0 0 1 34 18"
            fill="none"
            stroke="#6C7D02"
            strokeWidth="2"
            strokeDasharray={`${femalePercentage}, ${100 - malePercentage}`}
          />
        </svg>
        <div className="tooltip-container">
          <div className="tooltip">
            <span className="dot male-dot"></span> Male: &nbsp;{" "}
            {genderCount.male}
            {/* ({malePercentage}%) */}
          </div>
          <div className="tooltip">
            <span className="dot female-dot"></span> Female:{" "}
            {genderCount.female}
            {/* ({femalePercentage}%) */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenderChart;
