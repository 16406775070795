import React, { useEffect, useState } from "react";
import "./Blog.css";
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import demo from "../../../assets/images/H-Second.png";
import Constants from "../../../Constants";
import callApi from "../../../ApiCall";
import parse from "html-react-parser";
import moment from "moment/moment";
import { Link } from "react-router-dom";

const CategorySelector = ({
  categories,
  selectedCategory,
  onCategoryChange,
}) => {
  return (
    <div className="categorySelector">
      <h2>Select a Category:</h2>
      <ul>
        <li
          className={selectedCategory === null ? "selected" : ""}
          onClick={() => onCategoryChange(null)}
        >
          All Categories
        </li>
        {categories.map((category) => (
          <li
            key={category.category_id}
            className={
              selectedCategory === category.category_id ? "selected" : ""
            }
            onClick={() => onCategoryChange(category.category_id)}
          >
            {category.category_slug}
          </li>
        ))}
      </ul>
    </div>
  );
};

const RecentBlogs = ({ blogs }) => {
  const recentBlogs = blogs.slice(-5);

  return (
    <div className="recent">
      <h2 className="recentheading">Recent Posts</h2>
      {recentBlogs.map((blog) => (
        <div className="mainrecent" key={blog.id}>
          <Link to={`/blog/${blog.title_slug}`}>
            <h3 className="title">{blog.title}</h3>
          </Link>
          <p className="date"> {moment(blog.blog_date).format("DD-MM-YYYY")}</p>
        </div>
      ))}
    </div>
  );
};

const Blog = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [blogCategoryList, setBlogCategoryList] = useState([]);
  const [blogsData, setBlogsData] = useState([]);
  const blogsPerPage = 6;

  const getBlogCategory = async () => {
    const response = await callApi({
      apiURL: Constants.API.getBlogCategory,
    });

    if (response?.success) {
      const categoryData = response.data.blogCategoryList.map((category) => ({
        category_id: category.category_id,
        category_slug: category.category_slug,
      }));
      setBlogCategoryList(categoryData);
    }
  };

  const getBlogs = async () => {
    const response = await callApi({
      apiURL: Constants.API.getBlogs,
    });

    if (response?.success) {
      setBlogsData(response.data.blogsList);
    }
  };

  useEffect(() => {
    getBlogCategory();
    getBlogs();
  }, []);

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    setCurrentPage(1);
  };

  let filteredBlogs = blogsData;

  if (selectedCategory !== null) {
    filteredBlogs = blogsData.filter(
      (blog) => blog.category_id === selectedCategory
    );
  }

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const mediapath =
    "https://reveation-blog.s3.ap-south-1.amazonaws.com/Images/";

  return (
    <div className="mt-20">
      <h1 className="text-5xl pt-5 text-center font-bold">Blog</h1>
      <div className="main">
        <section className="rightcat">
          <CategorySelector
            categories={blogCategoryList}
            selectedCategory={selectedCategory}
            onCategoryChange={handleCategoryChange}
          />

          <RecentBlogs blogs={blogsData} />
        </section>
        <section className="blogs">
          {currentBlogs.map((blog) => (
            <div key={blog.blog_id} className="container">
              <Link to={`/blog/${blog.title_slug}`}>
              <section className="section1">
                <img src={mediapath + blog.image} title="" alt="" />
              </section>
              <section className="section2">
                
                <h1 className="h1">{blog.title.length > 30 ? `${blog.title.substring(0, 50)}` : blog.title}</h1>
               
                {/* <h6 className="text-gray-500">
                  {moment(blog.blog_date).format("DD-MM-YYYY")}
                </h6> */}
                <p className="para">
                  {parse(parse(blog.description.substring(0, 100)))}
                  {blog.description.length > 100 && ""}
                </p>
              </section>
              </Link>
            </div>
          ))}
        </section>
      </div>
      <div className="pagination">
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          <BsArrowLeft />
        </button>
        {Array(Math.ceil(filteredBlogs.length / blogsPerPage))
          .fill()
          .map((_, index) => (
            <button
              key={index}
              className={currentPage === index + 1 ? "active" : ""}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        <button
          disabled={indexOfLastBlog >= filteredBlogs.length}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <BsArrowRight />
        </button>
      </div>
    </div>
  );
};

export default Blog;
