import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import trash from "../../assets/icons/trash.svg";
import edit from "../../assets/icons/edit.svg";
import MySelect from "../../common/SingleSelect";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import callApi from "../../ApiCall";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./RoomMaster.css";
import Constants from "../../Constants";

const AssignRoomPopup = ({ onClose, selectedRoom }) => {
  const RoomDetails = selectedRoom?.RoomDetails
    ? JSON.parse(selectedRoom?.RoomDetails)
    : "";
  const [getAllRoomData, setAllGetRoomData] = useState(
    selectedRoom ? RoomDetails : []
  );

  const [isEditing, setIsEditing] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState("");
  //showing popup
  const [showPopup, setShowPopup] = useState(true);
  const [selectedBed, setSelectedBed] = useState(null);

  // toogle group for room status
  const [status, setStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(
    status === 34
      ? "Available"
      : status === 35
      ? "Occupaid"
      : status === 36
      ? "Under Maintenance"
      : "Available"
  );
  const [selectedData, setSelectedData] = useState([]);

 
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  });


  //formik values
  const initialValuesForFormiks = {
    roomTypeId: selectedRoom?.RoomTypeId ? selectedRoom?.RoomTypeId : "",
    RoomNo: selectedData?.RoomNo ? selectedData?.RoomNo : "",
    RoomKeyNo: selectedData?.RoomKeyNo ? selectedData?.RoomKeyNo : "",
    RoomStatus:
      selectedStatus == "Available"
        ? 34
        : selectedStatus == "Occupaid"
        ? 35
        : selectedStatus == "Under Maintenance"
        ? 36
        : 34,
    noOfBed: 0,
  };
  const isDuplicate = (value, allRoomData, key, editingIndex) => {
    if (!Array.isArray(allRoomData)) {
      return false; // Return false if allRoomData is not an array
    }

    return allRoomData.some((room, index) => {
      if (index === editingIndex) return false;
      return room[key] === value;
    });
  };

  const validationSchema = Yup.object({
    // roomTypeId: Yup.string().required("Please fill Room Type"),
    RoomNo: Yup.string()
      .required("Room Number is required")
      .test("unique-room-no", "Room number already exists", function (value) {
        const { path, parent, options } = this;
        return !isDuplicate(value, getAllRoomData, "RoomNo", options.path);
      }),
    RoomKeyNo: Yup.string()
      .required("Room Key is required")
      .test("unique-room-no", "Room key already exists", function (value) {
        const { path, parent, options } = this;
        return !isDuplicate(value, getAllRoomData, "RoomKeyNo", options.path);
      }),
    RoomStatus: Yup.string().required("Please Select Room Status "),
  });

  const handleStatusChange = (event, newStatus) => {
    setSelectedStatus(newStatus);  
  };
  // add new data
  const saveRoomData = (values, resetForm, validateForm, setFieldTouched) => {
    values.RoomStatus =
      selectedStatus === "Available"
        ? 34
        : selectedStatus === "Occupaid"
        ? 35
        : selectedStatus === "Under Maintenance"
        ? 36
        : 34;

    validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        const newRoomData = {
          ...values,
          isNew: true,
          Bed: selectedBed ? selectedBed.value : "",  
        };
        setAllGetRoomData([...getAllRoomData, newRoomData]);
        resetForm();
      } else {
        Object.keys(errors).forEach((fieldName) => {
          setFieldTouched(fieldName, true, false);
        });
      }
    });
  };
  

  // get data to edit
  const editGuest = (index, item, setValues) => {
    setStatus(item?.RoomStatus);
    setSelectedStatus(
      item?.RoomStatus === 34
        ? "Available"
        : item?.RoomStatus === 35
        ? "Occupaid"
        : item?.RoomStatus === 36
        ? "Under Maintenance"
        : "Available"
    );

    setSelectedData(item?.RoomId);
    setIsEditing(true);
    const selectedData = {
      roomTypeId: item?.roomTypeId,
      RoomNo: item?.RoomNo,
      RoomKeyNo: item?.RoomKeyNo,
      RoomStatus: item?.RoomStatus,
      noOfBed: item?.NoOfBed,
    }; 
    setSelectedIndex(index);
    setValues(selectedData);
    setSelectedData(item);
  };
  // edit existing data
  const updateRooms = (values, resetForm, setValues) => {
    values.RoomStatus =
      selectedStatus == "Available"
        ? 34
        : selectedStatus == "Occupaid"
        ? 35
        : selectedStatus == "Under Maintenance"
        ? 36
        : 34;
    setIsEditing(false);
    setSelectedStatus("Available");
    getAllRoomData[selectedIndex].roomTypeId = values.roomTypeId;
    getAllRoomData[selectedIndex].RoomStatus = values.RoomStatus;
    getAllRoomData[selectedIndex].RoomKeyNo = values.RoomKeyNo;
    getAllRoomData[selectedIndex].RoomNo = values.RoomNo;
    getAllRoomData[selectedIndex].NoOfBed = values.noOfBed;
    resetForm();
  };

  // Utility function to compare objects
  const isEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  // insert update API call
  const handleSubmit = async () => {
    if (getAllRoomData.length > 0) {
      const newRooms = [];
      const updateRooms = [];
      getAllRoomData.forEach((item) => {
        if (item.RoomId) {
          const originalRoom = RoomDetails.find(
            (room) => room.RoomId === item.RoomId
          );
          if (!isEqual(originalRoom, item)) {
            updateRooms.push(item);
          }
        } else {
          newRooms.push(item);
        }
      });

      const InsertParams = {
        roomList: newRooms.map((item) => ({
          roomTypeId: item?.roomTypeId,
          RoomNo: item?.RoomNo,
          RoomKeyNo: item?.RoomKeyNo,
          RoomStatus: item?.RoomStatus,
          Bed: item?.Bed,
        })),
      };
      const updateParams = {
        roomList: updateRooms.map((item) => ({
          roomMasterId: item?.RoomId,
          roomTypeId: item?.roomTypeId,
          RoomNo: item?.RoomNo,
          RoomKeyNo: item?.RoomKeyNo,
          RoomStatus: item?.RoomStatus,
          updateDate: item?.updateDate,
          NoOfBed : item?.NoOfBed,
        })),
      };
      if (newRooms.length <= 0 && updateRooms.length <= 0) {
        toast.error("Update data before clicking on save");
        onClose();
        return;
      }

      let response;
      try {
        let message;
        if (newRooms.length > 0) {
          response = await callApi({
            apiURL: Constants.API.addRoomNo,
            requestBody: InsertParams,
          });
          message = "added";
        }
        if (updateRooms.length > 0) {
          response = await callApi({
            apiURL: Constants.API.updateRoomData,
            requestBody: updateParams,
          });
          message = "updated";
        }
        if (response?.success) {
          toast.success(
            <div className="custom_Toast">
              <h5>Room</h5>
              Room has been {message} successfully
            </div>,
            {
              position: toast.POSITION.TOP_CENTER,
              theme: "colored",
              hideProgressBar: true,

              style: {
                width: "350px",
                backgroundColor: "#0ABE75",
                color: "#FFFFFF",
              },
              autoClose: 500,
              closeButton: true,
              transition: Zoom,
              onClose: () => {
                setShowPopup(false);
                onClose();
                // resetForm();
              },
            }
          );
        }
      } catch (error) {
        console.error(error);
      }
      setShowPopup(false);
      onClose();
    } else {
      toast.error("Add at least one room");
    }
  };

  // Delete api call
  const deleteRoom = async (index, item) => {
    // const formatDateString = (dateString) => dateString?.split('.')[0];
    // const deleteRoom = [...getAllRoomData];
    // deleteRoom.splice(index, 1);
    // setAllGetRoomData(deleteRoom);
    setIsEditing(false);
    const response = await callApi({
      apiURL: Constants.API.deleteRoom,
      requestBody: {
        roomMasterId: item?.RoomId,
        updateDate: item?.updateDate,
      },
    });
    if (response?.success) {
      const actionMessage = "Room";
      toast.success(
        <div className="custom_Toast">
          <h5>{actionMessage}</h5>
          Room has been deleted successfully
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          hideProgressBar: true,
          transition: Zoom,
          style: {
            width: "350px",
            backgroundColor: "#0ABE75",
            color: "#FFFFFF",
          },
          autoClose: 500,
          closeButton: true,
          onClose: () => {
            setShowPopup(false);
            onClose();
            // resetForm();
          },
        }
      );
    }
  };

  return (
    <>
      <div className="Overlay"></div>
      <div className={showPopup ? "AssignPopupOpen" : "AssignPopupOpen hidden"}>
        <div className="Assignpophead">
          {isEditing ? (
            <p className="Assign_p">
              Update Room -
              {selectedRoom?.RoomTypeName
                ? selectedRoom.RoomTypeName.charAt(0).toUpperCase() +
                  selectedRoom.RoomTypeName.slice(1)
                : ""}
            </p>
          ) : (
            // <p className="Assign_p">Assign Room - {selectedRoom?.RoomTypeName}</p>
            <p className="Assign_p">
              Assign Room -
              {selectedRoom?.RoomTypeName
                ? selectedRoom.RoomTypeName.charAt(0).toUpperCase() +
                  selectedRoom.RoomTypeName.slice(1)
                : ""}
            </p>
          )}
          <button onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#424242"
              />
            </svg>
          </button>
        </div>

        <Formik
          initialValues={initialValuesForFormiks}
          validationSchema={validationSchema}
        >
          {({
            values,
            resetForm,
            setValues,
            validateForm,
            setFieldTouched,
          }) => (
            <div className="formic-container">
              <Form className="AsssignForm">
                <div className="button_container">
                  <button
                    className="room_save"
                    onClick={() => {
                      saveRoomData(
                        values,
                        resetForm,
                        validateForm,
                        setFieldTouched,
                        selectedBed
                      );
                    }}
                    type="button"
                    style={{ display: isEditing ? "none" : "block" }}
                  >
                    Save and Add more
                  </button>
                </div>
                {/* <div className="AssignPopField">
                  <label htmlFor="RoomType" className="AssignPopField-label">
                    Room Type <sup>*</sup>
                  </label>

                  <Field
                    component={MySelect}
                    name="roomTypeId"
                    options={roomTypeList}
                    placeholder="Select..."
                    className="designation-dropdown"
                    isDisabled={true}
                  />
                  <div className="error">
                    <ErrorMessage name="departmentId" />
                  </div>
                </div> */}

                <div className="AssignPopField">
                  <label htmlFor="RoomNo" className="AssignPopField-label">
                    Room Number <sup>*</sup>
                  </label>

                  <Field
                    type="text"
                    name="RoomNo"
                    placeholder="Enter room No. "
                  />
                  <div className="error">
                    <ErrorMessage name="RoomNo" />
                  </div>
                </div>
                <div className="AssignPopField">
                  <label htmlFor="roomKeyNo" className="AssignPopField-label">
                    Room Key <sup>*</sup>
                  </label>
                  <Field
                    type="text"
                    id="roomKeyNo"
                    name="RoomKeyNo"
                    placeholder="Enter Key Number"
                  />
                  <div className="error">
                    <ErrorMessage name="RoomKeyNo" />
                  </div>
                </div>
                <div className="AssignPopField">
                  <label htmlFor="roomKeyNo" className="AssignPopField-label">
                    Number of Bed <sup>*</sup>
                  </label>
                  <Field
                    component={MySelect}
                    id="roomKeyNo"
                    name="noOfBed"
                    placeholder="Number of Bed"
                    options={[0, 1, 2].map((x) => {
                      return { label: x, value: x };
                    })}
                    onChange={(selectedOption) =>
                      setSelectedBed(selectedOption)
                    }
                  />
                  <div className="error">
                    <ErrorMessage name="RoomKeyNo" />
                  </div>
                </div>

                <div className=" toggleRoomStatus">
                  <label htmlFor="RoomStatus" className="AssignPopField-label">
                    Room Status <sup>*</sup>
                  </label>
                  <br />
                  <ToggleButtonGroup
                    name="RoomStatus"
                    className="ToggleButtonGroups"
                    value={selectedStatus}
                    exclusive
                    onChange={handleStatusChange}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value="Available"
                      aria-label="Available"
                      disabled={selectedStatus === "Available"}
                      sx={{
                        textTransform: "none",
                        padding: "8px 16px",
                        borderRadius: "8px 0px 0px 8px",
                      }}
                    >
                      Available
                    </ToggleButton>
                    <ToggleButton
                      value="Occupaid"
                      aria-label="Occupaid"
                      disabled={selectedStatus === "Occupaid"}
                      sx={{ textTransform: "none", padding: "8px 16px" }}
                    >
                      Occupied
                    </ToggleButton>
                    <ToggleButton
                      value="Under Maintenance"
                      aria-label="Under Maintenance"
                      disabled={selectedStatus === "Under Maintenance"}
                      sx={{
                        textTransform: "none",
                        padding: "8px 16px",
                        borderRadius: "0px 8px 8px 0px",
                      }}
                    >
                      Under-Maintanence
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <div className="Room_End">
                  {!isEditing ? (
                    <>
                      <button
                        type="button"
                        className="Room_buttonb"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        className="Room_buttona"
                        type="button"
                        onClick={onClose}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="Room_buttonb"
                        type="button"
                        onClick={() => {
                          updateRooms(values, resetForm, setValues);
                        }}
                        style={{ display: isEditing ? "block" : "none" }}
                      >
                        Save
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        type="button"
                        className="Room_buttona"
                        onClick={() => {
                          setIsEditing(false);
                          setSelectedStatus("Available");
                          resetForm();
                        }}
                        style={{ display: isEditing ? "block" : "none" }}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </Form>

              {getAllRoomData?.length === 0 ? (
                <div className="room_data">
                  <p style={{ fontSize: "14px" }}>
                    None of the rooms have been added yet.
                  </p>
                </div>
              ) : (
                <div className="data_container">
                  
                  {getAllRoomData?.map((item, index) => {
                    return (
                      <div className=" room_data" key={index}>
                        <div className="room_content">
                          <p
                            className="mb-0 font-semibold"
                            style={{ fontSize: "14px" }}
                          >
                            {`${selectedRoom?.RoomTypeName?.toString()
                              .charAt(0)
                              .toUpperCase()}${selectedRoom?.RoomTypeName.slice(
                              1
                            )} `}
                          </p>
                          <p>
                            <label
                              className="mb-0 font-semibold"
                              style={{ fontSize: "14px" }}
                            >
                              #:
                            </label>
                            {` ${item.RoomNo.charAt(
                              0
                            ).toUpperCase()}${item.RoomNo.slice(1)}`}
                          </p>
                          <p className="mb-0">
                            <label
                              className="mb-0 font-semibold"
                              style={{ fontSize: "14px" }}
                            >
                              <i class="fa-solid fa-key"></i>:
                            </label>
                            {` ${item.RoomKeyNo}`}
                          </p>
                        </div>

                        <button
                          className="btneditRoom"
                          disabled={isEditing ? true : false}
                          style={{
                            opacity: isEditing ? 0.2 : 1,
                            cursor: isEditing ? "default" : "pointer",
                          }}
                        >
                          <div
                            onClick={() => editGuest(index, item, setValues)}
                          >
                            {/* <i className="fa-regular fa-pen-to-square"></i> */}
                            <img src={edit} />
                          </div>
                        </button>
                        <button
                          className="btndeleteRoom"
                          disabled={isEditing ? true : false}
                          style={{
                            opacity: isEditing ? 0.2 : 1,
                            cursor: isEditing ? "default" : "pointer",
                          }}
                        >
                          <div
                            onClick={() =>
                              item.isNew ? null : deleteRoom(index, item)
                            }
                          >
                            <img
                              src={trash}
                              style={{
                                color: "var(--alert-status-error)",
                                opacity: item.isNew ? 0.2 : 1,
                                cursor: item.isNew ? "default" : "pointer",
                              }}
                            />
                          </div>
                        </button>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};
export default AssignRoomPopup;
