// import React, { useState, useEffect } from "react";
// import images from "../../../../assets/images/quotes.png";
// import slider from "../../../../assets/images/slider con.png";
// import dot from "../../../../assets/images/selips.png";
// import "./Testimonials.css";

// import { BsArrowLeft } from "react-icons/bs";
// import { BsArrowRight } from "react-icons/bs";

// function Card(props) {
//   return (
//     <>
//       <div className="slide-card-main">
//         <img className="slide-card-img" src={props.img} />
//         <p class="slide-card-text1">{props.text1}</p>
//         <br />
//         <br />
//         <br />
//         <p class="slide-card-text2">{props.text2}</p>
//         <p class="slide-card-text3">{props.text3}</p>
//       </div>
//       <img src={dot} className="mt-[8vh] ml-4" />
//     </>
//   );
// }

// const Testimonials = () => {
//   //   const { ref, previous, next, setCurrent, reset } = useCarousel();
//   const { ref, previous, next, setCurrent, reset } = {};
//   const [autoSlide, setAutoSlide] = useState(true);

//   useEffect(() => {
//     let intervalId;

//     if (autoSlide) {
//       intervalId = setInterval(() => {
//         next();
//       }, 3000);
//     }

//     return () => {
//       clearInterval(intervalId);
//     };
//   }, [autoSlide]);

//   return (
//     <>
//       <div className="test-sec">
//         <div className="test-title">
//           <center>
//             <h1 className="fourth-heading text-[#212121]">Testimonials</h1>
//             <p className="fourth-text text-[#424242] mt-5">
//               Start monitoring for free and then choose your plan.
//             </p>
//           </center>
//         </div>

//         <div className="test-main-div">
//           <ul ref={ref} className="carousel__list8">
//             <li class="carousel__item8">
//               <Card
//                 num="01"
//                 img={images}
//                 text1="Dr. Sharan"
//                 text2="– Wellness Coach at Wellness First "
//                 text3="Half Olive has revolutionized the way we manage our wellness center. The cloud-based solution has provided us with the flexibility to access our data from anywhere, making it easy to stay on top of our schedules and guest information. The analytics feature has also been incredibly helpful in identifying areas for improvement in our operations. I highly recommend Half Olive to anyone in the wellness industry looking to streamline their business.  "
//               />
//             </li>

//             <li class="carousel__item8">
//               <Card
//                 num="01"
//                 img={images}
//                 text1="Micheal"
//                 text2="– Owner of Atmanand Wellness Center"
//                 text3="Since implementing Half Olive into our wellness center, we have seen a significant increase in productivity and efficiency. The platform is user-friendly and easy to navigate, making it simple for our team to manage guest information, schedules, and payments. Kudos to Half Olive !"
//               />
//             </li>

//             <li class="carousel__item8">
//               <Card
//                 num="01"
//                 img={images}
//                 text1="Arun S."
//                 text2="– Manager of Wellness Services at Sunshine Wellness."
//                 text3="Half Olive's auto-scheduling feature is a game-changer for our business. It has significantly reduced the time and effort required to manage our schedules, enabling us to focus on providing exceptional wellness services to our clients. This top feature has really eased our day-to-day operations and has provided us with the flexibility to customize our schedules to fit our clients' needs. - Micheal, "
//               />
//             </li>

//             <li class="carousel__item8">
//               <Card
//                 num="01"
//                 img={images}
//                 text1="Chaitali"
//                 text2="– Director of Operations at Yaani Wellness Center"
//                 text3="Half Olive has been a game-changer for our business. The platform has streamlined our processes, enabling us to focus on what we do best - providing top-notch wellness services to our clients. The customer support team at Half Olive is exceptional, and they are always available to answer any questions we have. Thank you, Half Olive, for helping us take our business to the next level! - Chaitali, "
//               />
//             </li>

//             <li class="carousel__item8">
//               <Card
//                 num="01"
//                 img={images}
//                 text1="Dr. Sharan"
//                 text2="– Wellness Coach at Wellness First "
//                 text3="Half Olive has revolutionized the way we manage our wellness center. The cloud-based solution has provided us with the flexibility to access our data from anywhere, making it easy to stay on top of our schedules and guest information. The analytics feature has also been incredibly helpful in identifying areas for improvement in our operations. I highly recommend Half Olive to anyone in the wellness industry looking to streamline their business.  "
//               />
//             </li>

//             <li class="carousel__item8">
//               <Card
//                 num="01"
//                 img={images}
//                 text1="Micheal"
//                 text2="–Owner of Atmanand Wellness Center"
//                 text3="Since implementing Half Olive into our wellness center, we have seen a significant increase in productivity and efficiency. The platform is user-friendly and easy to navigate, making it simple for our team to manage guest information, schedules, and payments. Kudos to Half Olive !"
//               />
//             </li>

//             <li class="carousel__item8">
//               <Card
//                 num="01"
//                 img={images}
//                 text1="Arun S."
//                 text2="– Manager of Wellness Services at Sunshine Wellness."
//                 text3="Half Olive's auto-scheduling feature is a game-changer for our business. It has significantly reduced the time and effort required to manage our schedules, enabling us to focus on providing exceptional wellness services to our clients. This top feature has really eased our day-to-day operations and has provided us with the flexibility to customize our schedules to fit our clients' needs. - Micheal, "
//               />
//             </li>

//             <li class="carousel__item8">
//               <Card
//                 num="01"
//                 img={images}
//                 text1="Chaitali"
//                 text2="– Director of Operations at Yaani Wellness Center"
//                 text3="Half Olive has been a game-changer for our business. The platform has streamlined our processes, enabling us to focus on what we do best - providing top-notch wellness services to our clients. The customer support team at Half Olive is exceptional, and they are always available to answer any questions we have. Thank you, Half Olive, for helping us take our business to the next level! - Chaitali, "
//               />
//             </li>
//           </ul>
//         </div>
//         <hr className="font-bold border-solid border-1 border-[#B4BA3D] mt-[-4vh]" />
//         <div className="test-btn-div">
//           <center className="flex justify-center mt-10">
//             <button
//               className="prev-btn"
//               onClick={() => previous()}
//               style={{ background: "none", border: "none", cursor: "pointer" }}
//             >
//               <BsArrowLeft className="font-bold text-gray-500 border-2 text-2xl rounded-full" />
//             </button>
//             <div className="mt-2 mx-3">
//               <img src={slider} />
//             </div>
//             <button
//               className="next-btn"
//               onClick={() => next()}
//               style={{ background: "none", border: "none", cursor: "pointer" }}
//             >
//               <BsArrowRight className="font-bold text-gray-500 border-2 text-2xl rounded-full" />
//             </button>
//           </center>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Testimonials;

//                                                NEW TESTIMONAIAL

import { useState, useEffect, useRef } from "react";
import "./Testimonials.css";
import double_quote from "../../../../assets/images/quotes.png";
import navigation_1 from "../../../../assets/icons/testimonial_navigationbar_1.svg";
import navigation_2_4 from "../../../../assets/icons/testimonial_navigationbar_2.svg";

const testimonials = [
  {
    content:
      "Half Olive has revolutionized the way we manage our wellness center. The cloud-based solution has provided us with the flexibility to access our data from anywhere, making it easy to stay on top of our schedules and guest information. The analytics feature has also been incredibly helpful in identifying areas for improvement in our operations. I highly recommend Half Olive to anyone in the wellness industry looking to streamline their business.",
    name: "Dr. Sharon",
    position: "– Wellness Coach at Wellness First",
  },
  {
    content:
      "Since implementing Half Olive into our wellness center, we have seen a significant increase in productivity and efficiency. The platform is user-friendly and easy to navigate, making it simple for our team to manage guest information, schedules, and payments. Kudos to Half Olive!",
    name: "Arun S.",
    position: "– Owner of Atmaram Wellness Center",
  },
  {
    content:
      "Half Olive's auto-scheduling feature is a game-changer for our business. It has significantly reduced the time and effort required to manage our schedules, enabling us to focus on providing exceptional wellness services to our clients. The top feature has really helped in our day-to-day operations and has provided us with the flexibility to customize our schedules to fit our clients' needs.",
    name: "Michael",
    position: "– Manager of Wellness Services at Sunshine Wellness",
  },
  {
    content:
      "Half Olive has completely transformed the way we manage our wellness center. The ease of integrating client information with our scheduling system has saved us hours each week. We can now track guest progress and personalize their wellness plans effortlessly. It's a must-have tool for any modern wellness business!",
    name: "Sophie L.",
    position: "– Director of Wellness at Serenity Spa",
  },

  {
    content:
      "We’ve tried several software solutions for managing our wellness center, but none have been as effective as Half Olive. The real-time reporting and client management features have been invaluable in optimizing our operations. I’ve seen a noticeable improvement in both client satisfaction and staff efficiency since we adopted this platform.",
    name: "Derrick P.",
    position: "– General Manager at Pure Balance Wellness",
  },

  {
    content:
      "Half Olive has streamlined every aspect of our wellness center’s operations. From booking appointments to tracking payments and client preferences, everything is in one place. The automated reminders have reduced no-shows, and the customer support team has been exceptional in helping us set everything up.",
    name: "Clara M.",
    position: "– Owner of The Holistic Health Hub",
  },
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const intervalRef = useRef(null);

  useEffect(() => {
    // Listen for window resize events
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    startInterval(); // Start the interval on mount

    return () => {
      window.removeEventListener("resize", handleResize);
      clearInterval(intervalRef.current);
    };
  }, [windowWidth]);

  const startInterval = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setPrevIndex(currentIndex);
      setCurrentIndex(
        (prevIndex) =>
          (prevIndex + 1) %
          Math.ceil(testimonials.length / (windowWidth <= 768 ? 1 : 2))
      );
    }, 5000);
  };

  const handleNavigationClick = (index) => {
    setPrevIndex(currentIndex);
    setCurrentIndex(index);
    clearInterval(intervalRef.current);
    setTimeout(startInterval, 5000);
  };

  // Adjust the slice based on screen size
  const displayedTestimonials = testimonials.slice(
    currentIndex * (windowWidth <= 768 ? 1 : 2),
    currentIndex * (windowWidth <= 768 ? 1 : 2) + (windowWidth <= 768 ? 1 : 2)
  );

  return (
    <div className="testimonial_main_container">
      <div className="testimonial_header">
        <h3>Testimonials</h3>
        <p>Kind words from our clients</p>
      </div>

      <div className="testimonial_review_section">
        {displayedTestimonials.map((testimonial, index) => (
          <div
            key={index}
            className={`testimonial_review_section_item ${
              currentIndex > prevIndex ? "slideRightToLeft" : "slideLeftToRight"
            }`}
          >
            <div className="testimonial_review_section_content">
              <h3 className="testimonial_review_section_content_h3">
                {testimonial.name}
              </h3>
              <p className="testimonial_review_section_content_position">
                {testimonial.position}
              </p>
              <p className="testimonial_review_section_content_content">
                {testimonial.content}
              </p>
            </div>
            <div className="testimonial_review_section_double_quote_mark">
              <img src={double_quote} alt="double_quote_mark" />
            </div>
          </div>
        ))}
      </div>

      <div className="testimonial_navigationbar">
        {Array.from({
          length: Math.ceil(testimonials.length / (windowWidth <= 768 ? 1 : 2)),
        }).map((_, index) => (
          <img
            key={index}
            src={index === currentIndex ? navigation_1 : navigation_2_4}
            alt="navigation_icon"
            className={`navigation_dot ${
              index === currentIndex ? "active" : ""
            }`}
            onClick={() => handleNavigationClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
