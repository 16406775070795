import React, { useEffect, useState } from "react";
import css from "./AddRoomType.module.scss";

import Logo from "../../../../src/assets/images/Logo.png";
import Onboarding_2 from "../../../../src/assets/images/Onboarding_2.png";

import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { VscEdit } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import callApi from "../../../ApiCall";
import Constants from "../../../Constants";

const AddRoomType = () => {
  const [selectedRooms, setSelectedRooms] = useState(0);
  const [roomTypeList, setRoomTypeList] = useState([]);

  const [roomType, setRoomType] = useState("");
  const [roomNo, setRoomNo] = useState("");
  const [roomTypeError, setRoomTypeError] = useState("");
  const [roomNoError, setRoomNoError] = useState("");
  const [propertyMasterId, setPropertyMasterId] = useState(0);
  const [updatedDate, setUpdatedDate] = useState("");
  const [selectedRoomId, setSelectedRoomId] = useState(0);

  const navigate = useNavigate();

  const handleSliderChange = (e) => {
    setSelectedRooms(parseInt(e.target.value));
  };

  const handleRoomChange = (e) => {
    const value = e.target.value;
    setRoomType(value);
    if (value.trim() === "") {
      setRoomTypeError("Please add room type.");
    } else {
      setRoomTypeError("");
    }
  };

  // const handleRoomnoChange = (e) => {
  //   const value = e.target.value;
  //   if (/^\d*$/.test(value)) {
  //     setRoomNo(value);
  //   }
  //   if (value.trim() === "" || value > 200) {
  //     setRoomNoError("Please add valid Number.");
  //   } else {
  //     setRoomNoError("");
  //   }
  // };

  const getRoomDetails = async () => {
    setRoomTypeError("");
    setRoomNoError("");
    setRoomTypeList([]);
    setSelectedRoomId(0);
    // setRoomNo("");
    setRoomType("");
    const response = await callApi({
      apiURL: Constants.API.getRoomDetails,
    });
    if (response?.success) {
      if (response.data.roomsInfo) {
        let obj = response.data.roomsInfo;
        setPropertyMasterId(obj.PropertyMasterId);
        setUpdatedDate(obj.UpdatedDate);
        if (obj.NoOfRooms) {
          setSelectedRooms(obj.NoOfRooms);
          setRoomTypeList(JSON.parse(obj.RoomTypeList));
        } else {
          setSelectedRooms(0);
        }
      } else {
        navigate("/onboading/property");
      }
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    getRoomDetails();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (propertyMasterId === 0) {
      navigate("/onboading/property");
    }
    if (roomType === "") {
      roomType === ""
        ? setRoomTypeError("Please add room type.")
        : setRoomTypeError("");
      // roomNo === 0 || roomNo === ""
      //   ? setRoomNoError("Please add valid Number.")
      //   : setRoomNoError("");
      return;
    }
    console.log(roomTypeList);

    // let sum = +roomNo + roomTypeList.reduce((acc, x) => {
    //   if (x.RoomMasterId !== selectedRoomId) {
    //     const parsedNoOfRooms = parseInt(x.NoOfRooms);
    //     if (isNaN(parsedNoOfRooms)) {
    //       console.log("Error: NoOfRooms is not a number:", x.NoOfRooms);
    //     }
    //     return acc + parsedNoOfRooms;
    //   }
    //   return acc;
    // }, 0);
    // console.log(sum)

    const response = await callApi({
      apiURL: Constants.API.saveRoomType,
      requestBody: {
        propertyMasterId,
        updatedDate,
        roomTypeId: selectedRoomId,
        // roomNo,
        roomType,
        noOfRooms: selectedRooms,
      },
    });
    if (response?.success) {
      getRoomDetails();
    } else {
      console.log("error");
    }
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <section className="sec-left">
          <img src={Onboarding_2} />
        </section>
        <section className="sec1-right">
          <div style={{ marginTop: "10%" }}>
            <div className={css.flexone}>
              <section className={css.left}>
                <img src={Logo} alt="" className={css.img} />
              </section>
              <section className={css.right}>
                <a
                  onClick={() => {
                    navigate("/onboading/about-property");
                  }}
                  style={{ marginRight: 20 }}
                >
                  <button className={css.icon}>
                    <AiOutlineArrowLeft />
                  </button>
                </a>

                {selectedRooms > 0 && (
                  <a
                    onClick={() => {
                      navigate("/onboading/add-room-rate");
                    }}
                  >
                    <button className={css.icon}>
                      <AiOutlineArrowRight />
                    </button>
                  </a>
                )}
              </section>
            </div>
            <div className={css.title}>Add a Room Type</div>
            <div className={css.desc}>Please add a first room type</div>

            <div className={css.slider}>
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label htmlFor="roomSlider">
                    How many Rooms <span style={{ color: "red" }}> *</span>{" "}
                  </label>

                  <p>200+</p>
                </div>{" "}
                <input
                  type="range"
                  id="roomSlider"
                  className={css.sliderbar}
                  min={1}
                  max={200}
                  value={selectedRooms}
                  onChange={handleSliderChange}
                  // disabled
                />
              </div>

              <div className={css.rooms}>{selectedRooms} Room(s)</div>
            </div>
            <div className={css.editDiv}>
              {roomTypeList.length > 0 &&
                roomTypeList.map((room, index) => {
                  return (
                    <div
                      key={index}
                      className={css.element}
                      onClick={() => {
                        // setRoomNo(room.NoOfRooms);
                        setRoomType(room.RoomTypeName);
                        setSelectedRoomId(room.RoomMasterId);
                      }}
                    >
                      <span>{room.RoomTypeName}</span>
                      <div className={css.icondiv}>
                        <VscEdit
                          style={{ color: "#5D7202" }}
                          className={css.icon}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className={css.flexform2}>
              <div>
                <label htmlFor="gstNumber">
                  Room type: <span style={{ color: "red" }}>*</span>{" "}
                </label>
                <br />
                <input
                  type="text"
                  id="gstNumber"
                  value={roomType}
                  placeholder="Enter room type"
                  onChange={handleRoomChange}
                />
                {roomTypeError != "" && (
                  <div className="error-message" style={{ color: "red" }}>
                    {roomTypeError}
                  </div>
                )}
              </div>
              {/* <div>
                <label htmlFor="country">
                  No. Of rooms:<span style={{ color: "red" }}>*</span>{" "}
                </label>
                <br />
                <input
                  type="text"
                  id="gstNumber"
                  value={roomNo}
                  placeholder="0"
                  maxLength={3}
                  onChange={handleRoomnoChange}
                  pattern="[0-9]*"
                />
                {roomNoError != "" && (
                  <div className="error-message" style={{ color: "red" }}>
                    {roomNoError}
                  </div>
                )}
              </div> */}
            </div>

            {selectedRoomId > 0 ? (
              <div className={css.flexform3}>
                <div className={css.right}>
                  <button onClick={handleSubmit}>Update</button>
                </div>
                <div className={css.right}>
                  <button
                    onClick={() => {
                      // setRoomNo(0);
                      setRoomType("");
                      setSelectedRoomId(0);
                    }}
                    style={{ marginLeft: 10 }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className={css.flexform3}>
                <div className={css.left}></div>
                <div className={css.right}>
                  <button onClick={handleSubmit}>Save and add more</button>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default AddRoomType;
