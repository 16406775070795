import { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import MySelect from "../../common/SingleSelect";
import callApi from "../../ApiCall";
import Constants from "../../Constants";
import { getDisplayDate } from "../../components/DateTimeUtils";
import { toast, Zoom } from "react-toastify";

export default function CheckOut({ onClose }) {
  const [showPopup, setShowPopup] = useState(true);
  const [occupiedRoomList, setOccupiedRoomList] = useState([]);
  const [guestInfo, setGuestInfo] = useState({
    guestName: "",
    checkIn: "",
    checkOut: "",
  });

  // Fetch occupied rooms
  const getOccupiedRoomList = async () => {
    const response = await callApi({
      apiURL: Constants.API.getOccupiedRoomList,
    });
    if (response?.success) {
      const { occupiedRoom = [] } = response.data;
      const roomTypeData = occupiedRoom.map((x) => ({
        value: x.RoomMasterId,
        label: x.RoomNumber,
      }));
      setOccupiedRoomList(roomTypeData);
    } else {
      console.error("Error fetching occupied room list");
    }
  };

  // Fetch guest info based on selected roomMasterId
  const getGuestInfo = async (roomMasterId, setFieldValue) => {
    const response = await callApi({
      apiURL: Constants.API.getCheckoutGuestInfo,
      requestBody: { roomMasterId },
    });
    if (response?.success) {
      const { GuestName, CheckInDate, CheckOutDate, BookingMasterId } =
        response.data.checkoutGuestInfo[0];
      setGuestInfo({
        guestName: GuestName,
        checkIn: getDisplayDate(CheckInDate),
        checkOut: getDisplayDate(CheckOutDate),
      });

      // Set BookingMasterId in Formik's form state
      setFieldValue("guestName", GuestName);  // Set guestName in Formik form state
      setFieldValue("bookingMasterId", BookingMasterId);
    } else {
      console.error("Error fetching guest info");
    }
  };

  useEffect(() => {
    getOccupiedRoomList();
  }, []);

  const handleCancel = () => {
    setShowPopup(false);
    onClose(); // Close the popup
  };

  const initialValuesForFormiks = {
    roomMasterId: 0,
    guestName: "",  // Initialize guestName as empty string
    checkIn: "",
    checkOut: "",
    bookingMasterId: null,
  };

  const validationSchema = Yup.object({
    roomMasterId: Yup.number().required().moreThan(0, "Room Number is required"),
    guestName: Yup.string().required("Guest Name is required"),
    bookingMasterId: Yup.number().required("Booking Master Id is required"), 
  });

  const handleCheckout = async (values) => {
    const { roomMasterId, bookingMasterId } = values;
    // Call the checkout API with roomMasterId and bookingMasterId
    const response = await callApi({
      apiURL: Constants.API.checkoutGuest,
      requestBody: { roomMasterId, bookingMasterId },
    });

    if (response?.success) {
     
      handleCancel();
      toast.success(
        <div className="custom_Toast">
          <h5>Checkout successfully</h5>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          hideProgressBar: true,
          className: "toast_pop",
          autoClose: 1500,
          closeButton: true,
          transition: Zoom,
        }
      );
    } else {
      console.error("Error during checkout");
    }
  };

  return (
    <>
      <div className="Overlay"></div>
      <div className={showPopup ? "AssignPopupOpen" : "AssignPopupOpen hidden"} style={{width:"30%"}}>
        <div className="Assignpophead">
          <p className="Assign_p">Check Out</p>
          <button onClick={handleCancel}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#424242"
              />
            </svg>
          </button>
        </div>
        <Formik
          initialValues={initialValuesForFormiks}
          validationSchema={validationSchema}
          onSubmit={handleCheckout}
        >
          {({
            values,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
          }) => (
            <div>
              <Form className="AsssignForm" style={{width:"95%"}}>
                <div className="AssignPopField">
                  <label htmlFor="RoomNo" className="Designation-label">
                    Room No <sup>*</sup>
                  </label>
                  <Field
                    component={MySelect}
                    name="roomMasterId"
                    options={occupiedRoomList}
                    placeholder="Select Room No"
                    className="designation-dropdown"
                    onChange={(option) => {
                      setFieldValue("roomMasterId", option.value);
                      getGuestInfo(option.value, setFieldValue); // Pass setFieldValue to getGuestInfo
                    }}
                  />
                  <div className="error">
                    <ErrorMessage name="roomMasterId" />
                  </div>
                </div>
                <div className="AssignPopField">
                  <label htmlFor="GuestName" className="Designation-label">
                    Guest Name <sup>*</sup>
                  </label>
                  <Field
                    type="text"
                    id="guestName"
                    name="guestName"
                    placeholder="Guest Name"
                    className="guest_field"
                    value={values.guestName}  
                    onChange={(e) => setFieldValue("guestName", e.target.value)} 
                  />
                  <div className="error">
                    <ErrorMessage name="guestName" />
                  </div>
                </div>
                <div className="AssignPopField">
                  <label htmlFor="checkIn" className="Designation-label">
                    Check-in Date
                  </label>
                  <Field
                    type="text"
                    id="checkIn"
                    name="checkIn"
                    placeholder="Check-in Date"
                    className="guest_field"
                    value={guestInfo.checkIn}
                    readOnly
                  />
                </div>
                <div className="AssignPopField">
                  <label htmlFor="checkOut" className="Designation-label">
                    Check-out Date
                  </label>
                  <Field
                    type="text"
                    id="checkOut"
                    name="checkOut"
                    placeholder="Check-out Date"
                    className="guest_field"
                    value={guestInfo.checkOut}
                    readOnly
                  />
                </div>
                <div className="roomPop_buttons">
                  <button
                    type="submit"
                    className="roomMaster_buttonn"
                  >
                    Check Out
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    className="roomMaster_button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </>
  );
}
