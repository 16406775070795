import "./Treatment.css";
import React, { useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { toast, Zoom } from "react-toastify";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button, Box } from "@mui/material";
import callApi from "../../../ApiCall";
import Constants from "../../../Constants";
import DatePicker from "react-multi-date-picker";

import Select from "react-select";
import { Link } from "react-router-dom";
import MultipleCheckboxSelect from "../../../common/MultiCheckBoxSelect.js/MultiCheckBoxSelect";
import Multiselect from "multiselect-react-dropdown";
import MySelect from "../../../common/SingleSelect";

export default function DietPage({ GuestMasterId }) {
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "var(--primary-green)" : "white",
      "&:hover": {
        // backgroundColor: '#b4ba3d',
        backgroundColor: state.isSelected ? "var(--primary-green)" : "#b4ba3d",
        color: "white",
      },
    }),
  };

  const [selectedDate, setSelectedDate] = useState(moment());
  const [dateRange, setDateRange] = useState([]);
  const [tempSelectedDate, setTempSelectedDate] = useState(selectedDate);
  const [selectedGuestBookingHistory, setSelectedGuestBookingHistory] =
    useState([]);
  const [mealError, setMealError] = useState("");
  const [dietPlanStartDate, setDietPlanStartDate] = useState(null);
  const [dietPlanEndDate, setDietPlanEndDate] = useState(null);
  const [selectDietPlanError, setSelectDietPlanError] = useState("");
  const [dietPlanStartDateError, setDietPlanStartDateError] = useState("");
  const [dietPlanEndDateError, setDietPlanEndDateError] = useState("");
  const [activeDietPlanList, setActiveDietPlanList] = useState([]);
  const [dietPlanName, setDietPlanName] = useState("");
  const [mealInputs, setMealInputs] = useState({
    prebreakfast: "",
    postbreakfast: "",
    prelunch: "",
    postlunch: "",
    predinner: "",
    postdinner: "",
  });
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [timeSlot, setTimeSlot] = useState([]);
  const [selectedMorningSlot, setSelectedMorningSlot] = useState([]);
  const [selectedEveningSlot, setSelectedEveningSlot] = useState([]);
  const [applyToAllDynamic, setApplyToAllDynamic] = useState(false); // State to manage checkbox
  const [therapyList, setTreatmentList] = useState([]);
  // const [selectedTherapy, setSelectedTherapy] = useState({
  //   morningTherapy: [],
  //   eveningTherapy: [],
  // });
  const [selectedMorningTherapy, setSelectedMorningTherapy] = useState([]);
  const [selectedEveningTherapy, setSelectedEveningTherapy] = useState([]);
  const [dynamicEmployees, setDynamicEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [morningActivityOptions, setMorningActivityOptions] = useState([]);
  const [eveningActivityOptions, setEveningActivityOptions] = useState([]);
  const [morningActivity, setMorningActivity] = useState(null);
  const [eveningActivity, setEveningActivity] = useState(null);
  const [scheduleData, setScheduleData] = useState({
    today: {},
    tomorrow: {},
    dayAfterTomorrow: {},
  });

  const handleEmployeeChange = (selectedList) => {
    setSelectedEmployees(selectedList);
  };

  const handleSelectMorningSlot = (slot) => {
    setSelectedMorningSlot(slot);

    const eveningSlotIndex = morningTherapySlots.findIndex(
      (morningSlot) =>
        morningSlot.GuestActivityTimingSlotsId ===
        slot.GuestActivityTimingSlotsId
    );

    if (eveningSlotIndex !== -1 && eveningTherapySlots[eveningSlotIndex]) {
      setSelectedEveningSlot(eveningTherapySlots[eveningSlotIndex]);
    }
  };

  const handleSelectEveningSlot = (slot) => {
    setSelectedEveningSlot(slot);

    const morningSlotIndex = eveningTherapySlots.findIndex(
      (eveningSlot) =>
        eveningSlot.GuestActivityTimingSlotsId ===
        slot.GuestActivityTimingSlotsId
    );

    if (morningSlotIndex !== -1 && morningTherapySlots[morningSlotIndex]) {
      setSelectedMorningSlot(morningTherapySlots[morningSlotIndex]);
    }
  };

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  // Checkbox to toggle applyToAllDays
  const handleCheckboxChange = (e) => {
    setApplyToAllDynamic(e.target.checked);
  };

  const handleSubmitTimeSlot = () => {
    if (selectedMorningSlot && selectedEveningSlot) {
      const bookingForSelectedDate = selectedGuestBookingHistory.find(
        (booking) => {
          const checkInDate = moment(booking.CheckInDate);
          const checkOutDate = moment(booking.CheckOutDate);
          return tempSelectedDate.isBetween(
            checkInDate,
            checkOutDate,
            null,
            "[]"
          );
        }
      );
      if (!bookingForSelectedDate) {
        toast.error("No booking found for the selected date.");
        return;
      }
      const selectedEmployeeIds = selectedEmployees.map(
        (employee) => employee.value
      );

      const bookingMasterId = bookingForSelectedDate.BookingMasterId;

      const formData = {
        bookingMasterId: bookingMasterId,
        preferredEmployees: selectedEmployeeIds,
        guestMasterId: GuestMasterId,
        morningTherapySlots: selectedMorningSlot,
        eveningTherapySlots: selectedEveningSlot,
        selectedDate: tempSelectedDate.format("YYYY-MM-DD HH:mm:ss"),
        appliesToAllDays: applyToAllDynamic,
      };
      const a = async () => {
        const response = await callApi({
          apiURL: Constants.API.saveTherapySlot,
          requestBody: formData,
        });
      };
      a();

      setPopupVisible(false);
    } else {
      alert("Please select both morning and evening time slots.");
    }
  };

  const getTreatmentData = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getPackageTherapy,
        requestBody: {},
      });
      if (response?.success) {
        setTreatmentList(response.data.therapyList);
      } else {
        console.log("Error:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getSelectedGuestBookingHistory = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.selectedGuestBookingHistory,
        requestBody: { guestMasterId: GuestMasterId },
      });
      // console.log("response", response);
      if (response?.success) {
        setSelectedGuestBookingHistory(
          response.data.selectedGuestBookingHistory
        );
      } else {
        console.log("Error:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getActiveDietPlanList = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getActiveDietPlan,
      });
      if (response?.success) {
        setActiveDietPlanList(response.data.aciveDietPlanList);
      } else {
        console.log("Error:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getTimeSlot = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getTimeSlot,
      });
      if (response?.success) {
        setTimeSlot(response.data.timeSlotsList);
      } else {
        console.log("Error:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getDynamicActivityList = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getDynamicActivityList,
      });

      if (
        response.success &&
        response.data &&
        response.data.dynamicActivityList
      ) {
        const morningActivities = [];
        const eveningActivities = [];

        // Categorize morning and evening activities
        response.data.dynamicActivityList.forEach((activity) => {
          if (activity.ActivityName === "Morning Therapy") {
            morningActivities.push({
              value: activity.ActivityMasterId,
              label: activity.ActivityName,
            });
          } else if (activity.ActivityName === "Evening Therapy") {
            eveningActivities.push({
              value: activity.ActivityMasterId,
              label: activity.ActivityName,
            });
          }
        });

        // Update the state with activity options
        setMorningActivityOptions(morningActivities);
        setEveningActivityOptions(eveningActivities);

        // Pre-select the first activity or set to null if not available
        setMorningActivity(morningActivities[0] || null);
        setEveningActivity(eveningActivities[0] || null);
      } else {
        console.error("No activities found in response");
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  useEffect(() => {
    getTimeSlot();
    getTreatmentData();
    getDynamicActivityList();
  }, []);

  const morningTherapySlots = timeSlot.filter(
    (slot) => slot.ActivityName == "Morning Therapy"
  );

  // Filter for Evening Therapy slots
  const eveningTherapySlots = timeSlot.filter(
    (slot) => slot.ActivityName == "Evening Therapy"
  );

  const getDatesWiseGuestDietPlan = async (params) => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getDatesWiseGuestDietPlan,
        requestBody: params,
      });
      if (response?.success) {
        return response.data;
      } else {
        console.log("Error:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getDynamicActivityEmployees = async (params) => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getDynamicActivityEmployees,
        requestBody: { guestMasterId: GuestMasterId },
      });
      if (response?.success) {
        setDynamicEmployees(response.data.dynamicActivityEmployees);
      } else {
        console.log("Error:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getSelectedDateTherapies = async (params) => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getSelectedDateTherapies,
        requestBody: {
          guestMasterId: GuestMasterId,
          selectedDate: tempSelectedDate.format("YYYY-MM-DD"),
        },
      });

      if (response?.success) {
        const selectedDateTherapies = response.data.selectedDateTherapies;

        // Check if Therapies data is null or empty
        if (
          !selectedDateTherapies ||
          selectedDateTherapies.length === 0 ||
          selectedDateTherapies[0].Therapies === null
        ) {
          // Clear the morning and evening therapies if the data is null or empty
          setSelectedMorningTherapy([]);
          setSelectedEveningTherapy([]);
        } else {
          let morningTherapies = [];
          let eveningTherapies = [];

          // Loop through the therapies and separate them into morning and evening
          selectedDateTherapies.forEach((therapyData) => {
            // Ensure Therapies is a valid JSON string
            const therapies = therapyData.Therapies
              ? JSON.parse(therapyData.Therapies)
              : [];

            therapies.forEach((therapy) => {
              if (therapy.ActivityName === "Morning Therapy") {
                morningTherapies.push(therapy.value);
              } else if (therapy.ActivityName === "Evening Therapy") {
                eveningTherapies.push(therapy.value);
              }
            });
          });

          setSelectedMorningTherapy(morningTherapies);
          setSelectedEveningTherapy(eveningTherapies);
        }
      } else {
        console.log("Error:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  // Function to fetch and set the data
  const threeDaysTreatmens = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getThreeDayTreatments,
        requestBody: {
          guestMasterId: GuestMasterId,
          selectedDate: tempSelectedDate.format("YYYY-MM-DD"),
        },
      });
      if (response?.success) {
        const { threeDayTreatmens } = response.data;

        // Structure data based on response
        const data = {
          today: processDateData(threeDayTreatmens[0], threeDayTreatmens[3]),
          tomorrow: processDateData(threeDayTreatmens[1], threeDayTreatmens[4]),
          dayAfterTomorrow: processDateData(
            threeDayTreatmens[2],
            threeDayTreatmens[5]
          ),
        };

        setScheduleData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Helper function to process data for each day
  const processDateData = (therapyData, dietData) => {
    const therapies = therapyData.map((item) => ({
      date: item.TherapyDate,
      name: item.ActivityName,
      details: JSON.parse(item.TherapyDetails)
        .map((therapy) => therapy.TherapyName)
        .join(", "),
    }));

    const dietPlan = dietData && dietData.length > 0 ? dietData[0] : null;

    return {
      therapies,
      dietPlan: dietPlan
        ? {
            dietPlanDate: dietPlan.DietPlanDate,
            dietPlanName: dietPlan.DietPlanName,
            preBreakfast: dietPlan.PreBreakfast,
            postBreakfast: dietPlan.PostBreakfast,
            preLunch: dietPlan.PreLunch,
            postLunch: dietPlan.PostLunch,
            preDinner: dietPlan.PreDinner,
            postDinner: dietPlan.PostDinner,
          }
        : null,
    };
  };

  // Fetch data when the component is mounted or selected date changes
  useEffect(() => {
    threeDaysTreatmens();
  }, [tempSelectedDate]);

  const handleDietPlanChange = (selectedOption) => {
    if (selectedOption) {
      setDietPlanName(selectedOption);
    } else {
      setDietPlanName("");
    }
  };

  useEffect(() => {
    if (tempSelectedDate) {
      getSelectedGuestBookingHistory();
      getActiveDietPlanList();
      getDynamicActivityEmployees();
      getSelectedDateTherapies();
      threeDaysTreatmens();

      const params = {
        guestMasterId: GuestMasterId,
        selectedDate: tempSelectedDate.format("YYYY-MM-DD"),
      };

      getDatesWiseGuestDietPlan(params)
        .then((data) => {
          if (
            data &&
            data.dateWiseGuestDietPlanList.length > 0 &&
            data.dateWiseGuestDietPlanList[0].GuestMasterId === GuestMasterId
          ) {
            const dietPlanDetails = data.dateWiseGuestDietPlanList[0];
            setMealInputs({
              prebreakfast: dietPlanDetails.PreBreakfast || "",
              postbreakfast: dietPlanDetails.PostBreakfast || "",
              prelunch: dietPlanDetails.PreLunch || "",
              postlunch: dietPlanDetails.PostLunch || "",
              predinner: dietPlanDetails.PreDinner || "",
              postdinner: dietPlanDetails.PostDinner || "",
            });
            setDietPlanName({
              value: dietPlanDetails.DietPlanId,
              label: dietPlanDetails.DietPlanName,
            });
            const formattedDate = moment(dietPlanDetails.DietPlanDate).format(
              "DD-MM-YYYY"
            );
            setDietPlanStartDate(formattedDate);
            setDietPlanEndDate(formattedDate);
          } else {
            // Clear meal input fields when no diet plan is found
            setMealInputs({
              prebreakfast: "",
              postbreakfast: "",
              prelunch: "",
              postlunch: "",
              predinner: "",
              postdinner: "",
            });
            setDietPlanName("");
            setDietPlanStartDate(null);
            setDietPlanEndDate(null);
          }
        })
        .catch((error) => {
          console.error("Error fetching diet plan:", error);
        });
    }
  }, [tempSelectedDate, GuestMasterId]);

  let todaysDate = moment();

  useEffect(() => {
    const startDate = selectedDate.clone().startOf("week");
    const endDate = selectedDate.clone().endOf("week");

    const dates = [];
    let currentDate = startDate.clone();

    while (currentDate.isSameOrBefore(endDate)) {
      dates.push(currentDate.clone());
      currentDate.add(1, "day");
    }

    setDateRange(dates);
  }, [selectedDate]);

  const handlePreviousWeek = () => {
    setSelectedDate((prevDate) => prevDate.clone().subtract(1, "week"));
  };

  const handleNextWeek = () => {
    setSelectedDate((prevDate) => prevDate.clone().add(1, "week"));
  };

  const isToday = (date) => {
    return date.isSame(tempSelectedDate, "day");
  };

  const handleMealInputChange = (e) => {
    const { id, value } = e.target;
    setMealInputs((prevInputs) => ({
      ...prevInputs,
      [id]: value,
    }));
  };

  const handleDietPlanStartDateChange = (date) => {
    const extractedDate = date && date.toDate ? date.toDate() : date;
    const formattedStartDate = moment(extractedDate).format("DD-MM-YYYY");

    const endDate = moment(extractedDate).add(3, "days");
    const formattedEndDate = endDate.format("DD-MM-YYYY");

    setDietPlanStartDate(formattedStartDate);
    setDietPlanEndDate(formattedEndDate);
  };

  const handleDietPlanEndDateChange = (date) => {
    const extractedDate = date && date.toDate ? date.toDate() : date;
    const formattedDate = moment(extractedDate).format("DD-MM-YYYY");
    setDietPlanEndDate(formattedDate);
    if (
      dietPlanStartDate &&
      moment(formattedDate, "DD-MM-YYYY").isBefore(
        moment(dietPlanStartDate, "DD-MM-YYYY")
      )
    ) {
      setDietPlanStartDate(null);
    }
  };

  const handleSubmit = async () => {
    let hasError = false;

    const checkInDate = moment(selectedGuestBookingHistory[0]?.CheckInDate);
    const checkOutDate = moment(selectedGuestBookingHistory[0]?.CheckOutDate);

    if (!dietPlanName) {
      setSelectDietPlanError("Diet Plan is required.");
      hasError = true;
    } else {
      setSelectDietPlanError("");
    }

    const parsedStartDate = moment(dietPlanStartDate, "DD-MM-YYYY");
    const parsedEndDate = moment(dietPlanEndDate, "DD-MM-YYYY");

    if (!dietPlanStartDate) {
      setDietPlanStartDateError("Start Date is required.");
      hasError = true;
    } else {
      if (!parsedStartDate.isValid()) {
        setDietPlanStartDateError("Invalid Start Date.");
        hasError = true;
      } else {
        setDietPlanStartDateError("");
      }
    }

    if (!dietPlanEndDate) {
      setDietPlanEndDateError("End Date is required.");
      hasError = true;
    } else {
      if (!parsedEndDate.isValid()) {
        setDietPlanEndDateError("Invalid End Date.");
        hasError = true;
      } else {
        setDietPlanEndDateError("");
      }
    }

    const mealFilled =
      mealInputs.prebreakfast ||
      mealInputs.postbreakfast ||
      mealInputs.prelunch ||
      mealInputs.postlunch ||
      mealInputs.predinner ||
      mealInputs.postdinner;
    if (!mealFilled) {
      setMealError("At least one meal is required.");
      hasError = true;
    }

    if (hasError) return;

    if (
      !parsedStartDate.isBetween(checkInDate, checkOutDate, undefined, "[]") ||
      !parsedEndDate.isBetween(checkInDate, checkOutDate, undefined, "[]")
    ) {
      const actionMessage =
        "Please ensure that Diet Plan Dates fall within the booking dates: " +
        checkInDate.format("DD-MM-YYYY") +
        " to " +
        checkOutDate.format("DD-MM-YYYY") +
        ".";

      toast.error(
        <div className="custom_ErrorToast">
          <h5>{actionMessage}</h5>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          hideProgressBar: true,
          transition: Zoom,
          style: {
            width: "400px",
            fontSize: "14px",
            background: "--alert-status-error",
            color: "#fff",
          },
          autoClose: 1500,
          closeButton: true,
        }
      );
      return; // Prevent further execution
    }

    const formattedStartDate = parsedStartDate.format("YYYY-MM-DD");
    const formattedEndDate = parsedEndDate.format("YYYY-MM-DD");

    const payload = {
      GuestId: GuestMasterId,
      DietPlanId: dietPlanName?.value,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      PreBreakfast: mealInputs.prebreakfast,
      PostBreakfast: mealInputs.postbreakfast,
      PreLunch: mealInputs.prelunch,
      PostLunch: mealInputs.postlunch,
      PreDinner: mealInputs.predinner,
      PostDinner: mealInputs.postdinner,
      morningActivity: morningActivity.value,
      eveningActivity: eveningActivity.value,
      morningTherapy: JSON.stringify(selectedMorningTherapy),
      eveningTherapy: JSON.stringify(selectedEveningTherapy),
      therapyDate: tempSelectedDate.format("YYYY-MM-DD"),
    };

    try {
      const response = await callApi({
        apiURL: Constants.API.addDatesWiseGuestDietPlan,
        requestBody: payload,
      });

      if (response?.success) {
        const actionMessage = "Added new Diet Plan";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            DietPlan has been added successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            hideProgressBar: true,
            transition: Zoom,
            style: {
              width: "300px",
              background: "#0ABE75",
              color: "#fff",
            },
            autoClose: 1500,
            closeButton: true,
          }
        );

        setSelectDietPlanError("");
        setDietPlanStartDateError("");
        setDietPlanEndDateError("");
        setMealError("");
        setSelectedMorningTherapy([]);
        setSelectedEveningTherapy([]);
        getSelectedGuestBookingHistory();
        getActiveDietPlanList();
        getDynamicActivityEmployees();
        getSelectedDateTherapies();
        threeDaysTreatmens();
      } else {
        console.error("Error response from API:", response);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <>
      <div className="MainPae">
        <div className="DataTable">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              mb: "2%",
            }}
          >
            {/* Month and year display */}
            <Box mb={"1%"} mt={"1%"} width={"81%"}>
              <p
                style={{
                  fontSize: "16px",
                  fontFamily: "Urbanist",
                  fontWeight: "bold",
                }}
              >
                <div className="month-year">
                  {selectedDate.format("MMMM YYYY")}
                </div>
              </p>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                width: "90%",
              }}
            >
              <Button
                onClick={handlePreviousWeek}
                disableRipple
                endIcon={<ArrowBackIosIcon />}
              ></Button>
              {dateRange.map((date) => {
                const isDateInRange = selectedGuestBookingHistory.some(
                  (booking) => {
                    return (
                      date.isSameOrAfter(moment(booking.CheckInDate)) &&
                      date.isSameOrBefore(moment(booking.CheckOutDate))
                    );
                  }
                );

                const isTodayDate = isToday(date);

                const isClickable = isDateInRange || isTodayDate;

                return (
                  <div
                    key={date.format("YYYY-MM-DD")}
                    className={
                      isTodayDate
                        ? "currDate"
                        : isClickable
                        ? "allDate"
                        : "disabledDate"
                    }
                    style={{
                      border: date.isSame(todaysDate, "day")
                        ? "2px solid #6C7D02"
                        : "",
                      cursor: isClickable ? "pointer" : "not-allowed",
                    }}
                    onClick={() => {
                      if (isClickable) {
                        setTempSelectedDate(date);
                      }
                    }}
                  >
                    <div>{date.format("ddd")}</div>
                    <div>{date.format("D")}</div>
                  </div>
                );
              })}
              <Button
                onClick={handleNextWeek}
                disableRipple
                endIcon={<ArrowForwardIosIcon />}
              ></Button>
            </Box>
          </Box>
        </div>
      </div>

      <div className="diet-plan-container" style={{ display: "flex" }}>
        <div className="guest-diet-plan">
          <div className="select-tretment">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontWeight: "bold",
              }}
            >
              <h1 style={{ fontWeight: "900", fontSize: "30px" }}>Therapy </h1>
              <p
                onClick={togglePopup}
                style={{ cursor: "pointer", fontSize: "18px", color: "blue" }}
              >
                Select Timeslot
              </p>
            </div>
            <div className="tretment-time">
              <div className="morning-tretment">
                <div className="select-container">
                  <label className="data-label">
                    Select Activity <span className="required">*</span>
                  </label>
                  <div>
                    <Select
                      options={morningActivityOptions}
                      // onChange={handleMorningActivityChange}
                      value={morningActivity}
                      name="morningActivity"
                      id="morningActivity"
                      className="activity_dropdown"
                      styles={customStyles}
                    />
                  </div>
                  {selectDietPlanError && (
                    <p className="error-message">{selectDietPlanError}</p>
                  )}
                </div>

                {/* <p>Morning Therapy</p> */}
                <MultipleCheckboxSelect
                  items={therapyList}
                  keyField="value"
                  textField="label"
                  checked={selectedMorningTherapy}
                  label="Therapy"
                  setChecked={(values) => setSelectedMorningTherapy(values)}
                  required={true}
                />
              </div>

              <div className="evening-tretment">
                {/* <p>Evening Therapy</p> */}
                <div className="select-container">
                  <label className="data-label">
                    Select Activity <span className="required">*</span>
                  </label>
                  <div>
                    <Select
                      options={eveningActivityOptions}
                      // onChange={handleEveningActivityChange} // handle change for evening activity
                      value={eveningActivity}
                      name="eveningActivity"
                      id="eveningActivity"
                      className="activity_dropdown"
                      styles={customStyles} // Assuming customStyles is defined
                    />
                  </div>
                  {selectDietPlanError && (
                    <p className="error-message">{selectDietPlanError}</p>
                  )}
                </div>
                <MultipleCheckboxSelect
                  items={therapyList}
                  keyField="value"
                  textField="label"
                  checked={selectedEveningTherapy}
                  label="Therapy"
                  setChecked={(values) => setSelectedEveningTherapy(values)}
                  required={true}
                />
              </div>
            </div>
          </div>
          <br></br>
          <div className="select-diet">
            <h1 style={{ fontWeight: "900", fontSize: "30px" }}>Diet Plan</h1>
            <div className="data-row">
              <div className="select-container">
                <label className="data-label">
                  Diet Plan Name <span className="required">*</span>
                </label>
                <div>
                  <Select
                    options={activeDietPlanList}
                    onChange={handleDietPlanChange}
                    value={dietPlanName}
                    name="dietPlan"
                    id="dietPlan"
                    className="dietplan_dropdown"
                    styles={customStyles}
                  />
                </div>
                {selectDietPlanError && (
                  <p className="error-message">{selectDietPlanError}</p>
                )}
              </div>
              <div style={{ display: "flex" }}>
                <div className="date-picker-container">
                  <label className="data-label">
                    Diet Plan Start Date <span className="required">*</span>
                  </label>
                  <DatePicker
                    value={dietPlanStartDate}
                    onChange={handleDietPlanStartDateChange}
                    format="DD-MM-YYYY"
                    placeholder="Start Date"
                    minDate={today}
                    className="date-picker"
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      borderColor: "#ccc",
                      height: "46px",
                    }}
                  />
                  {dietPlanStartDateError && (
                    <p className="error-message">{dietPlanStartDateError}</p>
                  )}
                </div>

                <div className="date-picker-container">
                  <label className="data-label">
                    Diet Plan End Date <span className="required">*</span>
                  </label>
                  <DatePicker
                    value={dietPlanEndDate}
                    onChange={handleDietPlanEndDateChange}
                    format="DD-MM-YYYY"
                    placeholder="End Date"
                    minDate={
                      dietPlanStartDate
                        ? moment(dietPlanStartDate, "DD-MM-YYYY").toDate()
                        : new Date()
                    }
                    className="date-picker"
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      borderColor: "#ccc",
                      height: "46px",
                    }}
                  />
                  {dietPlanEndDateError && (
                    <p className="error-message">{dietPlanEndDateError}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="meal-inputs-container">
              <div className="input-row">
                <h1>Breakfast</h1>
                <input
                  id="prebreakfast"
                  placeholder="Pre Breakfast"
                  className="meal-input"
                  value={mealInputs.prebreakfast}
                  onChange={handleMealInputChange}
                />
                <input
                  id="postbreakfast"
                  placeholder="Post Breakfast"
                  className="meal-input"
                  value={mealInputs.postbreakfast}
                  onChange={handleMealInputChange}
                />
              </div>

              <div className="input-row">
                <h1>Lunch</h1>
                <input
                  id="prelunch"
                  placeholder="Pre Lunch"
                  className="meal-input"
                  value={mealInputs.prelunch}
                  onChange={handleMealInputChange}
                />
                <input
                  id="postlunch"
                  placeholder="Post Lunch"
                  className="meal-input"
                  value={mealInputs.postlunch}
                  onChange={handleMealInputChange}
                />
              </div>

              <div className="input-row">
                <h1>Dinner</h1>
                <input
                  id="predinner"
                  placeholder="Pre Dinner"
                  className="meal-input"
                  value={mealInputs.predinner}
                  onChange={handleMealInputChange}
                />
                <input
                  id="postdinner"
                  placeholder="Post Dinner"
                  className="meal-input"
                  value={mealInputs.postdinner}
                  onChange={handleMealInputChange}
                />
              </div>
            </div>
          </div>

          <div className="submit-container">
            <button className="submit-button" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
        <div className="guest-diet-schedule">
          {["today", "tomorrow", "dayAfterTomorrow"].map((dayKey) => (
            <div className="datewise-guest-treatment" key={dayKey}>
              <div className="selected-date-treatment">
                <p>
                  Date:{" "}
                  {
                   
                    scheduleData[dayKey].therapies &&
                    scheduleData[dayKey].therapies[0]?.date
                      ? moment(scheduleData[dayKey].therapies[0]?.date).format(
                          "DD/MM/YYYY"
                        )
                      : "N/A"
                  }
                </p>

                <p>
                 <b>Morning Therapy:</b> {" "}
                  {scheduleData[dayKey].therapies?.find(
                    (t) => t.name === "Morning Therapy"
                  )?.details || "N/A"}
                </p>
                <p>
                  <b>Evening Therapy:</b>{" "}
                  {scheduleData[dayKey].therapies?.find(
                    (t) => t.name === "Evening Therapy"
                  )?.details || "N/A"}
                </p>
                <p>
                 <b>Diet Plan:</b> {" "}
                  {scheduleData[dayKey].dietPlan
                    ? `${scheduleData[dayKey].dietPlan.dietPlanName}`
                    : "N/A"}
                </p>
                <p>
                 <b>Breakfast:</b> {" "}
                  {scheduleData[dayKey].dietPlan
                    ? `${scheduleData[dayKey].dietPlan.preBreakfast} / ${scheduleData[dayKey].dietPlan.postBreakfast}`
                    : "N/A"}
                </p>
                <p>
                  <b>Lunch:</b>{" "}
                  {scheduleData[dayKey].dietPlan
                    ? `${scheduleData[dayKey].dietPlan.preLunch} / ${scheduleData[dayKey].dietPlan.postLunch}`
                    : "N/A"}
                </p>
                <p>
                  <b>Dinner:</b>{" "}
                  {scheduleData[dayKey].dietPlan
                    ? `${scheduleData[dayKey].dietPlan.preDinner} / ${scheduleData[dayKey].dietPlan.postDinner}`
                    : "N/A"}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-box">
            <div
              className="select-slot"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h2>Select a Timeslot </h2>
              <p onClick={togglePopup}>X</p>{" "}
            </div>

            <div className="therapy-selection">
              <div className="therapy-column left">
                <h3>Morning Therapy</h3>
                <ul>
                  {morningTherapySlots.map((slot) => (
                    <li key={slot.GuestActivityTimingSlotsId}>
                      <label>
                        <input
                          type="radio"
                          name="morningTimeSlot"
                          value={slot.GuestActivityTimingSlotsId}
                          checked={
                            selectedMorningSlot?.GuestActivityTimingSlotsId ===
                            slot.GuestActivityTimingSlotsId
                          }
                          onChange={() => handleSelectMorningSlot(slot)}
                        />
                        {slot.TimeSlot}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Evening Therapy Slots - Right Side */}
              <div className="therapy-column right">
                <h3>Evening Therapy</h3>
                <ul>
                  {eveningTherapySlots.map((slot) => (
                    <li key={slot.GuestActivityTimingSlotsId}>
                      <label>
                        <input
                          type="radio"
                          name="eveningTimeSlot"
                          value={slot.GuestActivityTimingSlotsId}
                          checked={
                            selectedEveningSlot?.GuestActivityTimingSlotsId ===
                            slot.GuestActivityTimingSlotsId
                          }
                          onChange={() => handleSelectEveningSlot(slot)}
                        />
                        {slot.TimeSlot}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              <Multiselect
                options={dynamicEmployees.map((employee) => ({
                  value: employee.EmployeeMasterId,
                  label: employee.EmployeeName,
                }))}
                selectedValues={selectedEmployees}
                onSelect={handleEmployeeChange}
                onRemove={handleEmployeeChange}
                displayValue="label"
                placeholder="Select Therapist"
              />
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  name="applyToAllDays"
                  checked={applyToAllDynamic} // Bind the state to checkbox
                  onChange={handleCheckboxChange} // Handle checkbox change
                />
                Apply to all days
              </label>
            </div>

            <div className="buttons">
              <button
                onClick={handleSubmitTimeSlot}
                disabled={!selectedMorningSlot || !selectedEveningSlot}
                style={{
                  marginTop: "20px",
                  padding: "10px 20px",
                  cursor: "pointer",
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
