import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import callApi from "../../ApiCall";
import { toast, Zoom } from "react-toastify";
import Constants from "../../Constants";

const TreatmentsPopup = ({ onClose, isAddMode, selectedTherapy }) => {

  const [showPopup, setShowPopup] = useState(true);

  const [chargesError, setChargesError] = useState("");
  const [status, setStatus] = useState(
    selectedTherapy
      ? selectedTherapy.Chargable
        ? "Chargable"
        : "InPackage"
      : "Chargable"
  );

  // Function to check if Treatment name exists
  const checkTreatmentsExists = async (
    therapyName,
    currentTherapyName = null
  ) => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getTherapy,
      });

      if (response?.success) {
        const { therapyList = [] } = response.data;

        const therapyExists = therapyList.some(
          (plan) =>
            plan.TherapyName.toLowerCase() === therapyName.toLowerCase() &&
            plan.TherapyName !== currentTherapyName
        );

        return therapyExists;
      } else {
        console.error("Error fetching Treatment data:", response.error);
        return false;
      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;
    }
  };

  const handleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setStatus(newStatus);
      setChargesError("");
    }
  };

  // Function to handle form submission
  const handleSubmit = async (values, { resetForm }) => {
    values.chargable = status === "Chargable" ? 1 : 0;

    if (status === "Chargable" && !values.charges) {
      setChargesError("Charges is required.");
      return; // Exit early if charges are not provided
    } else {
      setChargesError(""); // Clear error if charges are valid
    }

    if (isAddMode) {
      const therapyExists = await checkTreatmentsExists(
        values.therapyName
      );

      if (therapyExists) {
        const actionMessage = "Therapy Name Already Exists";
        toast.error(
          <div className="custom_ErrorToast">
            <h5>{actionMessage}</h5>
          </div>,
          {
            theme: "colored",
            hideProgressBar: true,
            transition: Zoom,
            style: {
              width: "250px",
              fontSize: "12px",
              background: "--alert-status-error",
              color: "#fff",
            },

            autoClose: 1500,
            closeButton: true,
          }
        );
        return;
      }

      try {
     

        const response = await callApi({
          apiURL: Constants.API.addTherapy,
          requestBody: {
            therapyName: values.therapyName,
            therapyDescription: values.therapyDescription,
            charges: values.charges,
            chargable: values.chargable,
          },
        });
        

        if (response?.success) {
        
          const actionMessage = "Added new Therapy";
          toast.success(
            <div className="custom_Toast">
              <h5>{actionMessage}</h5>
              Therapy has been added successfully
            </div>,
            {
              position: toast.POSITION.TOP_CENTER,
              theme: "colored",
              hideProgressBar: true,
              transition: Zoom,
              style: {
                width: "300px",

                background: "#0ABE75",
                color: "#fff",
              },
              autoClose: 1500,
              closeButton: true,
              onClose: () => {
                setShowPopup(false);
                onClose();
              },
            }
          );
          onClose();
          resetForm();
        } else {
          console.error("Error response from API:", response);
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }
    } else {
      // Update mode
      const isEditing = !!selectedTherapy;

      const hasTreatmentNameChanged =
        isEditing && selectedTherapy.therapyName !== values.therapyName;

      if (hasTreatmentNameChanged) {
        const therapyExists = await checkTreatmentsExists(
          values.therapyName,
          isEditing ? selectedTherapy.TherapyName : null
        );

        if (therapyExists) {
          const actionMessage = "Therapy Name Already Exists";

          toast.error(
            <div className="custom_ErrorToast">
              <h5>{actionMessage}</h5>
            </div>,
            {
              theme: "colored",
              hideProgressBar: true,
              transition: Zoom,
              style: {
                width: "250px",
                fontSize: "12px",
                background: "--alert-status-error",
                color: "#fff",
              },

              autoClose: 1500,
              closeButton: true,
            }
          );
          return;
        }
      }
      try {
        const updateResponse = await callApi({
          apiURL: Constants.API.updateTherapy,

          requestBody: {
            therapyId: selectedTherapy?.TherapyId,
            therapyName: values.therapyName,
            chargable: values.chargable,
            charges: values.charges,
            therapyDescription: values.therapyDescription,
            updatedDate: selectedTherapy?.UpdatedDate,
          },
        });
        console.log("API Response (Update):", updateResponse);

        if (updateResponse?.success) {
          const actionMessage = "Updated Therapy";
          toast.success(
            <div className="custom_Toast">
              <h5>{actionMessage}</h5>
              Therapy has been updated successfully
            </div>,
            {
              position: toast.POSITION.TOP_CENTER,
              theme: "colored",
              hideProgressBar: true,
              transition: Zoom,
              className: "toast_pop",
              autoClose: 1500,
              closeButton: true,
              onClose: () => {
                setShowPopup(false);
                onClose();
              },
            }
          );
        } else {
          console.error("Error response from API (Update):", updateResponse);
        }
      } catch (error) {
        console.error("Error during API call (Update):", error);
      }
    }

    resetForm();
    onClose();
  };

  const initialValuesFormik = {
    therapyName: isAddMode ? "" : selectedTherapy?.TherapyName,
    therapyDescription: isAddMode
      ? ""
      : selectedTherapy?.TherapyDescription,
    chargable: selectedTherapy ? (selectedTherapy?.Chargable ? 1 : 0) : 1,

    charges: isAddMode ? "" : selectedTherapy?.Charges,
  };

  const validationSchemaFormik = Yup.object({
    // charges: Yup.number().required("Charges is required"),
    therapyName: Yup.string()
      .required("Therapy name is required")
      .max(40, "Max 40 Characters"),
    therapyDescription: Yup.string().required(
      "Therapy description is required"
    ), // Fix this
  });

  return (
    <>
      <div className="Overlay" />
      <div
        className={
          showPopup ? "Treatments_PopMain" : "Treatments_PopMain hidden"
        }
      >
        <div className="Treatments_First">
          <p>{isAddMode ? "Add Therapy" : "Update Therapy"}</p>
          <button onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#424242"
              />
            </svg>
          </button>
        </div>
        <Formik
          initialValues={initialValuesFormik}
          validationSchema={validationSchemaFormik}
          onSubmit={handleSubmit}
        >
          <Form className="Treatments-form">
            {/* Treatment Name and Description Fields Side by Side */}
            <div className="Treatments_Name">
              <label htmlFor="treatmentName" className="Treatments-label">
                Therapy Name <sup>*</sup>
              </label>
              <Field
                type="text"
                id="therapyName"
                name="therapyName"
                placeholder="Enter Therapy Name"
                className="custom-field"
              />
              <div className="error">
                <ErrorMessage name="therapyName" />
              </div>
            </div>

            <div className="Treatments_Name">
              <label
                htmlFor="treatmentDescription"
                className="Treatments-label"
              >
                Therapy Description <sup>*</sup>
              </label>
              <Field
                as="textarea"
                id="therapyDescription"
                name="therapyDescription"
                placeholder="Enter Therapy Description"
                className="custom-field"
                style={{ height: "120px", resize: "none" }}
              />
              <div className="error">
                <ErrorMessage name="therapyDescription" />
              </div>
            </div>
            <div className="treatmentfield_container">
              <div className="fields-wrapper">
                <div className="serviceActivity_Togglebutton-1">
                  <ToggleButtonGroup
                    value={status}
                    exclusive
                    onChange={handleStatusChange}
                    className="serviceActivity_ToggleButtonGroups-1"
                    sx={{ fontSize: "14px" }}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value="Chargable"
                      aria-label="Chargable"
                      style={{ borderRadius: "8px 0px 0px 8px" }}
                    >
                      Chargable
                    </ToggleButton>
                    <ToggleButton
                      value="InPackage"
                      aria-label="InPackage"
                      style={{ borderRadius: "0px 8px 8px 0px" }}
                    >
                      Inpackage
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>

                <div className="Treatments_Name">
                  <label htmlFor="charges" className="Treatments-label">
                    Charges {status === "Chargable" && <sup>*</sup>}
                  </label>
                  <Field
                    type="text"
                    id="charges"
                    name="charges"
                    placeholder="Enter Charges"
                    className="custom-field"
                  />
                  <div className="error">
                    {chargesError && (
                      <p className="error-message">{chargesError}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="Treatments_End">
              <button type="submit" className="Treatments_buttonb">
                {isAddMode ? "Add New" : "Update"}
              </button>
              &nbsp;&nbsp;&nbsp;
              <button className="Treatments_buttona" onClick={onClose}>
                Cancel
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default TreatmentsPopup;
