import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./common/MainHeader/PrivateRoute";
import ScrollToTop from "./components/Scrolltotop";
import UserCreateAccount from "./modules/User/CreateAccount/UserCreateAccount";
import UserLogin from "./modules/User/UserLogin/UserLogin";
import BlogPage from "./modules/Website/Blog/Blog";
import BlogInfo from "./modules/Website/BlogInfo/BlogInfo";
import Contact from "./modules/Website/Contact/Contact";
import Home from "./modules/Website/Home/Home";
import Pricing from "./modules/Website/Home/Pricing/Pricing";
import Steps from "./modules/Website/Home/Steps/Steps";
import Footer from "./modules/Website/footer/Footer";
import Navbar from "./modules/Website/header/header";
import DashboardScreen from "./modules/Dashboard/dashboardScreen";
import PropertyOnboarding from "./modules/PropertyOnboarding/PropertyDetail/PropertyOnboarding";
import AboutProperty from "./modules/PropertyOnboarding/AboutProperty/AboutProperty";
import AddRoomType from "./modules/PropertyOnboarding/AddRoomType/AddRoomType";
import AddRoomRate from "./modules/PropertyOnboarding/AddRoomRate/AddRoomRate";
import AddServices from "./modules/PropertyOnboarding/AddServices/AddServices";
import Guest from "./modules/Guest/Guest";
import Reservation from "./modules/Reservation/Reservation";
import MenuMaster from "./modules/MenuMaster/MenuMaster";
import PaymentSuccess from "./modules/Subscription/PaymentSuccess/PaymentSuccess";
import PaymentCancel from "./modules/Subscription/PaymentCancel/PaymentCancel";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GuestActivityMaster from "./modules/GuestActivityMaster/GuestActivityMaster";
import PaymentComponent from "./modules/Subscription/Payment";
import EmployeeMaster from "./modules/EmployeeMaster/EmployeeMaster";
import ServiceMaster from "./modules/ServiceMaster/ServiceMaster";
import RoomMaster from "./modules/RoomMaster/RoomMaster";
import PropertyMaster from "./modules/Property/PropertyMaster";
import Category from "./modules/Category/Category";
import PropertyLocation from "./modules/PropertyLocation/PropertyLocation";
import Designation from "./modules/Designation/Designation";
import Department from "./modules/Department/Department";
import Leave from "./modules/Leave/Leave";
import LeaveBalance from "./modules/LeaveBalance/LeaveBalance";
// import Item from "./modules/Item/Item";
// import ItemRequest from "./modules/ItemRequest/ItemRequestMaster";
import ItemRequest from "./modules/ItemRequest/ItemRequest";
import Item from "./modules/Item/Item";

import DietPlanMaster from "./modules/DietPlanMaster/DietPlanMaster";
import GuestDietPlan from "./modules/GuestDietPlan/GuestDietPlan";
import Treatments from "./modules/Treatments/Treatments";
import DailyScheduleSummary from "./modules/DailyScheduleSummary/DailyScheduleSummary";
import Approval from "./modules/Approval/Approval";
import Sitemap from "./modules/Website/sitemap";
import Billing from "./modules/Billing/Billing";

function App() {
  const WebsitePages = ({ component: Component }) => {
    return (
      <>
        <Navbar />
        <Component />
        <Footer />
      </>
    );
  };

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<WebsitePages component={Home} />} />
        <Route path="/blog" element={<WebsitePages component={BlogPage} />} />
        <Route path="/contact" element={<WebsitePages component={Contact} />} />
        <Route path="/sitemap" element={<WebsitePages component={Sitemap} />} />
        <Route
          path="/blog/:title_slug"
          element={<WebsitePages component={BlogInfo} />}
        />
        <Route path="/features" element={<WebsitePages component={Steps} />} />
        {/* <Route path="/pricing" element={<WebsitePages component={Pricing} />} /> */}
        <Route path="/login" element={<UserLogin />} />
        <Route path="/register" element={<UserCreateAccount />} />
        <Route path="/payment" element={<PaymentComponent />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-cancel" element={<PaymentCancel />} />
        <Route
          path="/dashboard"
          element={<PrivateRoute component={DashboardScreen} />}
        />
        <Route
          path="/onboading/property"
          element={<PrivateRoute component={PropertyOnboarding} />}
        />
        <Route
          path="/onboading/about-property"
          element={<PrivateRoute component={AboutProperty} />}
        />
        <Route
          path="/onboading/add-room-type"
          element={<PrivateRoute component={AddRoomType} />}
        />

        <Route
          path="/onboading/add-room-rate"
          element={<PrivateRoute component={AddRoomRate} />}
        />
        <Route
          path="/onboading/add-room-services"
          element={<PrivateRoute component={AddServices} />}
        />
        <Route
          path="/roles"
          element={<PrivateRoute component={MenuMaster} />}
        />
        <Route
          path="/reservation"
          element={<PrivateRoute component={Reservation} />}
        />
        <Route path="/guest" element={<PrivateRoute component={Guest} />} />
        <Route
          path="guestactivity"
          element={<PrivateRoute component={GuestActivityMaster} />}
        />
        <Route
          path="employees"
          element={<PrivateRoute component={EmployeeMaster} />}
        />
        <Route
          path="/category"
          element={<PrivateRoute component={Category} />}
        />
        <Route path="/item" element={<PrivateRoute component={Item} />} />
        <Route
          path="/dietplan"
          element={<PrivateRoute component={DietPlanMaster} />}
        />
        <Route
          path="/guestdietplan"
          element={<PrivateRoute component={GuestDietPlan} />}
        />
        <Route
          path="/therapy"
          element={<PrivateRoute component={Treatments} />}
        />
        <Route
          path="/dailyschedulesummary"
          element={<PrivateRoute component={DailyScheduleSummary} />}
        />
        <Route path="/leave" element={<PrivateRoute component={Leave} />} />
        <Route
          path="/leavebalance"
          element={<PrivateRoute component={LeaveBalance} />}
        />
        <Route
          path="/propertylocation"
          element={<PrivateRoute component={PropertyLocation} />}
        />
        <Route
          path="/properties"
          element={<PrivateRoute component={PropertyMaster} />}
        />
        <Route
          path="/rooms"
          element={<PrivateRoute component={RoomMaster} />}
        />
        <Route
          path="designation"
          element={<PrivateRoute component={Designation} />}
        />
        <Route
          path="services"
          element={<PrivateRoute component={ServiceMaster} />}
        />
        <Route
          path="department"
          element={<PrivateRoute component={Department} />}
        />
       
        <Route
          path="/requestitem"
          element={<PrivateRoute component={ItemRequest} />}
        />
         <Route
          path="/approval"
          element={<PrivateRoute component={Approval} />}
        />
        <Route
          path="/billing"
          element={<PrivateRoute component={Billing} />}
        />
        <Route path="*" element={<Navigate to={"/login"} />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
