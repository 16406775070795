import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Constants from "../../Constants";
import callApi from "../../ApiCall";

const Sitemap = () => {
  const [blogsData, setBlogsData] = useState([]);

  const getBlogs = async () => {
    const response = await callApi({
      apiURL: Constants.API.getBlogs,
    });
    if (response?.success) {
      setBlogsData(response.data.blogsList);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);


  return (
    <div className="sitemap-container">
      <header className="sitemap-header">
        <h1>Sitemap</h1>
      </header>

      <div className="sitemap-content">
        <h2 className="sitemap-section-title">Website</h2>
        <ul className="sitemap-list">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/features">Features</Link>
          </li>
          <li>
            <Link to="/pricing">Pricing</Link>
          </li>
        </ul>

        <h2 className="sitemap-section-title">Blogs</h2>
        <ul className="sitemap-list">
          {blogsData?.map((item) => {
            return (  // Return the JSX element here
              <li key={item.blog_id}>
                <Link to={`/blog/${item.title_slug}`}>{item.title}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Sitemap;
