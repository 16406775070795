import moment from "moment";
let appConfig = {
  dateFormatForGrid: "DD-MMM-YYYY",
  dateFormat: "DD/MM/YYYY",
  dateFormatForPicker: "dd-MMM-yyyy",
  dbDateFormat: "YYYY-MM-DD",
  dbDateTimeFormat: "YYYY-MM-DD HH:mm:ss",
  dateTimeFormatForPicker: "dd-MMM-yyyy HH:mm:ss",
  dateTimeFormatForPrintStamp: "DD-MMM-YYYY, HH:mm:ss A",
};

export const getDBFormateDate = (d) => {
  return moment(d).format(appConfig.dbDateFormat);
};

export const getDisplayDate = (d) => {
  // return moment(d).format(appConfig.dateFormatForGrid);
  return moment(d).format(appConfig.dateFormatForGrid);
};


export const convertDateInDBFormateDate = (d) => {
  return moment(new Date(d).toISOString().split("T")[0]).format(
    appConfig.dbDateFormat
  );
};
