import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "./BookingHistory.css";
import { Pagination } from "@mui/material";
import { PaginationItem } from "@mui/material";
import moment from "moment"; // import moment library

import callApi from "../../../ApiCall";
import Constants from "../../../Constants";

const columns = [
  { id: "date", label: "Booking Date", minWidth: 100 },
  { id: "roomNumber", label: "Room Number", minWidth: 100 },
  { id: "totalMember", label: "Total Member", minWidth: 100 },
  { id: "checkIn", label: "Check In", minWidth: 100 },
  { id: "checkOut", label: "Check Out", minWidth: 100 },
];

export default function BookingHistory({ GuestMasterId }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [selectedGuestBookingHistory, setSelectedGuestBookingHistory] =
    useState([]);

  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const getSelectedGuestBookingHistory = async (guestMasterId) => {
    try {
      const response = await callApi({
        apiURL: Constants.API.selectedGuestBookingHistory,
        requestBody: { guestMasterId: guestMasterId },
      });
      if (response?.success) {
        setSelectedGuestBookingHistory(
          response.data.selectedGuestBookingHistory
        );
        console.log("Booking Response :", response);
      } else {
        console.log("Error:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  useEffect(() => {
    setSelectedGuestBookingHistory([]);
    if (GuestMasterId) {
      getSelectedGuestBookingHistory(GuestMasterId);
    }
  }, [GuestMasterId]);

  return (
    <div className="Grid">
      <TableContainer style={{ maxHeight: 400, width: "95%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  className="tableHeaderCell"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedGuestBookingHistory
              .slice((currentPage - 1) * pageSize, currentPage * pageSize)
              .map((row) => (
                <TableRow key={row.BookingMasterId}>
                  <TableCell align="left" className="tableBodyCell">
                    {moment(row.CheckInDate).format("DD-MM-YY")}{" "}
                    {/* Format CheckInDate */}
                  </TableCell>
                  <TableCell align="left" className="tableBodyCell">
                    {row.RoomNumber}
                  </TableCell>
                  <TableCell align="left" className="tableBodyCell">
                    {row.NumberOfGuests}
                  </TableCell>
                  <TableCell align="left" className="tableBodyCell">
                    {moment(row.CheckInDate).format("DD-MM-YY")}{" "}
                    {/* Format CheckInDate */}
                  </TableCell>
                  <TableCell align="left" className="tableBodyCell">
                    {moment(row.CheckOutDate).format("DD-MM-YY")}{" "}
                    {/* Format CheckOutDate */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={Math.ceil(selectedGuestBookingHistory.length / pageSize)}
        page={currentPage}
        hideNextButton
        hidePrevButton
        onChange={(event, value) => setCurrentPage(value)}
        style={{
          paddingRight: "5%",
        }}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            page={formattedPageNumber(item.page)}
            className={item.selected ? "pagination-selected" : ""}
          />
        )}
      />
    </div>
  );
}
