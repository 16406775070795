import React from "react";
import * as XLSX from "xlsx";
import "./Department.css";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteConfirmationDialog from "../../common/DeleteConfirmation/confirmdelete";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ExportFile from "../../common/ExportFile/ExportFile";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import callApi from "../../ApiCall";
import DepartmentPopUp from "./DepartmentPopUp";
import { toast, Zoom } from "react-toastify";
import trash from "../../assets/icons/trash.svg";
import edit from "../../assets/icons/edit.svg";
import Constants from "../../Constants";
import SearchBar from "../../common/SearchBar/SearchBar";
import AddButton from "../../common/Button/Button";
import { Grid } from "@material-ui/core";

const MenuItemWrapper = styled("li")(() => ({
  padding: "8px 8px",
  boxShadow: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F75555",
    color: "#FFFFFF",
    img: {
      filter:
        "invert(92%) sepia(4%) saturate(8%) hue-rotate(126deg) brightness(138%) contrast(200%)",
    },
  },
  transition: "all 0.3s ease",
}));

const IconWrapper = styled("span")(() => ({
  fontSize: "16px",
  marginRight: "8px",
  "& .MuiSvgIcon-root": {
    fontSize: "inherit",
  },
}));

const Department = (props) => {
  const columns = [
    { field: "id", headerName: "Sr No", flex: 1 },
    {
      field: "DepartmentName",
      headerName: "Department Name",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            color: params.row.status === "Active" ? "#0ABE75" : "#F75555",
          }}
        >
          {params.row.status}
        </div>
      ),
    },
    props.currentMenu.Edit || props.currentMenu.Delete
      ? {
          field: "actions",
          headerName: "Actions",
          flex: 1,
          renderCell: (params) => (
            <div>
              <IconButton
                color="primary"
                aria-label="actions"
                onClick={(event) => handleMenuOpen(event, params.row)}
              >
                <MoreVertIcon style={{ color: "gray" }} />
              </IconButton>
              <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                sx={{
                  "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                    {
                      boxShadow: "none",
                      backgroundColor: "#EEE",
                      borderRadius: "8px",
                    },
                  "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                    paddingTop: "0",
                    paddingBottom: "0",
                  },
                }}
              >
                {props.currentMenu.Edit ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("edit")}>
                    <IconWrapper>
                      <img src={edit} />
                    </IconWrapper>{" "}
                    <span style={{ fontWeight: "400" }}>Edit</span>
                  </MenuItemWrapper>
                ) : null}

                {props.currentMenu.Delete ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("delete")}>
                    <IconWrapper>
                      <img src={trash} />
                    </IconWrapper>{" "}
                    <span style={{ fontWeight: "400" }}>Delete</span>
                  </MenuItemWrapper>
                ) : null}
              </Menu>
            </div>
          ),
        }
      : {},
  ];

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedDepartmentForDelete, setSelectedDepartmentForDelete] =
    useState(null);
  const [selectedDepartmentForUpdate, setSelectedDepartmentForUpdate] =
    useState(null);
  const [showDepartmentActivityPopup, setShowDepartmentActivityPopup] =
    useState(false);
  const [isAddMode, setIsAddMode] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [page, setPage] = useState(1);

  const [departmentdata, setdepartmentdata] = useState([]);

  const handleDeleteClick = (selectedRow) => {
    setSelectedDepartmentForDelete(selectedRow);
    setShowDeleteConfirmation(true);
  };

  // Delete API
  const handleConfirmDelete = async (selectedRow) => {
    try {
      const params = {
        departmentId: selectedRow.DepartmentId,
        updatedDate: selectedRow.UpdatedDate,
      };

      const response = await callApi({
        apiURL: Constants.API.deleteDepartment,
        requestBody: params,
      });
      if (response?.success) {
        const actionMessage = "Deleted Department";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            Department has been Deleted successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            transition: Zoom,
            hideProgressBar: true,
            className: "toast_pop",
            autoClose: 1500,
            closeButton: true,
            onClose: () => {
              getDepartment();
            },
          }
        );
      } else {
        console.error("Error deleting plan:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setShowDeleteConfirmation(false);
      setPage(1);
    }
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedDepartmentForUpdate(row);
    setSelectedDepartmentForDelete(row);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleMenuSelect = (option) => {
    if (option === "edit") {
      handleUpdateClick(selectedDepartmentForUpdate);
      handleMenuClose();
    } else if (option === "delete") {
      handleDeleteClick(selectedDepartmentForDelete);
    }
  };

  const handleUpdateClick = (selectedRow) => {
    setSelectedDepartmentForUpdate(selectedRow);
    setIsAddMode(false);
    setShowDepartmentActivityPopup(true);
  };

  const handleAddDepartmentClick = () => {
    setIsAddMode(true);
    setShowDepartmentActivityPopup(true);
  };

  const handleDepartmentActivityPopupClose = async () => {
    setShowDepartmentActivityPopup(false);
    setIsAddMode(true);
    setSelectedDepartmentForUpdate(null);
    getDepartment();
  };

  // Get API to get all data
  const getDepartment = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getDepartment,
      });
      if (response?.success) {
        const { departmentList = [] } = response.data;
        const mappedData = departmentList.map((item, index) => ({
          id: index + 1,
          ...item,
          status: item.Active === 1 ? "Active" : "Inactive",
        }));
        setdepartmentdata(mappedData);
      } else {
        console.error("Error fetching designation data:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  useEffect(() => {
    getDepartment();
  }, []);

  //export to xl
  const handleExportClick = (departmentdata) => {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      const fileName = `Department${formattedDate.replace(/ /g, " ")}.xlsx`;
      const worksheetName = `Department`;

      if (departmentdata?.length > 0) {
        const exportData = departmentdata.map((row) => ({
          "Sr No": row.id,
          "Department Name": row.DepartmentName,
          Status: row.status,
        }));
        const workSheet = XLSX.utils.json_to_sheet(exportData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, worksheetName);
        XLSX.writeFile(workBook, fileName);
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF("p");
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    const fileName = `Department${formattedDate.replace(/ /g, "")}.pdf`;

    const tableHeaders = ["Sr No", "Department Name", "Status"];

    if (departmentdata.length > 0) {
      let tableData = departmentdata.map((row) => {
        return [row.id, row.DepartmentName, row.status];
      });

      // Add title
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("Department", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });
      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
        theme: "grid",
      });
      // Add date and time
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        formattedDate,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );
      doc.save(fileName);
    } else {
      // showToast("ERROR", "No Data To Export");
    }
  };

  // Paginatio, searching and sorting
  const initialSortedRows = departmentdata;
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortedRows, setSortedRows] = useState(initialSortedRows);
  const pageSize = 10;
  const totalPages = Math.ceil(sortedRows.length / pageSize);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };

  const handleSortClick = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };

  const renderArrowIcon = (column) => {
    if (sortedColumn === column) {
      return (
        <span className="arrow-icon">
          {sortOrder === "asc" ? (
            <i className="fa-solid fa-arrow-up" />
          ) : (
            <i className="fa-solid fa-arrow-down" />
          )}
        </span>
      );
    }
    return null;
  };

  useEffect(() => {
    if (departmentdata.length > 0) {
      setSortedRows(departmentdata);
    }
  }, [departmentdata.length > 0]);

  useEffect(() => {
    if (sortedColumn) {
      const sorted = departmentdata.slice().sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];
        if (aValue < bValue) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedRows(sorted);
    }
  }, [sortedColumn, sortOrder, departmentdata]);

  //searcH
  const [filtering, setFiltering] = useState("");
  const handleSearchfilter = (event) => {
    setFiltering(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    const searched = departmentdata.filter((item) => {
      if (!filtering) {
        return true;
      } else {
        return item.DepartmentName.toLowerCase().includes(
          filtering.toLowerCase()
        );
      }
    });
    setSortedRows(searched);
  }, [filtering, departmentdata]);

  const paginatedRows = sortedRows.slice(startIndex, endIndex);
  return (
    <>
      {showDepartmentActivityPopup && (
        <DepartmentPopUp
          onClose={handleDepartmentActivityPopupClose}
          isAddMode={isAddMode}
          selectedDepartment={selectedDepartmentForUpdate}
          getDepartment={getDepartment}
        />
      )}
      <div className="propActivity_MainPae">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <SearchBar value={filtering} onChange={handleSearchfilter} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ExportFile
              handleExportClick={handleExportClick}
              filteredRows={departmentdata}
              handleExportPDF={handleExportPDF}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            {props?.currentMenu?.Add ? (
              <>
                <AddButton
                  buttonText={"Add Department"}
                  onClick={handleAddDepartmentClick}
                />
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>

        <div className="propActivityTable">
          <div className="Table">
            <DeleteConfirmationDialog
              open={showDeleteConfirmation}
              handleClose={() => {
                setShowDeleteConfirmation(false);
                handleMenuClose();
              }}
              handleConfirm={() => {
                handleConfirmDelete(selectedDepartmentForDelete);
                handleMenuClose();
              }}
              product={"Department"}
            />
            <div className="department_DataGrid">
              <div className="propActivity-table">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell
                            key={i}
                            className="tableHeaderCell"
                            onClick={() => handleSortClick(column.field)}
                          >
                            {column.headerName}
                            {renderArrowIcon(column.field)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedRows.map((row, i) => (
                        <TableRow key={i}>
                          {columns.map((column, ind) => (
                            <TableCell key={ind} className="tableBodyCell">
                              {column.renderCell
                                ? column.renderCell({ row })
                                : row[column.field]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={totalPages}
                  page={page}
                  hideNextButton
                  hidePrevButton
                  onChange={(event, value) => setPage(value)}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      page={formattedPageNumber(item.page)}
                      className={item.selected ? "pagination-selected" : ""}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Department;
