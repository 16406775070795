import { MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";

import ExportFile from "../../common/ExportFile/ExportFile";
import AddButton from "../../common/Button/Button";
import SearchBar from "../../common/SearchBar/SearchBar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import edit from "../../assets/icons/edit.svg";
import trash from "../../assets/icons/trash.svg";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import callApi from "../../ApiCall";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Constants from "../../Constants";
import "./Category.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import CategoryPopup from "./CategoryPopup";
import { Flex } from "antd";
import colors from "react-multi-date-picker/plugins/colors";
import DeleteConfirmationDialog from "../../common/DeleteConfirmation/confirmdelete";
import PaginationItem from "@mui/material/PaginationItem";
import Pagination from "@mui/material/Pagination";
import { Grid } from "@material-ui/core";

//  For edit and delete icon click
const MenuItemWrapper = styled("li")(() => ({
  padding: "8px 8px",
  boxShadow: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F75555",
    color: "#FFFFFF",
    img: {
      filter:
        "invert(92%) sepia(4%) saturate(8%) hue-rotate(126deg) brightness(138%) contrast(200%)",
    },
  },
  transition: "all 0.3s ease",
}));

//  For edit and delete icons
const IconWrapper = styled("span")(() => ({
  fontSize: "16px",
  marginRight: "8px",
  "& .MuiSvgIcon-root": {
    fontSize: "inherit",
  },
}));

const Category = (props) => {
  const [showCategoryPopup, setshowCategoryPopup] = useState(false);
  const [isAddMode, setIsAddMode] = useState(true);
  const [selectedCategoryForUpdate, setselectedCategoryForUpdate] =
    useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [categoryData, setcategoryData] = useState([]); //daattttaaaaaa
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const initialSortedRows = categoryData;
  const [sortedRows, setSortedRows] = useState(initialSortedRows);
  const [selectedCategoryForDelete, setselectedCategoryForDelete] =
    useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);

  const pageSize = 10;
  const totalPages = Math.ceil(sortedRows.length / pageSize);
  const [page, setPage] = useState(1);

  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };

  // categorycolumns
  const columns = [
    { field: "categoryId", headerName: "Sr No", flex: 1 },
    { field: "CategoryName", headerName: "Category Name", flex: 1 },

    {
      field: "CategoryDescription",
      headerName: "Category   Description",
      flex: 1,
    },
    props.currentMenu.Edit || props.currentMenu.Delete
      ? {
          field: "actions",
          headerName: "Actions",
          flex: 1,
          renderCell: (params) => (
            <div>
              <IconButton
                color="primary"
                aria-label="actions"
                onClick={(event) => handleMenuOpen(event, params.row)}
              >
                <MoreVertIcon style={{ color: "gray" }} />
              </IconButton>
              <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                sx={{
                  "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                    {
                      boxShadow: "none",
                      backgroundColor: "#EEE",
                      borderRadius: "8px",
                    },
                  "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                    paddingTop: "0",
                    paddingBottom: "0",
                  },
                }}
              >
                {props.currentMenu.Edit ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("edit")}>
                    <IconWrapper>
                      <img src={edit} />
                    </IconWrapper>
                    <span style={{ fontWeight: "400" }}>Edit</span>
                  </MenuItemWrapper>
                ) : null}

                {props.currentMenu.Delete ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("delete")}>
                    <IconWrapper>
                      <img src={trash} />
                    </IconWrapper>
                    <span style={{ fontWeight: "400" }}>Delete</span>
                  </MenuItemWrapper>
                ) : null}
              </Menu>
            </div>
          ),
        }
      : {},
  ];

  // open edit and delete popup
  const handleMenuOpen = (e, row) => {
    setAnchorEl(e.currentTarget);
    setselectedCategoryForUpdate(row);
    setselectedCategoryForDelete(row);
    setIsMenuOpen(true);
  };

  // close edit and delete popup
  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleUpdateClick = (selectedRow) => {
    setselectedCategoryForUpdate(selectedRow);
    setIsAddMode(false);
    setshowCategoryPopup(true);
    getCategory();
  };

  const handleDeleteClick = (selectedRow) => {
    setselectedCategoryForDelete(selectedRow);
    setShowDeleteConfirmation(true);
  };

  const handleMenuSelect = (option) => {
    if (option === "edit") {
      handleUpdateClick(selectedCategoryForUpdate);
      handleMenuClose();
    } else if (option === "delete") {
      handleDeleteClick(selectedCategoryForDelete);
    }
  };

  const handleAddCategoryClick = () => {
    setIsAddMode(true);
    setshowCategoryPopup(true);
  };

  // delete api
  const handleConfirmDelete = async (selectedRow) => {
    try {
      const response = await callApi({
        apiURL: Constants.API.deleteCategoryMaster,
        requestBody: {
          deletedCategoryMasterId: selectedRow.CategoryMasterId,
          updateDate: selectedRow.UpdatedDate,
        },
      });
      if (response?.success) {
        const actionMessage = "Deleted Successfully";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            <br />
            Category has been deleted successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            className: "toast_pop",
            transition: Zoom,
            autoClose: 1500,
            closeButton: true,
            onClose: () => {
              getCategory();
            },
          }
        );
        getCategory();
      }
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setShowDeleteConfirmation(false);
      setPage(1);
    }
  };
  const handleCategoryActivityPopupClose = () => {
    setshowCategoryPopup(false);
    setIsAddMode(true);
    setselectedCategoryForUpdate(null);
    getCategory();
  };

  // select api
  const getCategory = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getCategoryMaster,
      });
      if (response?.success) {
        const { category = [] } = response?.data;
        const categoryData = category.map((item, index) => ({
          categoryId: index + 1,
          ...item,
        }));
        setcategoryData(categoryData);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleExportClick = (categoryData) => {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      const fileName = `Category_${formattedDate.replace(/ /g, " ")}.xlsx`;
      const worksheetName = `Category`;
      if (categoryData.length > 0) {
        const exportData = categoryData.map((row) => ({
          SrNo: row.CategoryMasterId,
          "Category Name": row.CategoryName,
          "Category Link": row.CategoryDescription,
        }));
        const workSheet = XLSX.utils.json_to_sheet(exportData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, worksheetName);
        XLSX.writeFile(workBook, fileName);
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF("p");
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    const fileName = `Category_${formattedDate.replace(/ /g, " ")}.pdf`;

    const tableHeaders = ["Sr No", "Category Name", "Category Link"];

    if (categoryData.length > 0) {
      const tableData = categoryData.map((row) => {
        return [
          row.CategoryMasterId,
          row.CategoryName,
          row.CategoryDescription,
        ];
      });

      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("Category Master", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });

      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
        theme: "grid",
      });

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        formattedDate,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );

      doc.save(fileName);
    }
  };

  const handleSortClick = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };

  const renderArrowIcon = (column) => {
    if (sortedColumn === column) {
      {
        return (
          <span className="arrow-icon">
            {sortOrder === "asc" ? (
              <i className="fa-solid fa-arrow-up" />
            ) : (
              <i className="fa-solid fa-arrow-down" />
            )}
          </span>
        );
      }
    }
    return null;
  };

  useEffect(() => {
    if (categoryData.length > 0) {
      setSortedRows(categoryData);
    }
  }, [categoryData.length > 0]);

  useEffect(() => {
    if (sortedColumn) {
      const sorted = categoryData.slice().sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];

        if (aValue < bValue) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedRows(sorted);
    }
  }, [sortedColumn, sortOrder, categoryData]);

  const [filtering, setFiltering] = useState("");

  const handleSearchfilter = (e) => {
    setFiltering(e.target.value);
    setPage(1);
  };

  useEffect(() => {
    const searched = categoryData.filter((item) => {
      if (!filtering) {
        return true;
      } else {
        return (
          (item.CategoryName !== null &&
            item.CategoryName.toLowerCase().includes(
              filtering.toLowerCase()
            )) ||
          (item.CategoryDescription !== null &&
            item.CategoryDescription.toLowerCase().includes(
              filtering.toLowerCase()
            ))
        );
      }
    });

    setSortedRows(searched);
  }, [filtering, categoryData]);

  // paginated rows
  const paginatedRows = sortedRows.slice(startIndex, endIndex);

  return (
    <>
      {/* CategoryMasterPopup */}
      {showCategoryPopup && (
        <CategoryPopup
          onClose={handleCategoryActivityPopupClose}
          isAddMode={isAddMode}
          selectedCategoryForUpdate={selectedCategoryForUpdate}
          getCategoryDetails={getCategory}
        />
      )}
      <div className="categoryMaster_main_component">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <SearchBar value={filtering} onChange={handleSearchfilter} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ExportFile
              handleExportClick={handleExportClick}
              filteredRows={categoryData}
              handleExportPDF={handleExportPDF}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            {props?.currentMenu?.Add ? (
              <>
                <AddButton
                  buttonText={"Add Category"}
                  onClick={handleAddCategoryClick}
                />
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <div className="categorymain_main_sec_2">
          <div className="category_search_main_container">
            <DeleteConfirmationDialog
              open={showDeleteConfirmation}
              handleClose={() => {
                setShowDeleteConfirmation(false);
                handleMenuClose();
              }}
              handleConfirm={() => {
                handleConfirmDelete(selectedCategoryForDelete);
                handleMenuClose();
              }}
              product={"Category"}
            />
            <div className="catrgory_data_grid">
              <div className="category_data">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell
                            key={i}
                            className="categorytableHeaderCell"
                            onClick={() => handleSortClick(column.field)}
                          >
                            {column.headerName}
                            {renderArrowIcon(column.field)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedRows.map((row, i) => (
                        <TableRow key={i}>
                          {columns.map((column, i) => (
                            <TableCell
                              key={i}
                              className="categorytableBodyCell"
                            >
                              {column.renderCell
                                ? column.renderCell({ row })
                                : row[column.field]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={totalPages}
                  page={page}
                  hideNextButton
                  hidePrevButton
                  onChange={(e, value) => setPage(value)}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      page={formattedPageNumber(item.page)}
                      className={item.selected ? "pagination-selected" : ""}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Category;
