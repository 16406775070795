import React, { useState, useEffect } from "react";
import { toast, Zoom, ToastContainer } from "react-toastify";
import { Field, ErrorMessage, Formik, Form } from "formik";
import "react-toastify/dist/ReactToastify.css";
import callApi from "../../ApiCall";
import Constants from "../../Constants";
import "./ItemRequest.css";
import * as Yup from "yup";
import Select from "react-select";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { InputBase } from "@mui/material";
import styled from "styled-components";


const AddItemRequest = ({ onClose, isAddMode }) => {
  const [showPopup, setShowPopup] = useState(true);
  const [orderItemList, setOrderItemList] = useState([]);
  const [initialItemList, setInitialItemList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
   const [showOverlay, setShowOverlay] = React.useState(true);

  const pageSize = orderItemList.length;
  const paginatedRows = orderItemList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  useEffect(() => {
    getItemListForItemRequest();
    AddNewItemRow();
  }, []);

  const getItemListForItemRequest = async () => {
    const response = await callApi({
      apiURL: Constants.API.getItemListForItemRequest,
    });
    if (response?.success) {
      const { itemList = [] } = response.data;
      const initialItems = itemList.map((item) => ({
        value: item.value,
        label: item.label,
        Description: item.Description,
        Category: item.Category,
        CategoryName: item.CategoryName,
        Pack: item.Pack,
        UOM: item.UOM,
        UOMName: item.UOMName,
        IsReturn: item.IsReturn,
      }));
      setInitialItemList(initialItems);
    }
  };

  const AddNewItemRow = () => {
    const newRow = {
      srNo: orderItemList.length + 1,
      quantity: null,
      selectedItem: null,
    };
    setOrderItemList((prevOrderItemList) => [...prevOrderItemList, newRow]);
  };

  const handleItemChange = (index, selectedItem) => {
    if (!selectedItem || !selectedItem.value) {
      toast.error("Please select a valid item");
      return;
    }

    const updatedList = orderItemList.map((item, i) =>
      i === index
        ? { ...item, selectedItem, quantity: item.quantity || 0 }
        : item
    );

    if (!validateUniqueItems(updatedList)) {
      toast.error("Selected item is already present");
      return;
    }

    setOrderItemList(updatedList);
  };

  const validateUniqueItems = (items) => {
    const itemIds = items.map((item) => item.selectedItem?.value);
    const uniqueItems = new Set(itemIds);
    return itemIds.length === uniqueItems.size;
  };

  const removeRow = (index) => {
    setOrderItemList(orderItemList.filter((_, i) => i !== index));
  };

  const handleQuantityChange = (index, value) => {
    setOrderItemList((prev) =>
      prev.map((item, i) => (i === index ? { ...item, quantity: value } : item))
    );
  };

  const handleSave = async () => {
    const selectedItems = orderItemList.filter((item) => item.selectedItem);
    if (selectedItems.length === 0) {
      toast.error("Please select at least one item.");
      return;
    }

    const hasInvalidQuantity = selectedItems.some(
      (item) =>
        item.quantity === null ||
        item.quantity <= 0 ||
        item.quantity === "" ||
        Number.isNaN(item.quantity)
    );
    if (hasInvalidQuantity) {
      toast.error("Please enter a valid quantity for all selected items.");
      return;
    }

    const itemIds = selectedItems.map((item) => item.selectedItem.value);

    const hasDuplicates = new Set(itemIds).size !== itemIds.length;

    if (hasDuplicates) {
      toast.error("Duplicate items selected! Please select unique items.");
      return;
    }

    const formattedSelectedItems = selectedItems.map((item) => ({
      value: item.selectedItem.value,
      label: item.selectedItem.label,
      Description: item.selectedItem.Description,
      Category: item.selectedItem.Category,
      CategoryName: item.selectedItem.CategoryName,
      Pack: item.selectedItem.Pack,
      UOM: item.selectedItem.UOM,
      UOMName: item.selectedItem.UOMName,
      IsReturn: item.selectedItem.IsReturn,
      quantity: item.quantity,
    }));

    try {
      const response = await callApi({
        apiURL: Constants.API.addOrderData,
        requestBody: { itemList: formattedSelectedItems },
      });
      if (response?.success) {
        toast.success("Item Requested Successfully", {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          hideProgressBar: true,
          transition: Zoom,
          style: {
            width: "350px",
            backgroundColor: "#0ABE75",
            color: "#FFFFFF",
          },
          autoClose: 1500,
          closeButton: true,
        });
        onClose();
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const columns = [
    {
      field: "ItemName",
      headerName: "Item Name",
      flex: 1,
      renderCell: (params, index) => (
        <Select
          options={initialItemList}
          onChange={(selectedOption) => handleItemChange(index, selectedOption)}
          value={orderItemList[index]?.selectedItem || null}
          name="ItemName"
          className="dietplan_dropdown"
        />
      ),
    },
    {
      field: "Quantity",
      headerName: "Quantity",
      renderCell: (params, index) => (
        <InputBase
          placeholder="0"
          type="number"
          value={orderItemList[index]?.quantity || ""}
          onChange={(e) =>
            handleQuantityChange(index, parseInt(e.target.value, 10))
          }
          className="employee-detail-input"
        />
      ),
    },
    {
      field: "Actions",
      headerName: "",
      renderCell: (params, index) => (
        <button onClick={() => removeRow(index)}>
          <h1 className="button_RemoveItemRow">X</h1>
        </button>
      ),
    },
  ];
  const handleOutsideClick = (event) => {
    if (event.target.classList.contains("PopMain")) {
      setShowPopup(false);
      onClose();
      setShowOverlay(false);
    }
  };
  return (
    <>
    {showOverlay && <div className="Overlay" />}
      <div
        className={
          showPopup ? "itemRequest_PopMain" : "itemRequest_PopMain hidden"
        } onClicks={handleOutsideClick}
      >
        <div className="guestActivity_First">
          <p>{isAddMode ? "Add Items" : "Update Items"}</p>
          <button
            className="popup_Close_icon"
            onClick={() => setShowPopup(false) || onClose()}
          >
            X
          </button>
        </div>
        <div className="Property_Location_DataGrid" style={{ width: "90%" }}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <button className="button_AddItemRow" onClick={AddNewItemRow}>
              +
            </button>
          </div>
          <TableContainer style={{ overflowX: "unset" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="tableHeaderCell">Sr. No.</TableCell>
                  {columns.map((column, i) => (
                    <TableCell key={i} className="tableHeaderCell">
                      {column.headerName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedRows.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell className="tableBodyCell">
                      {rowIndex + 1}
                    </TableCell>
                    {columns.map((column, colIndex) => (
                      <TableCell key={colIndex} className="tableBodyCell">
                        {row &&
                          (column.renderCell
                            ? column.renderCell({ row }, rowIndex)
                            : row[column.field])}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="item_End">
            <button
              type="submit"
              className="item_buttonb"
              onClick={handleSave}
            >
              Save
            </button>
            <button
              className="item_buttona"
              onClick={() => setShowPopup(false) || onClose()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddItemRequest;
