import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import trash from "../../assets/icons/trash.svg";
import edit from "../../assets/icons/edit.svg";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import callApi from "../../ApiCall";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CSS/CheckIn.css";
import Constants from "../../Constants";
import MySelect from "../../common/SingleSelect";
import Multiselect from "multiselect-react-dropdown";

const CheckIn = ({ onClose, selectedBooking }) => {
  const [showPopup, setShowPopup] = useState(true);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  });

  const handleCancel = () => {
    setShowPopup(false);
    onClose();
  };

  const [guestList, setGuestList] = useState([]);
  const [assignedList, setAssignedList] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState(0);

  const [roomTypeList, setRoomTypeList] = useState([]);

  const getRoomTypeList = async () => {
    const response = await callApi({
      apiURL: Constants.API.getRoomType,
    });
    if (response?.success) {
      const { roomType = [] } = response.data;
      const roomTypeData = roomType.map((x) => ({
        value: x.RoomTypeId,
        label: x.RoomTypeName,
      }));
      setRoomTypeList(roomTypeData);
      if (response?.success) {
      } else {
        console.error("error");
      }
    }
  };

  const [roomList, setRoomList] = useState([]);

  const getAvailableRoomsByRoomType = async (id) => {
    const response = await callApi({
      apiURL: Constants.API.getAvailableRoomsByRoomType,
      requestBody: { roomTypeId: id },
    });
    if (response?.success) {
      const { availableRooms = [] } = response.data;
      const data = availableRooms.map((x) => ({
        value: x.RoomMasterId,
        label: x.RoomNumber,
        keyNumber: x.RoomKeyNumber,
        noOfBed: x.NoOfBed,
      }));
      let assignedRooms = assignedList?.map((x) => {
        return x.roomMasterId;
      });
      const availableRoomsData = data.filter((x) => {
        return !assignedRooms.includes(x.value);
      });
      setRoomList(availableRoomsData);
    } else {
      console.error("error");
    }
  };

  const getBookingDetailsByBookingId = async () => {
    const response = await callApi({
      apiURL: Constants.API.getBookingDetailsByBookingId,
      requestBody: {
        bookingMasterId: selectedBooking.BookingMasterId,
      },
    });

    if (response?.success) {
      let data = response.data.bookingDetails?.map((x) => {
        let guestName = "";
        JSON.parse(selectedBooking?.GuestList).map((y) => {
          if (JSON.parse(x.GuestList)?.includes(y.GuestMasterId)) {
            let separator = guestName != "" ? ", " : "";
            guestName += separator + y.FirstName + " " + y.LastName;
          }
        });
        return {
          bookingDetailId: x.BookingDetailId,
          updatedDate: x.UpdatedDate,
          guestNames: guestName,
          guestData: JSON.parse(x.GuestList),
          roomTypeId: x.RoomTypeId,
          roomMasterId: x.RoomMasterId,
          roomNo: x.RoomNumber,
          roomKeyNumber: x.RoomKeyNumber,
          noOfExtraBed: x.NoOfExtraBed,
        };
      });
      setAssignedList(data);
      getRoomTypeList();
    } else {
      console.error("error");
    }
  };

  useEffect(() => {
    getBookingDetailsByBookingId();

    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    let data = JSON.parse(selectedBooking?.GuestList)?.map((x) => {
      return {
        ...x,
        value: x.GuestMasterId,
        label: x.FirstName + " " + x.LastName,
      };
    });
    setGuestList(data);
    setRemainingGuests(data);
    //   getRoomTypeList();
  }, []);

  //   const [roomTypeName, setRoomTypeName] = useState({
  //     value: 0,
  //   });

  //   const [room, setRoom] = useState({
  //     value: 0,
  //   });

  const handleRoomType = (option) => {
    // setRoomTypeName(option);
    getAvailableRoomsByRoomType(option.value);
  };

  //   const handleRoom = (option) => {
  //     setRoom(option);
  //   };

  const [selectedGuestData, setSelectedGuestData] = useState([]);
  const [remainingGuests, setRemainingGuests] = useState([]);

  const handleGuestsChange = (selectedOptions, setFieldValue) => {
    const newData = selectedOptions.map((option) => option.value);
    setSelectedGuestData(newData);
    setFieldValue("guestData", newData);
    const remaining =
      newData.length > 0
        ? guestList.filter((guest) => {
            return !newData?.includes(guest.value);
          })
        : guestList;
    setRemainingGuests(remaining);
  };

  const initialValuesForFormiks = {
    bookingDetailId: 0,
    roomTypeId: 0,
    roomMasterId: 0,
    guestData: "",
    noOfExtraBed: 0,
  };

  const validationSchema = Yup.object({
    roomTypeId: Yup.number().required().moreThan(0, "Room Type is required"),
    roomMasterId: Yup.number()
      .required()
      .moreThan(0, "Room Number is required"),
    guestData: Yup.array()
      .of(Yup.string().min(1, "At least one guest is required"))
      .required("Guest is required"),
  });

  const assignRoomAndGuests = (
    values,
    resetForm,
    validateForm,
    setFieldTouched,
    setFieldValue
  ) => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        let guestName = "";
        guestList.map((x) => {
          if (values.guestData.includes(x.GuestMasterId)) {
            let separator = guestName != "" ? ", " : "";
            guestName += separator + x.FirstName + " " + x.LastName;
          }
        });

        let roomDetails = roomList.filter(
          (x) => x.value == values.roomMasterId
        )[0];
        let data = {
          ...values,
          guestNames: guestName,
          roomNo: roomDetails.label,
          roomKeyNumber: roomDetails.keyNumber,
        };
        setAssignedList([...assignedList, data]);
        setGuestList(remainingGuests);
        setRoomList([]);
        setSelectedGuestData([]);
        resetForm();
      } else {
        Object.keys(errors).forEach((fieldName) => {
          setFieldTouched(fieldName, true, false);
        });
      }
    });
  };

  const handleSubmit = async (isCheckIn) => {
    if (assignedList.length > 0) {
      const params = {
        bookingMasterId: selectedBooking?.BookingMasterId,
        updatedDate: selectedBooking?.UpdatedDate,
        bookingDetailList: assignedList.map((x) => {
          return {
            bookingDetailId: x.bookingDetailId,
            roomMasterId: x.roomMasterId,
            noOfExtraBed: x.noOfExtraBed,
            guestList: x.guestData,
          };
        }),
        isCheckIn: isCheckIn,
      };

      const response = await callApi({
        apiURL: Constants.API.addCheckIn,
        requestBody: params,
      });
      if (response?.success) {
        handleCancel();
        toast.success(
          <div className="custom_Toast">
            <h5>CheckIn successfully</h5>
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            hideProgressBar: true,
            className: "toast_pop",
            autoClose: 1500,
            closeButton: true,
            transition: Zoom,
          }
        );
      } else {
        console.log("error");
      }
    } else {
    }
    // onClose();
    // resetForm();
  };

  const deleteBookingDetail = async (index) => {
    if (assignedList[index].bookingDetailId > 0) {
      const params = {
        bookingDetailId: assignedList[index]?.bookingDetailId,
        updatedDate: assignedList[index]?.UpdatedDate,
      };

      const response = await callApi({
        apiURL: Constants.API.deleteBookingDetail,
        requestBody: params,
      });
      if (response?.success) {
        assignedList.splice(index, 1);
        setAssignedList([...assignedList]);
      } else {
        console.log("error");
      }
    } else {
      assignedList.splice(index, 1);
      setAssignedList([...assignedList]);
    }
  };

  const validateAnyValue = (values) => {
    if (
      values.roomTypeId == 0 &&
      values.roomMasterId == 0 &&
      values.guestData == 0 &&
      assignedList.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="Overlay"></div>
      <div className={showPopup ? "AssignPopupOpen" : "AssignPopupOpen hidden"}>
        <div className="Assignpophead">
          <p className="Assign_p">Check In</p>
          <button onClick={handleCancel}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#424242"
              />
            </svg>
          </button>
        </div>

        <Formik
          initialValues={initialValuesForFormiks}
          validationSchema={validationSchema}
        >
          {({
            values,
            resetForm,
            setValues,
            validateForm,
            setFieldValue,
            setFieldTouched,
          }) => (
            <div>
              <div className="formic-container">
                <Form className="AsssignForm">
                  <div className="AssignPopField">
                    <label htmlFor="RoomType" className="Designation-label">
                      Room Type <sup>*</sup>
                    </label>
                    <Field
                      component={MySelect}
                      name="roomTypeId"
                      options={roomTypeList}
                      placeholder="Select Room Type"
                      className="designation-dropdown"
                      // value={values.roomTypeId}
                      onChange={(option) => {
                        handleRoomType(option);
                        setFieldValue("roomMasterId", 0);
                      }}
                    />
                    <div className="error">
                      <ErrorMessage name="roomTypeId" />
                    </div>
                  </div>
                
                  <div className="AssignPopField">
                    <label htmlFor="RoomNo" className="Designation-label">
                      Room Number <sup>*</sup>
                    </label>
                    <Field
                      component={MySelect}
                      name="roomMasterId"
                      options={roomList}
                      placeholder="Select Room"
                      className="designation-dropdown"
                      // value={values.roomMasterId}
                      onChange={() => {}}
                    />
                    <div className="error">
                      <ErrorMessage name="roomMasterId" />
                    </div>
                    {values.roomMasterId > 0 && (
                      <label htmlFor="RoomNo" className="Designation-label">
                        {"Number Of Bed :- " +
                          roomList?.filter((x) => {
                            return x.value == values.roomMasterId;
                          })[0]?.noOfBed}
                      </label>
                    )}
                  </div>
                  <div className="AssignPopField">
                    <label htmlFor="NoOfExtraBed" className="Designation-label">
                      No Of Extra Bed
                    </label>
                    <Field
                      component={MySelect}
                      name="noOfExtraBed"
                      options={[0, 1, 2].map((x) => {
                        return { label: x, value: x };
                      })}
                      placeholder="Select No Of Extra Bed"
                      className="designation-dropdown"
                      onChange={() => {}}
                    />
                    <div className="error">
                      <ErrorMessage name="noOfExtraBed" />
                    </div>
                  </div>
                  <div className="AssignPopField">
                    <label htmlFor="Guests" className="Designation-label">
                      Guests<sup>*</sup>
                    </label>
                    <Field
                      as={Multiselect}
                      options={remainingGuests}
                      //   selectedValues={selectedGuestData}
                      displayValue="label"
                      onSelect={(selectedList) =>
                        handleGuestsChange(selectedList, setFieldValue)
                      }
                      onRemove={(selectedList) =>
                        handleGuestsChange(selectedList, setFieldValue)
                      }
                      placeholder="Select Guests"
                      name="guestData"
                      id="Guests"
                    />
                    <div className="error">
                      <ErrorMessage name="guestData" />
                    </div>
                  </div>
                  <div className="button_container">
                    <button
                      className="room_save"
                      onClick={() => {
                        assignRoomAndGuests(
                          values,
                          resetForm,
                          validateForm,
                          setFieldTouched,
                          setFieldValue
                        );
                      }}
                      type="button"
                      // style={{ display: isEditing ? "none" : "block" }}
                    >
                      Save and Assign more
                    </button>
                    <button
                      className="room_save"
                      onClick={() => {
                        resetForm();
                      }}
                      type="button"
                      // style={{ display: isEditing ? "none" : "block" }}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>

                {assignedList?.length === 0 ? (
                  <div className="room_data">
                    <p style={{ fontSize: "14px" }}>
                      Please assign rooms to guests.
                    </p>
                  </div>
                ) : (
                  <div className="data_container">
                    {assignedList?.map((item, index) => {
                      return (
                        <div className=" room_data" key={index}>
                          <div className="room_content">
                            <p
                              className="mb-0 font-semibold"
                              style={{ fontSize: "14px" }}
                            >
                              {"Room"}
                            </p>
                            <p>
                              <label
                                className="mb-0 font-semibold"
                                style={{ fontSize: "14px" }}
                              >
                                {"No # "}
                                {item?.roomNo}
                              </label>
                            </p>
                            <p className="mb-0">
                              <label
                                className="mb-0 font-semibold"
                                style={{ fontSize: "14px" }}
                              >
                                {"Key "}
                                <i class="fa-solid fa-key">
                                  {" " + item?.roomKeyNumber}
                                </i>
                              </label>
                            </p>
                            <p
                              className="mb-0 font-semibold"
                              style={{ fontSize: "14px" }}
                            >
                              {"Guests :-"}
                              {item?.guestNames}
                            </p>
                            <p
                              className="mb-0 font-semibold"
                              style={{ fontSize: "14px" }}
                            >
                              {"No Of Extra Bed :- "}
                              {item?.noOfExtraBed}
                            </p>
                          </div>
                          <button
                            className="btndeleteRoom"
                            // disabled={isEditing ? true : false}
                            // style={{
                            //   opacity: isEditing ? 0.2 : 1,
                            //   cursor: isEditing ? "default" : "pointer",
                            // }}
                          >
                            <div
                              onClick={() => {
                                resetForm();
                                deleteBookingDetail(index);
                              }}
                            >
                              <img
                                src={trash}
                                style={{
                                  color: "var(--alert-status-error)",
                                  //   opacity: item.isNew ? 0.2 : 1,
                                  //   cursor: item.isNew ? "default" : "pointer",
                                }}
                              />
                            </div>
                          </button>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              {validateAnyValue(values) && (
                <>
                  <div className="roomPop_buttons">
                    <button
                      type="submit"
                      className="roomMaster_buttonn"
                      onClick={() => handleSubmit(false)}
                    >
                      Save
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      type="button"
                      className="roomMaster_button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                  <br />
                  <div className="roomPop_buttons">
                    <button
                      type="submit"
                      className="roomMaster_buttonn"
                      onClick={() => handleSubmit(true)}
                    >
                      Check In
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};
export default CheckIn;
