import React, { useEffect, useState } from "react";
import callApi from "../../ApiCall";
import Constants from "../../Constants";
import "./dashboardScreen.css";
import { PieChart, Pie, Cell, Tooltip } from "recharts";

// Color palette for the pie chart
const palette = ["#6C7D02", "#b4ba3d"];

const RoomOccupy = () => {
  const [occupyCount, setOccupyCount] = useState({ Available: 0, Occupied: 0 });

  useEffect(() => {
    const fetchOccupyData = async () => {
      try {
        const response = await callApi({
          apiURL: Constants.API.getOccupyCount,
        });
        if (response?.success) {
          const occupyData = response.data.occupyCount[0] || {
            Available: 0,
            Occupied: 0,
          };
          setOccupyCount(occupyData);
        } else {
          console.error("Failed to fetch occupy data:", response?.error);
        }
      } catch (error) {
        console.error("API call error:", error);
      }
    };

    fetchOccupyData();
  }, []);

  // Data for PieChart
  const platforms = [
    { name: "Available", value: occupyCount.Available },
    { name: "Occupied", value: occupyCount.Occupied },
  ];

  return (
    <div className="dashboard-card-container2">
      <div className="dashboard-card-item3">
        <h2 className="dashboard-title2">Room Occupied</h2>
        <PieChart width={200} height={200}>
          <Pie
            data={platforms}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={50}
            fill="#8884d8"
          >
            {platforms.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={palette[index % palette.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </div>

      {/* Occupancy Data */}
      <div className="dashboard-card-item4">
        <div className="roomcss1">
          <span className="dot1"></span>
          <h3 className="dashboard-item-label3">
            Available: {occupyCount.Available}
          </h3>
        </div>
        <div className="roomcss2">
          <span className="dot2"></span>
          <h3 className="dashboard-item-label4">
            Occupied: {occupyCount.Occupied}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default RoomOccupy;
