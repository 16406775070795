import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import callApi from "../../ApiCall";
import Constants from "../../Constants";
import "./MenuMaster.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6C7D02",
    color: "white",
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function MenuMaster(props) {
  const [roleList, setRoleList] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [menuRoleList, setMenuRoleList] = useState([]);
  const [error, setError] = useState("");

  const [copyRole, setCopyRole] = useState("");
  const [copyRoleError, setCopyRoleError] = useState("");
  const [copyMenuRoleList, setCopyMenuRoleList] = useState([]);

  const flattenArray = (arr) => {
    let result = [];
    arr.forEach((item) => {
      result.push(item);
      if (item.ChildList && JSON.parse(item.ChildList)) {
        result.push(...JSON.parse(item.ChildList));
      }
    });
    return result;
  };
 

  const getMenuRoleByRoleId = async (roleId) => {
    setMenuRoleList([]);
    setCopyMenuRoleList([]);
    const response = await callApi({
      apiURL: Constants.API.getMenuRoleByRoleId,
      requestBody: {
        roleId: roleId,
      },
    });
    if (response?.success) {
      setMenuRoleList(flattenArray(response.data.menuRoleList));
      setCopyMenuRoleList(flattenArray(response.data.menuRoleList));
      setCopyRole("");
      setCopyRoleError("");
    } else {
      console.log("error");
    }
  };


  const getRoles = async () => {
    const response = await callApi({
      apiURL: Constants.API.getRoles,
    });
    if (response?.success) {
      setRoleList(response.data.roleList);
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  const handleStatusChange = (id, parentId, type, value) => {
    setMenuRoleList((prevData) =>
      prevData.map((item) => {
        if (parentId === 0) {
          if (item.ParentId === id && value == 0) {
            return { ...item, [type]: value, isUpdate: true };
          }
          if (item.MenuMasterId === id) {
            return { ...item, [type]: value, isUpdate: true };
          }
        } else {
          if (item.MenuMasterId === parentId && value == 1) {
            return { ...item, [type]: value, isUpdate: true };
          }
          if (item.MenuMasterId === id) {
            return { ...item, [type]: value, isUpdate: true };
          }
        }
        return item;
      })
    );
  };

  const handleSave = async () => {
    const updatedData = menuRoleList.filter((x) => x.isUpdate);
    if (updatedData.length == 0) {
      setError("Please update anything!!!");
      return;
    }
    setError("");
    const response = await callApi({
      apiURL: Constants.API.saveMenuRole,
      requestBody: {
        menuRoleList: updatedData,
      },
    });
    if (response?.success) {
      getMenuRoleByRoleId(selectedRole);
    } else {
      console.log("error");
    }
  };

  const handleSaveAs = async () => {
    if (copyRole == "") {
      setCopyRoleError("Please add role name.");
      return;
    }
    setCopyRoleError("");
    const response = await callApi({
      apiURL: Constants.API.copyMenuRole,
      requestBody: {
        roleName: copyRole,
        menuRoleList: copyMenuRoleList,
      },
    });
    if (response?.success) {
      getMenuRoleByRoleId(selectedRole);
    } else {
      console.log("error");
    }
  };

  const menuBody = (menuRole) => {
    return (
      <StyledTableRow
        key={menuRole.MenuMasterId}
        sx={{
          backgroundColor: menuRole.ParentId == 0 && "#ffffff",
          color: "#0733F6",
        }}
      >
        <StyledTableCell component="th" scope="row">
          {menuRole.ParentId !== 0 && <>&nbsp;&nbsp;&nbsp;</>}
          {menuRole.MenuName}
        </StyledTableCell>
        <StyledTableCell>
          <FormControlLabel
            control={
              <Checkbox
                checked={menuRole.View}
                onChange={() =>
                  handleStatusChange(
                    menuRole.MenuMasterId,
                    menuRole.ParentId,
                    "View",
                    menuRole.View == 0 ? 1 : 0
                  )
                }
              />
            }
          />
        </StyledTableCell>
        <StyledTableCell>
          <FormControlLabel
            control={
              <Checkbox
                checked={menuRole.Add}
                onChange={() =>
                  handleStatusChange(
                    menuRole.MenuMasterId,
                    menuRole.ParentId,
                    "Add",
                    menuRole.Add == 0 ? 1 : 0
                  )
                }
              />
            }
          />
        </StyledTableCell>
        <StyledTableCell>
          <FormControlLabel
            control={
              <Checkbox
                checked={menuRole.Edit}
                onChange={() =>
                  handleStatusChange(
                    menuRole.MenuMasterId,
                    menuRole.ParentId,
                    "Edit",
                    menuRole.Edit == 0 ? 1 : 0
                  )
                }
              />
            }
          />
        </StyledTableCell>
        <StyledTableCell>
          <FormControlLabel
            control={
              <Checkbox
                checked={menuRole.Delete}
                onChange={() =>
                  handleStatusChange(
                    menuRole.MenuMasterId,
                    menuRole.ParentId,
                    "Delete",
                    menuRole.Delete == 0 ? 1 : 0
                  )
                }
              />
            }
          />
        </StyledTableCell>
        <StyledTableCell>
          <FormControlLabel
            control={
              <Checkbox
                checked={menuRole.TeamView}
                onChange={() =>
                  handleStatusChange(
                    menuRole.MenuMasterId,
                    menuRole.ParentId,
                    "TeamView",
                    menuRole.TeamView == 0 ? 1 : 0
                  )
                }
              />
            }
          />
        </StyledTableCell>
        <StyledTableCell>
          <FormControlLabel
            control={
              <Checkbox
                checked={menuRole.ViewAll}
                onChange={() =>
                  handleStatusChange(
                    menuRole.MenuMasterId,
                    menuRole.ParentId,
                    "ViewAll",
                    menuRole.ViewAll == 0 ? 1 : 0
                  )
                }
              />
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };
  return (
    <>
      <div className="MainPae">
        <div className="sect-1">
          {/* <div className="product_manage">
            <h1 className="manage_page_heading">Manage Menu Rights</h1>
          </div> */}
          <div className="drop-down">
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              Role
            </InputLabel>
            <Select
              // labelId="demo-simple-select-autowidth-label"
              // id="demo-simple-select-autowidth"
              value={selectedRole}
              label="role"
              onChange={(e) => {
                setSelectedRole(e.target.value);
                e.target.value > 0 && getMenuRoleByRoleId(e.target.value);
              }}
            >
              <MenuItem value="">
                <em>--Select--</em>
              </MenuItem>
              {roleList?.map((role, index) => {
                return (
                  <MenuItem value={role.RoleMasterId}>{role.RoleName}</MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {menuRoleList.length > 0 && (
            <>
              <TextField
                id="outlined-basic"
                label="Copy to"
                variant="outlined"
                error={copyRoleError}
                helperText={copyRoleError}
                sx={{
                  marginLeft: "10px",
                }}
                onChange={(e) => {
                  setCopyRole(e.target.value);
                  e.target.value
                    ? setCopyRoleError("")
                    : setCopyRoleError("Please add role name.");
                }}
              />
              {copyRole != "" && (
                <Button
                  variant="contained"
                  onClick={() => handleSaveAs()}
                  className="save-btn"
                  sx={{
                    background: "#6c7d02",
                    color: "white",
                    marginLeft: "10px",
                    minWidth: "120px",
                  }}
                >
                  Save As
                </Button>
              )}
              {copyRole === "" && (
                <Button
                  variant="contained"
                  onClick={() => handleSave()}
                  className="save-btn"
                  sx={{
                    background: "#6c7d02",
                    color: "white",
                    marginTop:"-5px",
                    marginBottom: "5px",
                    marginLeft: "8px",
                    minWidth: "120px",
                  }}
                >
                  Save
                </Button>
              )}
            </>
          )}
        </div>
        </div>
        
        {error != "" && (
          <div
            style={{
              color: "red",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              paddingRight: "10px",
            }}
          >
            {error}
          </div>
        )}
        {menuRoleList.length > 0 && (
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Menu</StyledTableCell>
                  <StyledTableCell>View</StyledTableCell>
                  <StyledTableCell>Add</StyledTableCell>
                  <StyledTableCell>Edit</StyledTableCell>
                  <StyledTableCell>Delete</StyledTableCell>
                  <StyledTableCell>Team View</StyledTableCell>
                  <StyledTableCell>View All</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {menuRoleList.map((menuRole) => {
                  return <>{menuBody(menuRole)}</>;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </>
  );
}
