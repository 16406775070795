const Constants = {
  BASE_URL: "https://www.halfolive.com/",
  API: {
    //Subscription
    paymentSuccess: "subscription/payment-success",
    getSubscriptionPlans: "subscription/getPlans",
    freeTrial: "subscription/freeTrial",
    createSubscription: "subscription/create-subscription-session",

    //Onboarding
    getProperty: "onboarding/getProperty",
    addProperty: "onboarding/addProperty",
    updateProperty: "onboarding/updateProperty",
    updatePropertyAddress: "onboarding/updatePropertyAddress",
    getRoomDetails: "onboarding/getRoomDetails",
    saveRoomType: "onboarding/saveRoomDetails",
    getRoomRates: "onboarding/getRoomRates",
    saveRoomRate: "onboarding/saveRoomRate",
    getServices: "onboarding/getServices",
    addServiceCategory: "onboarding/addServiceCategory",
    updateServiceCategory: "onboarding/updateServiceCategory",
    addService: "onboarding/addService",
    updateService: "onboarding/updateService",
    serviceStatusUpdate: "onboarding/serviceStatusUpdate",
    completeOnBoarding: "onboarding/completeOnBoarding",
    //dashboard
    getUserDetails: "dashboard/getUserDetails",
    getPlans: "openApi/getPlans",
    getGenderCount: "dashboard/getGenderCount",
    getRoomCount: "dashboard/getRoomCount",
    getOccupyCount: "dashboard/getOccupyCount",

    //profile
    getProfileData: "employeemaster/getLoginEmployeeData",

    // HealthHistory

    getHealthHistoryData: "guesthealthhistory/getHealthHistoryData",
    addHealthHistoryData: "guesthealthhistory/addHealthHistoryData",
    // API Dropdown getaddhealthHistoryData:
    getaddHealthHistoryData: "guesthealthhistory/getaddHealthHistoryData",
    updateHealthHistoryData: "guesthealthhistory/updateHealthHistoryData",
    deleteHealthHistoryData: "guesthealthhistory/deleteHealthHistoryData",
    // SP
    addHealthHistoryInputData: "guesthealthhistory/addHealthHistoryInputData",
    // LIST
    getHealthHistoryDataList: "guesthealthhistory/getHealthHistoryDataList",

    //  DailyHealthHistory :
    // API
    getaddDailyHealthHistoryData:
      "guestdailyhealth/getaddDailyHealthHistoryData",
    addDailyHealthHistoryData: "guestdailyhealth/addDailyHealthHistoryData",
    updateDailyHealthHistoryData:
      "guestdailyhealth/updateDailyHealthHistoryData",
    deleteDailyHealthHistoryData:
      "guestdailyhealth/deleteDailyHealthHistoryData",
    // List
    getDailyHealthHistoryDataList:
      "guestdailyhealth/getDailyHealthHistoryDataList",

    //roles
    getMenus: "menuMaster/getMenus",
    getMenuRoleByMenuId: "menuMaster/getMenuRoleByMenuId",
    saveMenuRole: "menuMaster/saveMenuRole",
    copyMenuRole: "menuMaster/copyMenuRole",
    getRoles: "menuMaster/getRoles",
    getMenuRoleByRoleId: "menuMaster/getMenuRoleByRoleId",

    getGender: "openApi/getGender",

    //guestList
    getGuestList: "guestmaster/getGuestList",
    selectedGuestBookingHistory: "guestmaster/selectedGuestBookingHistory",
    selectedGuestDetails: "guestmaster/selectedGuestDetails",

    //GUESTaCTIVITY
    getGuestSchedule: "guestActivitymaster/getGuestScheduleByDate",
    getDynamicActivityList: "guestActivitymaster/getDynamicActivityList",
    getDynamicActivityEmployees:
      "guestActivitymaster/getDynamicActivityEmployees",
    getGuestActivityTimeSlots: "guestActivitymaster/getGuestActivityTimeSlots",
    getSpecialGuestActivityList:
      "guestActivitymaster/getSpecialGuestActivityList",
    getSpecialActivityEmployees:
      "guestActivitymaster/getSpecialActivityEmployees",
    getActivityPreferences: "guestActivitymaster/getActivityPreferences",
    savePreferrences: "guestActivitymaster/savePreferrences",
    updatePreferences: "guestActivitymaster/updateGuestActivityPreferrences",
    deleteGuestActivityPreferrences:
      "guestActivitymaster/deleteGuestActivityPreferrences",
    startSChedule: "guestActivitymaster/startSChedule",
    getDatewiseGuestSchedule: "guestActivitymaster/getDatewiseGuestSchedule",

    //Room Master
    getRoomData: "room/getRoomData",
    getRoomType: "room/getRoomType",
    getOccupiedRoomList: "room/getOccupiedRoomList",
    deleteRoomType: "room/deleteRoomType",
    addRoom: "room/addRoom",
    updateRoom: "room/updateRoom",
    addRoomNo: "room/addRoomNo",
    updateRoomData: "room/updateRoomData",
    deleteRoom: "room/deleteRoom",

    //Service Master
    getService: "service/getService",
    getServiceCategoryData: "service/getServiceCategoryData",
    deleteService: "service/deleteService",
    deleteServiceCategory: "service/deleteServiceCategory",
    masteraddServiceCategory: "service/addServiceCategory",
    masterupdateServiceCategory: "service/updateServiceCategory",
    masteraddService: "service/addService",
    masterupdateService: "service/updateService",

    //Department
    getDepartment: "department/getDepartment",
    addDepartment: "department/addDepartment",
    updateDepartment: "department/updateDepartment",
    deleteDepartment: "department/deleteDepartment",

    //Designation
    getDesignation: "designation/getDesignation",
    getDesignationDepartment: "designation/getDepartment",
    getReportingToDesignation: "designation/getReportingToDesignation",
    addDesignation: "designation/addDesignation",
    updateDesignation: "designation/updateDesignation",
    deleteDesignation: "designation/deleteDesignation",

    // Physiotherapy
    getPhysiotherapyData: "physiotherapy/getPhysiotherapyData",
    addPhysiotherapy: "physiotherapy/addPhysiotherapy",
    updatePhysiotherapy: "physiotherapy/updatePhysiotherapy",
    deletePhysiotherapy: "physiotherapy/deletePhysiotherapy",
    getPhysiotherapyDataList: "physiotherapy/getPhysiotherapyDataList",
    PhysiotherapyData: "physiotherapy/PhysiotherapyData",

    //DietPlan
    getDietPlan: "dietplanmaster/getDietPlan",
    addDietPlan: "dietplanmaster/addDietPlan",
    updateDietPlan: "dietplanmaster/updateDietPlan",
    deleteDietPlan: "dietplanmaster/deleteDietPlan",
    getPropertyDetails: "dietplanmaster/getPropertyDetails",
    getDatesWiseGuestDietPlan: "dietplanmaster/getDatesWiseGuestDietPlan",
    getActiveDietPlan: "dietplanmaster/getActiveDietPlan",
    addDatesWiseGuestDietPlan: "dietplanmaster/addDatesWiseGuestDietPlan",
    getTimeSlot: "therapy/getTimeSlot",
    saveTherapySlot: "therapy/saveTherapySlots",
    getSelectedDateTherapies: "therapy/getSelectedDateTherapies",
    
    getPackageTherapy: "therapy/getPackageTherapy",
    //Treatments
    getTherapy: "therapy/getTherapy",
    addTherapy: "therapy/addTherapy",
    updateTherapy: "therapy/updateTherapy",
    deleteTherapy: "therapy/deleteTherapy",
    getThreeDayTreatments: "therapy/getThreeDayTreatmens",

    //PropertyMaster and PropertyLocation
    getPropertyMaster: "property/getProperty",
    addPropertyMaster: "property/addProperty",
    updatePropertyMaster: "property/updateProperty",
    deletePropertyMaster: "property/deleteProperty",
    getPropertyLocation: "property/getPropertyLocation",
    getAddPropertyLocationCurrency: "property/getAddPropertyLocationCurrency",
    addPropertyLocation: "property/addPropertyLocation",
    updatePropertyLocation: "property/updatePropertyLocation",
    deletePropertyLocation: "property/deletePropertyLocation",

    //CategoryMaster
    getCategoryMaster: "category/getCategory",
    addCategoryMaster: "category/addCategory",
    updateCategoryMaster: "category/updateCategory",
    deleteCategoryMaster: "category/deleteCategory",

    //ItemMaster
    getItemMaster: "item/getItem",
    addItemMaster: "item/addItem",
    updateItemMaster: "item/updateItem",
    deleteItemMaster: "item/deleteItem",
    getItemCategoryData: "item/getItemCategoryData",
    addItemCategory: "item/addItemCategory",
    updateItemCategory: "item/updateItemCategory",
    deleteItemCategory: "item/deleteItemCategory",

    //Booking
    getGuestByMobileNumber: "bookingMaster/getGuestByMobileNumber",
    getAwsFileName: "aws/getAwsFileName",
    addBookingData: "bookingMaster/addBookingData",
    updateBookingData: "bookingMaster/updateBookingData",
    deleteBookingData: "bookingMaster/deleteBookingData",
    getBookingData: "bookingMaster/getBookingData",
    getStaticDataForBookingMaster:
      "bookingMaster/getStaticDataForBookingMaster",
    getRoomAvailability: "bookingMaster/getRoomAvailability",
    getAvailableRoomsByRoomType: "room/getAvailableRoomsByRoomType",
    addCheckIn: "bookingMaster/addCheckIn",
    getBookingDetailsByBookingId: "bookingMaster/getBookingDetailsByBookingId",
    deleteBookingDetail: "bookingMaster/deleteBookingDetail",
    getCheckoutGuestInfo: "billing/getCheckoutGuestInfo",
    checkoutGuest: "billing/checkoutGuest",

    //LEAVE MASTER
    getLeaveData: "leave/getLeaveData",
    getLeaveTypesData: "leave/getLeaveTypes",
    getLeaveCategoriesData: "leave/getLeaveCategories",
    addLeaveData: "leave/addLeave",
    updateLeaveData: "leave/updateLeave",
    deleteLeaveData: "leave/deleteLeaveById",
    getEmployessSubData: "leave/getEmployessSubData",
    approveRejectLeave: "leave/approveRejectLeave",

    //LEAVE BALANCE MASTER
    updateLeaveBalance: "leavebalance/updateLeaveBalance",
    getRoleWiseLeaveBalance: "leavebalance/getRoleWiseLeaveBalance",
    getDesignations: "leavebalance/getDesignations",
    getQuarterTypesData: "leavebalance/getQuarter",

    //Item Request
    getItemListForItemRequest: "itemrequest/getItemList",
    getItemRequestData: "itemrequest/getItemRequestList",
    getSupplierForItemRequest: "itemrequest/getSupplierList",
    addOrderData: "itemrequest/createOrder",
    getItemRequestedHistory: "itemrequest/getOrderHistory",
    getItemRequestedItemDetailsHistory: "itemrequest/getOrderItemHistory",
    getStatusList: "itemrequest/getStatusList",
    updateItemRequestStatus: "itemrequest/updateStatus",


   //Blogs
    getBlogs: "openApi/getBlogs",
    getBlogCategory: "openApi/getBlogCategory"
  },

  PUBLIC_APIS: [
    "userAuth/login",
    "userAuth/register",
    "userAuth/otpVerification",
  ],

  appConfig: {
    dateFormatForGrid: "DD-MMM-YYYY",
    dateFormat: "DD/MM/YYYY",
    dateFormatForPicker: "dd-MMM-yyyy",
    dbDateFormat: "YYYY-MM-DD",
    dbDateTimeFormat: "YYYY-MM-DD HH:mm:ss",
    dateTimeFormatForPicker: "dd-MMM-yyyy HH:mm:ss",
    dateTimeFormatForPrintStamp: "DD-MMM-YYYY, HH:mm:ss A",
    APIVersion: "4.3.0",
    APITimeout: 60000,
    APITimeoutMessage: "Server not reachable. Please contact Admin.",
  },

  awsMediaPath:
    "https://file-upload-smartlab-qa.s3.ap-south-1.amazonaws.com/HalfOlive/",
};

export default Constants;
