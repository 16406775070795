import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import GuestDetails from "./GuestDetails";
import BookingHistory from "./BookingHistory/BookHistory";
import Treatment from "./Treatment/Treatment";
import HealthHistory from "./HealthHistory/HealthHistory";
import DailySchedule from "./DailySchedule/DailySchedule";
// import DietPage from "./DietPlan/DietPage";
import "./Guest.css";
import callApi from "../../ApiCall";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { toast, Zoom } from "react-toastify";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  PaginationItem,
} from "@mui/material";
import Constants from "../../Constants";
import Booking from "../Reservation/Booking";
import SearchBar from "../../common/SearchBar/SearchBar";
import AddButton from "../../common/Button/Button";
import Dialog from "@mui/material/Dialog";
import Physiotherapy from "./Physiotherapy/Physiotherapy";
import { Grid } from "@material-ui/core";

const Guest = (props) => {
  const [value, setValue] = useState(0);
  const [isDataSelected, setisDataSelected] = useState(false);
  const [filter, setFilter] = useState("");
  const [guestData, setGuestData] = useState([]);
  const [showGuestPopup, setshowGuestPopup] = useState(false);
  const [isAddMode, setIsAddMode] = useState(true);
  const [refreshPage, setRefreshPage] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("Checked-In");

  // start


  const [openFullScreenModal, setOpenFullScreenModal] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null); // Track the hovered row

  const handleMouseEnter = (rowIndex) => {
    setHoveredRow(rowIndex); // Set the hovered row
  };

  const handleMouseLeave = () => {
    setHoveredRow(null); // Reset the hovered row
  };

  const handleOpenFullScreenModal = () => {
    setOpenFullScreenModal(true);
  };

  const handleCloseFullScreenModal = () => {
    setOpenFullScreenModal(false);
  };

  // add dialog box in search bar

  const [dialogSearch, setDialogSearch] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const handleDialogSearch = (event) => {
    setDialogSearch(event.target.value.toLowerCase());
  };

  // end

  useEffect(() => {
    getUserDetails();
  }, [refreshPage]);

  const handleSearchfilter = (event) => {
    setFilter(event.target.value);
    setPage(1);
  };

  const handleReservationPopupClose = () => {
    setshowGuestPopup(false);
  };

  const getUserDetails = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getGuestList,
        headers: { Authorization: "Bearer YourAccessToken" }, // Adjust this
      });

      if (response?.success) {
        const { guestList = [] } = response.data;
        setGuestData(guestList);
      } else {
        console.error("Error fetching Guest data:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleRowClick = (data) => {
    setSelectedGuest(data);
    setisDataSelected(true);
    setDialogSearch("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setSelectedRowIndex((prev) =>
        prev === null ? 0 : Math.min(filteredDialogRows.length - 1, prev + 1)
      );
      e.preventDefault();
    } else if (e.key === "ArrowUp") {
      setSelectedRowIndex((prev) =>
        prev === null ? 0 : Math.max(0, prev - 1)
      );
      e.preventDefault();
    } else if (e.key === "Enter") {
      if (selectedRowIndex !== null) {
        handleRowClick(filteredDialogRows[selectedRowIndex]);
      }
    }
  };

  const handleAddProductClick = () => {
    setIsAddMode(true);
    setshowGuestPopup(true);
  };

  const handleBackToGuest = () => {
    setisDataSelected(false);
    setValue(0);
  };

  const handleStatusChange = (event, newStatus) => {
    setSelectedStatus(newStatus);
  };

  const pageSize = 10;
  const totalPages = Math.ceil(guestData?.length / pageSize);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const filteredData = guestData.filter((row) => {
    const guestName = row.GuestName ? row.GuestName.toLowerCase() : "";
    const mobile = row.MobileNumber ? row.MobileNumber : "";
    const gender = row.Gender ? row.Gender : "";
    const filterLower = filter.toLowerCase();
    return (
      guestName.includes(filterLower) ||
      mobile.includes(filterLower) ||
      gender.includes(filterLower)
    );
  });

  const paginatedRows = filteredData.slice(startIndex, endIndex);

  // Filter the paginated rows based on selectedStatus
  const filteredRows = paginatedRows.filter((row) => {
    if (selectedStatus === "all") {
      return true;
    } else if (selectedStatus === "Checked-In") {
      return row.BookingStatus === "Checked-In";
    } else {
      return row.BookingStatus !== "Checked-In";
    }
  });
  // add serh bar in dialog box

  const filteredDialogRows = guestData.filter((row) => {
    const guestName = row.GuestName ? row.GuestName.toLowerCase() : "";
    return guestName.includes(dialogSearch);
  });

  // end

  const getStatusColor = (status) => {
    switch (status) {
      case "Checked-In":
        return "check-in-text";
      case "Checked-Out":
        return "check-out-text";
      default:
        return "default-color";
    }
  };

  const handleStartSchedule = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.startSChedule,
      });

      if (response?.success) {
        toast.success(
          <div className="custom_Toast">
            <h5>Schedule Start for next day</h5>
          </div>
        );
      } else {
        toast.error("Failed to Start SChedule.");
      }
    } catch (error) {
      console.error("Error updating Schedule:", error);
      toast.error("An error occurred while Starting Schedule");
    }
  };

  return (
    <>
      {!isDataSelected && (
        <div className="reservation_MainPae">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <SearchBar value={filter} onChange={handleSearchfilter} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <ToggleButtonGroup
                className="ToggleButtonGroups"
                value={selectedStatus}
                exclusive
                onChange={handleStatusChange}
                aria-label="text alignment"
              >
                <ToggleButton
                  value="all"
                  aria-label="all"
                  disabled={selectedStatus === "all"}
                  sx={{
                    textTransform: "none",
                    padding: "8px 16px",
                    borderRadius: "8px 0px 0px 8px",
                  }}
                >
                  All
                </ToggleButton>
                <ToggleButton
                  value="Checked-In"
                  aria-label="Checked-In"
                  disabled={selectedStatus === "Checked-In"}
                  sx={{ textTransform: "none", padding: "8px 16px" }}
                >
                  Checked-In
                </ToggleButton>
                <ToggleButton
                  value="Inactive"
                  aria-label="Inactive"
                  disabled={selectedStatus === "Inactive"}
                  sx={{
                    textTransform: "none",
                    padding: "8px 16px",
                    borderRadius: "0px 8px 8px 0px",
                  }}
                >
                  Other
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2}>
              <button
                style={{
                  backgroundColor: "var(--primary-green)",
                  color: "white",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight: "bold",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
                onClick={handleStartSchedule}
              >
                Start Schedule
              </button>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2}>
              {props?.currentMenu?.Add ? (
                <>
                  <AddButton
                    buttonText={"Add Booking"}
                    onClick={handleAddProductClick}
                  />
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>

          {/* Table */}
          <div className="ReservationTable">
            <div className="Table">
              <div className="DataGrid">
                {/* Table */}
                <div className="propActivity-table">
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Guest Name</TableCell>
                          <TableCell>Mobile Number</TableCell>
                          <TableCell>Email Address</TableCell>
                          <TableCell>Gender</TableCell>
                          <TableCell>Room Number</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredRows.map((row, i) => (
                          <TableRow
                            key={i}
                            onClick={() => handleRowClick(row)}
                            onMouseEnter={() => handleMouseEnter(i)} // On mouse enter, set hovered row
                            onMouseLeave={handleMouseLeave} // On mouse leave, reset hovered row
                            style={{
                              cursor: 'pointer',
                              backgroundColor: hoveredRow === i ? 'var(--secondary-green)' : '', // Change the row background on hover
                            }}
                          >
                            <TableCell>{row.GuestName}</TableCell>
                            <TableCell>{row.MobileNumber}</TableCell>
                            <TableCell>{row.EmailAddress}</TableCell>
                            <TableCell>{row.Gender}</TableCell>
                            <TableCell>
                              {row.BookingStatus === "Checked-In"
                                ? row.RoomNumber
                                : ""}
                            </TableCell>
                            <TableCell>
                              {" "}
                              <span
                                className={getStatusColor(row.BookingStatus)}
                              >
                                {row.BookingStatus}
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* Pagination */}
                  <Pagination
                    count={totalPages}
                    page={page}
                    hideNextButton
                    hidePrevButton
                    onChange={(event, value) => setPage(value)}
                    renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        page={item.page}
                        className={item.selected ? "pagination-selected" : ""}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* If data is selected, render appropriate tab */}
      {isDataSelected && (
        <>
          <Tabs
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
            aria-label="basic tabs example"
          >
            <Tab label="Guest Details" />
            <Tab label="Booking History" />
            <Tab label="Health History" />
            <Tab label="Treatment" />
            {/* <Tab label="Diet Plan" /> */}
            <Tab label="Daily Schedule" />
            <Tab label="Physiotherapy" />
          </Tabs>

          <TabPanel
            value={value}
            index={0}
            GuestMasterId={selectedGuest.GuestMasterId}
          >
            <div className="product-detail">
              <GuestDetails
                handleBackToGuest={handleBackToGuest}
                GuestMasterId={selectedGuest.GuestMasterId}
              />
            </div>
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            GuestMasterId={selectedGuest.GuestMasterId}
          >
            <div className="product-detail">
              <BookingHistory GuestMasterId={selectedGuest.GuestMasterId} />
            </div>
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            GuestMasterId={selectedGuest.GuestMasterId}
          >
            <div className="product-detail">
              <HealthHistory GuestMasterId={selectedGuest.GuestMasterId} />
            </div>
          </TabPanel>

          <TabPanel
            value={value}
            index={3}
            GuestMasterId={selectedGuest.GuestMasterId}
          >
            <div className="product-detail">
              <Treatment GuestMasterId={selectedGuest.GuestMasterId} />
            </div>
          </TabPanel>
          
          <TabPanel
            value={value}
            index={4}
            GuestMasterId={selectedGuest.GuestMasterId}
          >
            <div className="product-detail">
              <DailySchedule GuestMasterId={selectedGuest.GuestMasterId} />
            </div>
          </TabPanel>
          {/* Physiotherapy start */}
          <TabPanel
            value={value}
            index={5}
            GuestMasterId={selectedGuest.GuestMasterId}
          >
            <div className="product-detail">
              <Physiotherapy GuestMasterId={selectedGuest.GuestMasterId} />
            </div>
          </TabPanel>
          <TabPanel>
            <button
              style={{
                position: "absolute",
                padding: "5px",
                backgroundColor: "#6C7D02",
                borderRadius: "8px",
                border: "none",
                width: "auto",
                height: "3.2vw",
                fontSize: "18px",
                color: "#ffffff",
                cursor: "pointer",
                top: "14%",
                left: "85%",
                fontWeight: "600",
              }}
              onClick={handleOpenFullScreenModal}
            >
              <span style={{ padding: "10px" }}>
                {selectedGuest?.GuestName || "Guest Name"}
                <span
                  style={{
                    fontSize: "22px",
                    marginLeft: "12px",
                    marginTop: "10px",
                    fontWeight: "100",
                  }}
                >
                  &#8595;
                </span>
              </span>
            </button>
          </TabPanel>
          {/* Physiotherapy end  */}

          <Dialog
            open={openFullScreenModal}
            onClose={handleCloseFullScreenModal}
            PaperProps={{
              style: {
                background: "rgba(255, 255, 255, 0.9)",
                backdropFilter: "blur(10px)",
                width: "250px",
                height: "450px",
                left: "37%",
                top: "8%",
                zIndex: "0.5",
                scrollbarWidth: "thin",
              },
            }}
          >
            <div
              style={{
                padding: "20px",
                textAlign: "center",
              }}
              onKeyDown={handleKeyDown}
              tabIndex={0}
            >
              <button
                style={{
                  color: "black",
                  cursor: "pointer",
                  float: "right",
                  zIndex: "1000",
                  top: "0%",
                  fontSize: "22px",
                  fontWeight: "bold",
                }}
                onClick={handleCloseFullScreenModal}
              >
                X
              </button>
              <div style={{ marginBottom: "10px" }}>
                <input
                  type="text"
                  placeholder="Search Guest Name..."
                  value={dialogSearch}
                  onChange={handleDialogSearch}
                  style={{
                    padding: "5px",
                    width: "100%",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>

              <div className="ReservationTable" style={{ marginTop: "20px" }}>
                <div className="Table">
                  <div className="DataGrid">
                    {/* Table */}
                    <div className="propActivity-table">
                      <TableContainer component={Paper}>
                        <Table>
                          <TableBody>
                            {/* Check if there are any rows to display */}
                            {filteredDialogRows.length === 0 ? (
                              <TableRow>
                                <TableCell colSpan={6} align="center">
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    Data Not Found
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              filteredDialogRows.map((row, i) => (
                                <TableRow
                                  key={i}
                                  onClick={() => {
                                    handleRowClick(row);
                                    handleCloseFullScreenModal();
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      selectedGuest?.GuestName === row.GuestName
                                        ? "#5d7202"
                                        : "",
                                  }}
                                >
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "500",
                                      textAlign: "left",
                                      color:
                                        selectedGuest?.GuestName ===
                                        row.GuestName
                                          ? "white"
                                          : "black",
                                      fontWeight:
                                        selectedGuest?.GuestName ===
                                        row.GuestName
                                          ? "bold"
                                          : "normal",
                                    }}
                                  >
                                    {row.GuestName}
                                  </TableCell>
                                </TableRow>
                              ))
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </>
      )}

      {showGuestPopup && (
        // />
        <Booking onClose={handleReservationPopupClose} isAddMode={isAddMode} />
      )}
    </>
  );
};

const TabPanel = ({ children, value, index, selectedGuest, ...other }) => {
  const { GuestMasterId } = selectedGuest || {};

  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>
              {React.cloneElement(children, { GuestMasterId })}
            </Typography>
          </Box>
        )}
      </div>
    </>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  selectedGuest: PropTypes.object,
};

export default Guest;
