import React, { useEffect, useState } from "react";
import "./BlogInfo.css";
import demo from "../../../assets/images/H-Second.png";
import Constants from "../../../Constants";
import callApi from "../../../ApiCall";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

const BlogInfo = () => {
  const { title_slug } = useParams(); // Get the title_slug from the URL
  const [blog, setBlog] = useState(null); // Initialize as null, not array
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track error state

  const getBlogDetails = async () => {
    try {
      const response = await callApi({
        apiURL: `${Constants.API.getBlogs}`,
      });

      if (response?.success) {
        const foundBlog = response.data.blogsList.find(
          (blog) => blog.title_slug === title_slug
        );

        if (foundBlog) {
          setBlog(foundBlog); // Set the found blog
        } else {
          setError("Blog not found");
        }
      } else {
        setError("Failed to load blogs");
      }
    } catch (err) {
      setError("Error fetching blog details");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBlogDetails();
  }, [title_slug]);

  const mediapath =
    "https://reveation-blog.s3.ap-south-1.amazonaws.com/Images/";

  if (loading) {
    return <p>Loading...</p>; // Show loading message while fetching the blog
  }

  if (error) {
    return <p>{error}</p>; // Show error message
  }

  return (
    <div className="blog-info-container mt-20">
      {blog ? (
        <div className="blog-info-content">
          <center>
            <h1 className="blog-info-heading">{blog.title}</h1>

            <div className="blog-info-image-container">
              <img
                src={mediapath + blog.image}
                title=""
                alt="Blog Image"
                className="blog-info-img"
              />
            </div>
            <br />
            <p className="blog-info-text">{parse(parse(blog.description))}</p>
          </center>
          <br />
          <center>
            <div className="blog-info-image-container">
              {blog.image2 && (
                <img
                  src={mediapath + blog.image2}
                  alt="Blog Image 2"
                  className="blog-info-img"
                />
              )}
              <br />

              <section className="blog-info-section2">
                {blog.description2 && (
                  <p className="blog-info-text">
                    {parse(parse(blog.description2))}
                  </p>
                )}
              </section>
            </div>
          </center>
          <br />
          <center>
            <div className="blog-info-image-container">
              {blog.image3 && (
                <img
                  src={mediapath + blog.image3}
                  title=""
                  alt="Blog Image 3"
                  className="blog-info-img"
                />
              )}
            </div>
            <br />
            {blog.description3 && (
              <p className="blog-info-text">
                {parse(parse(blog.description3))}
              </p>
            )}
          </center>
        </div>
      ) : (
        <p>No blog found for the given title_slug</p> // This message will be shown if blog is null or undefined
      )}
    </div>
  );
};

export default BlogInfo;
