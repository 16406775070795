import React from "react";
import "../../assets/colors/colors.css";
import "react-datepicker/dist/react-datepicker.css";
// import "../Reservation/CSS/reservation.css";
import { useEffect, useState } from "react";
import callApi from "../../ApiCall.js";
import SearchBar from "../../common/SearchBar/SearchBar.js";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Constants from "../../Constants.js";
import Menu from "@mui/material/Menu";
import edit from "../../assets/icons/edit.svg";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import trash from "../../assets/icons/trash.svg";
import { toast, Zoom, ToastContainer } from "react-toastify";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  Pagination,
  PaginationItem,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/lab";
import AddButton from "../../common/Button/Button.js";
import { getDisplayDate } from "../../utils/DateTimeUtils.js";
import styled from "styled-components";
import AddItemRequest from "./AddItemRequest.js";

const MenuItemWrapper = styled("li")(() => ({
  padding: "8px 8px",
  boxShadow: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F75555",
    color: "#FFFFFF",
    img: {
      filter:
        "invert(92%) sepia(4%) saturate(8%) hue-rotate(126deg) brightness(138%) contrast(200%)",
    },
  },
  transition: "all 0.3s ease",
}));

const IconWrapper = styled("span")(() => ({
  fontSize: "16px",
  marginRight: "8px",
  "& .MuiSvgIcon-root": {
    fontSize: "inherit",
  },
}));

export default function ItemRequest(props) {
  const [filter, setFilter] = useState("");
  const [isAddMode, setIsAddMode] = useState(false);
  const [showReservationPopup, setShowReservationPopup] = useState(false);
  const [selectedProductForUpdate, setSelectedProductForUpdate] =
    useState(false);
  const [itemOrderHistory, setOrderItemHistory] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("Pending");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowForActions, setSelectedRowForActions] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const initialSortedRows = itemOrderHistory;

  const [page, setPage] = useState(1);
  const pageSize = 10;
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };

  const initialItemSortedRows = itemOrderHistory;
  const [sortedItemRows, setSortedItemRows] = useState(initialItemSortedRows);

  const startItemIndex = (page - 1) * pageSize; //0, 10
  const endItemIndex = startItemIndex + pageSize; // 0 + 10 = 10, 20
  const formattedItemPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };
  const paginatedItemRows = itemOrderHistory.slice(
    startItemIndex,
    endItemIndex
  );

  useEffect(() => {
    if (itemOrderHistory?.length > 0) {
      setSortedItemRows(itemOrderHistory);
    }
  });

  useEffect(() => {
    getItemRequestedItemDetailsHistory();
  }, []);

  const handleSearchfilter = (event) => {
    setFilter(event.target.value);
    setPage(1);
  };

  const handleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setSelectedStatus(newStatus);
      setPage(1);
    }
  };

  // const selectedStatusData = sortedItemRows.filter(
  //   (val) => val.Status === selectedStatus
  // );

  // const selectedStatusData =
  //   selectedStatus !== "Pending"
  //     ? sortedItemRows.filter((val) => val.status === selectedStatus)
  //     : sortedItemRows;

  const selectedStatusData = sortedItemRows.filter(
    (row) => row.Status === selectedStatus
  );

  const filteredRows = selectedStatusData.slice(startIndex, endIndex);

  const totalItemPages = Math.ceil(selectedStatusData.length / pageSize);

  const handleMenuSelect = (option) => {
    handleUpdateClick(option, selectedRowForActions);
    handleMenuClose();
  };
  const handleUpdateClick = async (option, selectedRow) => {
    const response = await callApi({
      apiURL: Constants.API.updateItemRequestStatus,
      requestBody: {
        option,
        selectedRow,
      },
    });
    if (response?.success) {
      getItemRequestedItemDetailsHistory();
      toast.success(
        <div className="custom_Toast">
          <h5>Item Requested Status Updated</h5>
          Item requested status has been updated successfully
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          hideProgressBar: true,
          transition: Zoom,
          style: {
            width: "350px",
            backgroundColor: "#0ABE75",
            color: "#FFFFFF",
          },
          autoClose: 1500,
          closeButton: true,
          // onClose: () => {
          //     onClose();
          //     setShowPopup(false);
          //     resetForm();
          // },
        }
      );
    } else {
      console.log("error");
    }
  };

  const handleMenuOpen = (event, row, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowForActions(row);
    setIsMenuOpen(true);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRowForActions(null);
    setIsMenuOpen(false);
  };

  const getItemRequestedItemDetailsHistory = async () => {
    const response = await callApi({
      apiURL: Constants.API.getItemRequestedItemDetailsHistory,
      requestBody: {
        TeamView: props?.currentMenu?.TeamView,
        ViewAll: props?.currentMenu?.ViewAll,
      },
    });
    if (response?.success) {
      const { orderHistory = [] } = response.data;
      const mappedData = orderHistory.map((item, index) => ({
        ...item,
        id: index + 1,
        ItemRequestedDate: getDisplayDate(item.ItemRequestedDate),
      }));
      setOrderItemHistory(mappedData);
    } else {
      console.log("error");
    }
  };

  const columns = [
    {
      field: "id",
      headerName: <span>Sr. No</span>,
      flex: 1,
    },
    {
      field: "ItemName",
      headerName: <span>Item Name</span>,
      flex: 1,
    },
    {
      field: "ItemRequestedDate",
      headerName: <span>Item Requested Date</span>,
      flex: 1,
    },
    {
      field: "orderedBy",
      headerName: <span>Requested By</span>,
    },
    {
      field: "Qty",
      headerName: <span>Qty.</span>,
      flex: 1,
    },
    {
      field: "UOM",
      headerName: <span>UOM</span>,
      flex: 1,
    },
    {
      field: "PackUOM",
      headerName: <span>Pack & UOM</span>,
      flex: 1,
    },
    ((props.currentMenu.Edit || props.currentMenu.Delete) &&
      selectedStatus === "Pending") ||
    selectedStatus === "Accepted"
      ? {
          field: "actions",
          headerName: "Actions",
          flex: 1,
          renderCell: (params, index) => {
            return (
              <div>
                <IconButton
                  color="primary"
                  aria-label="actions"
                  onClick={(event) => handleMenuOpen(event, params.row, index)}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <MoreVertIcon style={{ color: "gray" }} />
                </IconButton>
                <Menu
                  id="actions-menu"
                  anchorEl={anchorEl}
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                  sx={{
                    "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                      {
                        boxShadow: "none",
                        backgroundColor: "#EEE",
                        borderRadius: "8px",
                      },
                    "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                      paddingTop: "0",
                      paddingBottom: "0",
                    },
                  }}
                >
                  {selectedStatus === "Pending" && (
                    <MenuItemWrapper onClick={() => handleMenuSelect(96)}>
                      <IconWrapper>
                        <CheckCircleOutlineRoundedIcon
                          style={{ color: "#6C7D02" }}
                        />
                      </IconWrapper>{" "}
                      <span style={{ fontWeight: "400" }}>Accept</span>
                    </MenuItemWrapper>
                  )}

                  {selectedStatus === "Pending" && (
                    <MenuItemWrapper
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={() => handleMenuSelect(98)}
                    >
                      <IconWrapper>
                        <img src={trash} alt="Reject" />
                      </IconWrapper>{" "}
                      <span style={{ fontWeight: "400" }}>&nbsp; Reject</span>
                    </MenuItemWrapper>
                  )}

                  {selectedStatus === "Accepted" && (
                    <MenuItemWrapper onClick={() => handleMenuSelect(97)}>
                      <IconWrapper>
                        <CheckCircleOutlineRoundedIcon
                          style={{ color: "#6C7D02" }}
                        />
                      </IconWrapper>{" "}
                      <span style={{ fontWeight: "400" }}>Delivered</span>
                    </MenuItemWrapper>
                  )}
                </Menu>
              </div>
            );
          },
        }
      : null,
  ].filter(Boolean);

  const handleAddProductClick = () => {
    setIsAddMode(true);
    setShowReservationPopup(true);
  };

  const handleReservationPopupClose = () => {
    setShowReservationPopup(false);
    setIsAddMode(true);
    setSelectedProductForUpdate(null);
    getItemRequestedItemDetailsHistory();
  };

  return (
    <>
      {showReservationPopup && (
        <AddItemRequest
          onClose={handleReservationPopupClose}
          isAddMode={isAddMode}
          leaveData={selectedProductForUpdate}
        />
      )}
      <div className="reservation_MainPae">
        <div className="reservation_sect-1">
          <div className="reservation_manage">
            <h1 className="manage_page_heading">Request Item</h1>
          </div>
          <Grid item xs={12} sm={6} md={4}>
            <ToggleButtonGroup
              className="ToggleButtonGroups"
              value={selectedStatus}
              exclusive
              onChange={handleStatusChange}
              aria-label="text alignment"
            >
              <ToggleButton
                value="Pending"
                aria-label="Pending"
                sx={{ textTransform: "none", padding: "8px 16px" }}
              >
                Pending
              </ToggleButton>
              <ToggleButton
                value="Accepted"
                aria-label="Approved"
                sx={{ textTransform: "none", padding: "8px 16px" }}
              >
                Approved
              </ToggleButton>
              <ToggleButton
                value="Delivered"
                aria-label="Rejected"
                sx={{
                  textTransform: "none",
                  padding: "8px 16px",
                  borderRadius: "0px 8px 8px 0px",
                }}
              >
                Delivered
              </ToggleButton>
              <ToggleButton
                value="Rejected"
                aria-label="Rejected"
                sx={{
                  textTransform: "none",
                  padding: "8px 16px",
                  borderRadius: "0px 8px 8px 0px",
                }}
              >
                Rejected
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {props?.currentMenu?.Add && (
            <div style={{ margin: "0 0 1% 15%" }}>
              <AddButton
                buttonText={"New Item Request"}
                onClick={handleAddProductClick}
              />
            </div>
          )}
        </div>

        <div className="ReservationTable">
          <div className="Table">
            <div className="Property_Location_DataGrid">
              <div className="propActivity-table">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="tableHeaderCell">
                          {/* Sr. No. */}
                        </TableCell>
                        {columns.map((column, i) => (
                          <TableCell
                            key={i}
                            className="tableHeaderCell"
                            // onClick={() => handleSortClick(column.field)}
                          >
                            {column.headerName}
                            {/* {renderArrowIcon(column.field)} */}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {filteredRows?.length > 0 ? (
                      <TableBody>
                        {filteredRows.map((row, i) => (
                          <TableRow key={i}>
                            <TableCell className="tableBodyCell">
                              {/* {i + 1} */}
                            </TableCell>
                            {columns.map((column, i) => (
                              <TableCell key={i} className="tableBodyCell">
                                {row &&
                                  (column.renderCell
                                    ? column.renderCell({ row })
                                    : row[column.field])}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <p style={{ display: "flex" }}>No data found</p>
                    )}
                  </Table>
                </TableContainer>
                <Pagination
                  count={totalItemPages}
                  page={page}
                  hideNextButton
                  hidePrevButton
                  onChange={(event, value) => setPage(value)}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      page={formattedItemPageNumber(item.page)}
                      className={item.selected ? "pagination-selected" : ""}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
